import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import RowLeftHeader from '@components/Timelines/TimelineProjects/RowLeftHeader';

import { useResourceRowContext } from './Context';
import styles from './styles.module.css';

type Props = {
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function Header({ sortHandlerProps }: Props) {
  const { project, resource } = useResourceRowContext();

  if (!project || !resource) return null;
  return (
    <div className={styles.header}>
      <RowLeftHeader
        allocation={resource?.totalAllocation}
        image={resource.image}
        resource={resource}
        project={project}
        sortHandlerProps={sortHandlerProps}
      >
        <p>{resource.firstName + ' ' + resource.lastName}</p>
      </RowLeftHeader>
    </div>
  );
}
