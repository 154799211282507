import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { handleApiCall } from '@services/api';
import { getUsageInfo } from '@services/api/workspace/workspace';

type UsageInfoProps = {
  enabled: boolean;
  workspaceId: string;
  onSuccess?: typeof noop;
  onError?: typeof noop;
};

export const USAGE_INFO_QUERY_KEY: string = 'get-usage-info';
export default function useUsageInfo({
  enabled = true,
  workspaceId,
  onError = noop,
  onSuccess = noop,
}: UsageInfoProps) {
  return useQuery({
    queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
    queryFn: ({ queryKey: [_, wsId] }) =>
      handleApiCall(
        getUsageInfo({ workspaceId: wsId as string }),
        onSuccess,
        onError,
      ),
    enabled,
  });
}
