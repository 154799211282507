import { RightSlot } from 'react-contexify';

import { IconBackspace } from '@tabler/icons-react';

export default function RightSlotDelete() {
  return (
    <RightSlot>
      {'⇧'}
      <IconBackspace size={14} style={{ marginLeft: '4px' }} />
    </RightSlot>
  );
}
