import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import { getAuth } from '@firebase/auth';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { ROUTES } from '@/types/routes';

import OnboardingProvider from '@contexts/OnboardingContext';
import { UserContext } from '@contexts/UserContext';

import Button from '@components/Button';
import Layout from '@components/Layout';
import LogoHeader from '@components/LogoHeader';

import styles from './styles.module.css';

export default function OnboardingLayout() {
  const { clearLocalStorage, isUserEmailVerified } = useContext(UserContext);
  const auth = getAuth();
  const queryClient = useQueryClient();

  const onLogoutFn = async () => {
    await auth?.signOut();
    queryClient.clear();
    clearLocalStorage();
  };
  const location = useLocation();
  const { logoutBtn } = location.state ?? { logoutBtn: true };

  if (!isUserEmailVerified) {
    return <Navigate to={ROUTES.EMAIL_VERIFICATION} replace />;
  }

  return (
    <Layout>
      <OnboardingProvider>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.left}>
              <LogoHeader />
            </div>
            {logoutBtn && (
              <div className={styles.right}>
                <Button
                  label={t('common:logout')}
                  size="medium"
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    onLogoutFn();
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </OnboardingProvider>
    </Layout>
  );
}
