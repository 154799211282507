import { useCallback, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';
import { t } from 'i18next';

import { INSIGHTS_TYPE, WORKSPACE_MODE } from '@/types/enums';
import { TWhosFree } from '@/types/insights';

import { UIContext } from '@contexts/UIContext';
import { UserContext } from '@contexts/UserContext';
import useInsightsPartQuery from '@hooks/insights/useInsightsPartQuery';
import { linearMap } from '@services/helpers';

import Avatar from '@components/Avatar';
import DrawerCard from '@components/DrawerCard';
import ShowMore from '@components/ShowMore';
import ListSkeleton from '@components/skeleton/ListSkeleton';

import styles from './styles.module.css';

export default function WhosFreeDrawer() {
  const { drawerIsOpen } = useContext(UIContext);

  const { data: whosFree, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.WHOS_FREE,
    min: 0,
    max: 4,
    enabled: drawerIsOpen,
  });

  const { workspaceMode } = useContext(UserContext);

  const whosFreeDifference = useMemo(
    () => (whosFree?.count && whosFree?.count - whosFree.data.length) ?? 0,
    [whosFree?.count, whosFree?.data.length],
  );

  const renderWhosFreeItem = useCallback(
    (value: TWhosFree) => {
      const minAllocation = 1;
      const maxAllocation = workspaceMode === WORKSPACE_MODE.DAYS ? 5 : 40;
      const allocValue = Math.round(
        Math.min(Math.max(value.freeTime, minAllocation), maxAllocation),
      );
      const freeTimeShade = Math.floor(
        linearMap(allocValue, minAllocation, maxAllocation, 1, 5),
      );
      return (
        <li
          key={`whos-free-${value.firstName}-${value.lastName}`}
          className={styles.item}
        >
          <div className={styles.subItem}>
            <Avatar
              size="medium"
              image={value.image?.ref}
              name={value?.firstName + ' ' + value?.lastName}
            />
            <p>{`${value.firstName} ${value.lastName}`}</p>
          </div>
          <div className={classNames(styles.horizontalList)}>
            <p>
              {value.thisWeek
                ? 'this week'
                : format(value.startDate as Date, 'dd MMM')}
            </p>
            <p
              className={classNames(
                styles.freeTime,
                styles[`shade-${freeTimeShade}`],
              )}
            >
              <span>{value.freeTime}</span>
            </p>
          </div>
        </li>
      );
    },
    [workspaceMode],
  );

  const renderWhosFreeList = useCallback(
    (value: TWhosFree[]) => {
      return <ul className={styles.list}>{value.map(renderWhosFreeItem)}</ul>;
    },
    [renderWhosFreeItem],
  );

  return (
    <DrawerCard
      draweId="whosFree"
      emoji="💪"
      title={t('common:insights.whosFree')}
      rightTitle={'Next 2 months'}
      isLoading={isLoading}
      loader={<ListSkeleton />}
      emptyLabel={t('common:insights.noWhosFree')}
    >
      {whosFree?.count ? (
        <>
          <ul className={styles.list}>
            {whosFree?.data?.map(renderWhosFreeItem)}
          </ul>
          {whosFreeDifference ? (
            <ShowMore
              insightsType={INSIGHTS_TYPE.WHOS_FREE}
              renderData={renderWhosFreeList}
              triggerLabel={`+ ${whosFreeDifference} ${t('common:more')}`}
              open={false}
              type="modal"
              title={`💪 ${t('common:insights.whosFree')}`}
            />
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </DrawerCard>
  );
}
