import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { validate as EmailValidator } from 'email-validator';

import { ROUTES } from '@/types/routes';

import Button from '@components/Button';
import Input from '@components/Input';

import styles from './styles.module.css';

export type SignInFormData = { email: string; password: string };

type Props = {
  /**
   * Function to call when the form is submitted
   * @param data
   */
  onSubmitFn: (data: SignInFormData) => void;
  /**
   * Loading state of the button
   */
  isLoading?: boolean;
};

export default function SignInForm({ onSubmitFn, isLoading }: Props) {
  const { t } = useTranslation(['common', 'forms']);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>({ mode: 'onSubmit' });

  /**
   * Hide/show password
   */
  const [showPassword, setShowPassword] = useState(false);
  const onTogglePassword = useCallback(
    (e: React.SyntheticEvent) => {
      e?.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword, setShowPassword],
  );

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmitFn)} noValidate>
        <div className={styles.field}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signIn.email.required'),
              },
              validate: (value) => {
                if (!EmailValidator(value)) {
                  return t('forms:signIn.email.invalid') as string;
                }
                return true;
              },
            }}
            control={control}
            name="email"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  name={name}
                  type="email"
                  size="large"
                  value={value}
                  label={t('forms:signIn.email.label') as string}
                  placeholder={t('forms:signIn.email.placeholder') as string}
                  onChange={onChange}
                  error={errors?.email?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.field} style={{ marginBottom: 24 }}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signIn.password.required') || 'invalid',
              },
            }}
            control={control}
            name="password"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  TrailingIcon={
                    <div aria-hidden="true" onClick={onTogglePassword}>
                      {showPassword ? (
                        <span className="icon-eye-closed text-lg leading-10"></span>
                      ) : (
                        <span className="icon-eye-opened text-lg leading-10"></span>
                      )}
                    </div>
                  }
                  onBlur={onBlur}
                  name={name}
                  size="large"
                  value={value}
                  type={showPassword ? 'text' : 'password'}
                  label={t('forms:signIn.password.label') as string}
                  placeholder={t('forms:signIn.password.placeholder')}
                  onChange={onChange}
                  error={errors?.password?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles['action-wrapper']}>
          <Button
            label={t('forms:signIn.submit') as string}
            size="large"
            isLoading={isLoading}
          />
          <div className={styles.cta}>
            <Link to={ROUTES.PASSWORD_RECOVER} preventScrollReset={false}>
              {t('forms:signIn.forgotPassword')}
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
