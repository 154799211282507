import type { InfiniteData } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import {
  cloneDeep,
  find,
  findIndex,
  findLastIndex,
  flatMap,
  some,
} from 'lodash';

import { PROJECT_STATUS } from '@/types/enums';
import { TPagedQuery } from '@/types/generic';
import {
  TimelineFilters,
  TProjectListWithResources,
  TResourceItemList,
} from '@/types/timeline';

import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { PAGE_SIZE } from '@services/helpers/timelines/projects';

type Prop = {
  projectId: string;
  status: PROJECT_STATUS;
};

export type ContextData = {
  oldStatus?: PROJECT_STATUS;
  newStatus?: PROJECT_STATUS;
  projectId: string;
  oldPageIndex?: number;
  newPageIndex?: number;
  hasNextPage?: boolean;
};

type TData = InfiniteData<
  TPagedQuery<TProjectListWithResources<TResourceItemList>>
>;

export function onUpdateProjectMutate(
  queryClient: QueryClient,
  workspaceId: string,
  sortByName: boolean,
  filters: TimelineFilters | undefined,
  { projectId: id, ...projectData }: Prop,
) {
  const key = [
    PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
    workspaceId,
    sortByName,
    filters,
  ];
  const queryData = queryClient.getQueryData<TData>(key);
  if (!queryData) return;
  const clonedQueryData = cloneDeep(queryData);
  const { pages } = clonedQueryData ?? { pages: [] };

  const context: ContextData = {
    projectId: id,
    hasNextPage: !!queryData?.pages?.[queryData.pages.length - 1]?.nextPage,
  };
  // pagina del progetto che sto modificando
  const currentPage = find(pages, ({ results }) =>
    some(results, (project) => project.id === id),
  );
  if (!currentPage) return;

  const projectIdx = findIndex(currentPage.results, { id });

  const lastIndexOfStatus = findLastIndex(
    flatMap(pages, (p) => p.results),
    ({ status }) => status === projectData.status,
  );

  const lastPage:
    | TPagedQuery<TProjectListWithResources<TResourceItemList>>
    | undefined =
    lastIndexOfStatus > -1
      ? pages?.[Math.floor(lastIndexOfStatus / PAGE_SIZE)]
      : pages?.[pages.length - 1];

  const project = currentPage.results.splice(projectIdx, 1)[0];
  if (projectData.status !== PROJECT_STATUS.COMPLETED && lastPage) {
    lastPage.results.push(Object.assign(project, projectData));
    context.hasNextPage = !!pages?.[pages.length - 1]?.nextPage;
  }
  queryClient.setQueryData(key, clonedQueryData);
  return context;
}
