import { useMemo } from 'react';

import classNames from 'classnames';
import { Tooltip } from 'radix-ui';

import { TResources } from '@/types/timeline';

import ColorDot from '@components/ColorDot';

import styles from './styles.module.css';

type PropsBase = {
  withTooltip?: boolean;
  dotClassName?: string;
  resource: Pick<TResources, 'labels' | 'id'>;
};

type PropsWithTooltip = PropsBase & {
  withTooltip: true;
  tooltip: JSX.Element;
};

type PropsWithoutTooltip = PropsBase & {
  withTooltip: false;
  tooltip?: never;
};

type Props = PropsWithTooltip | PropsWithoutTooltip;

export default function LabelsIndicator({
  resource,
  dotClassName = '',
  withTooltip = true,
  tooltip,
}: Props) {
  const labels = useMemo(() => {
    return [...resource.labels].sort().reverse() ?? [];
  }, [resource.labels]);

  return (
    <Tooltip.Provider key={`label-indicator-${resource.id}`} delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild disabled={!withTooltip}>
          <div
            className={styles.labelsIndicator}
            style={{ '--items-per-row': labels.length } as React.CSSProperties}
          >
            {labels.map((label, idx) => (
              <ColorDot
                key={label.id}
                color={label.color}
                className={classNames(styles.dotColor, dotClassName)}
                style={{ marginLeft: idx * 6 }}
              />
            ))}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal
          container={document.getElementById('radix-tooltip-portal')}
        >
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            {withTooltip && tooltip}
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
