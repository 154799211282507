import { ComponentProps } from 'react';

import { t } from 'i18next';

import { titleCase } from '@services/helpers';

import Button from '@components/Button';
import Modal from '@components/Modals/Modal';

import styles from './styles.module.css';

type ModalProps = Omit<ComponentProps<typeof Modal>, 'modalKey'> & {
  name: string;
  email: string;
  onRevokeAccess: () => void;
  onKeepAccess: () => void;
};
export default function ModalHideRevokeAccess({
  isOpen,
  onClose,
  onRevokeAccess,
  onKeepAccess,
  name,
  email,
}: ModalProps) {
  return (
    <Modal
      title={t('common:modals.hideRevokeAccess.modalTitle')}
      modalKey={'revoke-access'}
      enableClose
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className={styles['action-wrapper']}>
          <Button
            label={t('common:modals.hideRevokeAccess.actionLabel')}
            variant="destructive"
            size="medium"
            onClick={onRevokeAccess}
          />
          <Button
            label={t('common:modals.hideRevokeAccess.secondaryActionLabel')}
            size="medium"
            variant="ghost"
            onClick={onKeepAccess}
          />
        </div>
      }
    >
      <div className={styles.modalContainer}>
        <div className={styles.main}>
          <p>
            {t('common:modals.hideRevokeAccess.title', {
              name: titleCase(name),
            })}
          </p>
          <span>
            {t('common:modals.hideRevokeAccess.description', {
              email,
            })}
          </span>
        </div>
      </div>
    </Modal>
  );
}
