import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import useRequestAnimationFrame from '@hooks/utils/useRequestAnimationFrame';

import { TimelineResourcesContext } from '@components/Timelines/TimelineResources/context';

import styles from './styles.module.css';

type DraggingOverlayProps = {
  panelId: string;
  onScrollUpdate?: (step: number) => void;
  isActive?: boolean;
  stepSize?: number;
  animationSpeed?: number;
  isDragging: boolean;
};
export default function ScrollDraggingOverlay({
  isActive = true,
  isDragging = false,
  panelId,
  onScrollUpdate,
  stepSize = 20,
  animationSpeed = 10,
}: DraggingOverlayProps) {
  // const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const speed = useRef<number>(animationSpeed);

  const { virtualizer } = useContext(TimelineResourcesContext);

  const [direction, setDirection] = useState<'left' | 'right' | null>(null);

  const onScrollAnimation = useCallback(
    (deltaTime: number) => {
      const newStep = direction === 'left' ? -stepSize : stepSize;
      virtualizer.scrollBy(newStep * deltaTime * speed.current, {
        behavior: 'auto',
      });
      onScrollUpdate?.(newStep);
    },
    [direction, onScrollUpdate, stepSize, virtualizer],
  );

  const { startAnimation, stopAnimation } = useRequestAnimationFrame({
    onAnimate: onScrollAnimation,
  });

  const onMouseEnter = useCallback(
    (e: MouseEvent) => {
      const dir = (e.target as HTMLDivElement).dataset.direction as
        | 'left'
        | 'right';
      if (!dir) return;
      setDirection(dir);
      startAnimation();
    },
    [startAnimation],
  );

  const onMouseLeave = useCallback(
    (_e: MouseEvent) => {
      setDirection(null);
      stopAnimation();
    },
    [stopAnimation],
  );

  useEffect(() => {
    // const leftCurr = leftRef.current;
    const rightCurr = rightRef.current;
    if (isDragging && isActive) {
      // leftCurr?.addEventListener('mouseenter', onMouseEnter);
      // leftCurr?.addEventListener('mouseleave', onMouseLeave);
      // leftCurr?.addEventListener('mouseup', onMouseLeave);

      rightCurr?.addEventListener('mouseenter', onMouseEnter);
      rightCurr?.addEventListener('mouseleave', onMouseLeave);
      rightCurr?.addEventListener('mouseup', onMouseLeave);
    }
    return () => {
      // leftCurr?.removeEventListener('mouseenter', onMouseEnter);
      // leftCurr?.removeEventListener('mouseleave', onMouseLeave);
      // leftCurr?.removeEventListener('mouseup', onMouseLeave);

      rightCurr?.removeEventListener('mouseenter', onMouseEnter);
      rightCurr?.removeEventListener('mouseleave', onMouseLeave);
      rightCurr?.removeEventListener('mouseup', onMouseLeave);
    };
  }, [isActive, isDragging, onMouseEnter, onMouseLeave]);

  return (
    isActive &&
    createPortal(
      isDragging && (
        <>
          <div className={styles.draggingPortal} aria-hidden />
          {/* <div
          ref={leftRef}
          style={{
            width: (sidebarRef?.current?.clientWidth ?? 0) + stepSize,
          }}
          data-direction="left"
          className={classNames(styles.scrollHandle, styles.left)}
        /> */}
          <div
            ref={rightRef}
            // style={{
            //   width: stepSize,
            // }}
            data-direction="right"
            className={classNames(styles.scrollHandle, styles.right)}
          />
        </>
      ),
      document.body,
      panelId,
    )
  );
}
