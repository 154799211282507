import getSymbolFromCurrency from 'currency-symbol-map';
import { t } from 'i18next';
import { get, isUndefined } from 'lodash';

import {
  TCanDeleteAccount,
  TOwnedWorkspaceInfo,
  TResourceAccountInfo,
  TSubscriptionInfo,
} from '@/types/account';
import {
  INSIGHTS_TYPE,
  PROJECT_COLOR,
  PROJECT_STATUS,
  PROJECT_TYPE,
  RESOURCE_STATUS,
  USER_ROLE,
  WORKSPACE_MEMBER_PERMISSION,
  WORKSPACE_MODE,
} from '@/types/enums';
import {
  Dto,
  TAttachmentDataUrls,
  TFeatureFlags,
  TPagedQuery,
  TResponseList,
} from '@/types/generic';
import {
  TCountedInsights,
  TInsights,
  TInsightsActiveProjects,
  TInsightsActiveProjectsChartData,
  TInsightsAvailable,
  TInsightsCapacity,
  TInsightsCapacityChartData,
  TInsightsOverburdened,
  TInsightsPartKey,
  TInsightsProject,
  TInsightsResource,
  TInsightsStartingSoon,
  TInsightsThisWeek,
  TInsightsTopProjects,
  TWhosFree,
  TWorkloadData,
  TWorkloadPreference,
} from '@/types/insights';
import {
  TPaymentInfo,
  TTransactions,
  TTransactionsList,
} from '@/types/invoices';
import { TLabel } from '@/types/labels';
import { TProduct } from '@/types/paddle';
import {
  TCompletedProject,
  TFiltersProject,
  THiddenProject,
  TProject,
  TProjectListWithResourcesStatus,
  TResourceItemList,
  TResources,
  TResourceSearchResult,
  TResourceSearchResultInvitation,
  TResourceWorkspaceAssigned,
  TSharedProjectInfo,
  TTimeBlockRange,
  TTimelineAllocationResponse,
  TTimelineHiddenProject,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';
import {
  AccessTokenInfo,
  TAccountConsent,
  TInvitationInfo,
  TSubscriptionDto,
  TSubscriptionItemDto,
  TSubscriptionNextTransaction,
  TUserAccount,
  TUserWorkspace,
  TWorkspaceAccess,
  TWorkspaceCustomer,
  TWorkspaceUsageInfo,
} from '@/types/workspace';

import { generateUUID, titleCase } from './helpers';

export function normalizeTimeblock(
  data: Dto<TTimeBlockRange>,
): TTimeBlockRange {
  return {
    id: data?.id ?? '',
    start: new Date(`${data?.start}T00:00`),
    end: new Date(`${data?.end}T23:59`),
    allocation: data?.allocation,
    note: data?.note ?? undefined,
  };
}

export function normalizeSharedProjectInfo(
  data: Dto<TSharedProjectInfo>,
): TSharedProjectInfo | undefined {
  return data
    ? ({
        id: data?.id ?? '',
        url: data?.url ?? '',
      } as TSharedProjectInfo)
    : undefined;
}

export function normalizeProject(data: Dto<TProject>): TProject {
  return {
    id: data?.id ?? '',
    type: data?.type ?? PROJECT_TYPE.PROJECT,
    color: data?.color ?? PROJECT_COLOR.GREY,
    name:
      data?.name === PROJECT_TYPE.TIME_OFF
        ? t('common:timeOff')
        : (data?.name ?? ''),
    totalAllocation: data?.totalAllocation ? data?.totalAllocation : 0,
    emoji: data?.emoji ?? undefined,
    status: data?.status ?? undefined,
    shared: data?.shared
      ? (normalizeSharedProjectInfo(data.shared) ?? undefined)
      : undefined,
  };
}

export function normalizeTimelineProject(
  data: Dto<TTimelineProject>,
): TTimelineProject {
  return {
    id: data?.id ?? '',
    totalAllocation: data?.totalAllocation ? data?.totalAllocation : 0,
    color: data?.color ?? PROJECT_COLOR.GREY,
    shared: data?.shared ?? undefined,
    name:
      data?.type === PROJECT_TYPE.PROJECT
        ? (data?.name ?? '')
        : t('common:timeOff'),
    type: data?.type ?? PROJECT_TYPE.TIME_OFF,
    timeblocks: data?.timeblocks?.map(normalizeTimeblock) ?? [],
  };
}

export function normalizeHiddenProject(
  data: Dto<THiddenProject>,
): THiddenProject {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    color: data?.color ?? PROJECT_COLOR.GREY,
    allocation: data?.allocation ?? 0,
  };
}

export function normalizeTimelineHiddenProject(
  data: Dto<TTimelineHiddenProject>,
): TTimelineHiddenProject {
  return {
    start: new Date(`${data?.start}T00:00`) as unknown as Date,
    end: new Date(`${data?.end}T23:59`) as unknown as Date,
    projects: data?.projects?.map(normalizeHiddenProject) ?? [],
  };
}

export function normalizeTimelineResources(
  data: Dto<TTimelineResource>,
): TTimelineResource {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: data?.image ? normalizeAttachmentFile(data?.image) : undefined,
    capacity: data?.capacity ?? 0,
    projects: data?.projects?.map(normalizeTimelineProject) ?? [],
    hidden: data?.hidden?.map(normalizeTimelineHiddenProject) ?? [],
    hiddenCount: data?.hiddenCount ?? 0,
    labels: data?.labels?.length ? data.labels.map(normalizeLabels) : [],
    workspaceAccess: normalizeWorkspaceMemberPermission(data?.workspaceAccess),
  };
}

export function normalizeAttachmentFile(
  data: Dto<TAttachmentDataUrls>,
): TAttachmentDataUrls {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    url: data?.url ?? undefined,
    ref: data?.ref ?? undefined,
    mimeType: data?.mimeType ?? '',
    size: data?.size ?? 0,
    thumbnails: data?.thumbnails ?? {},
  };
}

export function normalizeTimelineResourceProject(
  data: Dto<TTimelineAllocationResponse>,
): TTimelineAllocationResponse {
  return {
    resourceId: data?.resourceId,
    projectId: data?.projectId,
    timeblocks: data?.timeblocks?.map(normalizeTimeblock) ?? [],
    totalAllocation: data?.totalAllocation ?? 0,
  };
}

export function normalizeWorkspaceMemberPermission(
  data: Dto<TWorkspaceAccess>,
): TWorkspaceAccess {
  return {
    hasAccess: data?.hasAccess ?? false,
    email: data?.email ?? undefined,
    pending: data?.pending ?? false,
    permission: data?.permission ?? WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    role: data?.role ?? null,
  };
}
export function normalizeResources(
  data: Dto<TResources & { hidden: { totalCount: number } }>,
): TResources {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: normalizeAttachmentFile(data?.image) ?? undefined,
    capacity: data?.capacity ? data?.capacity : 0,
    projects: data?.projects?.map(normalizeProject) ?? [],
    hiddenCount: data?.hidden?.totalCount ?? 0,
    labels: data?.labels?.length ? data.labels.map(normalizeLabels) : [],
    workspaceAccess:
      normalizeWorkspaceMemberPermission(data?.workspaceAccess) ?? {},
  };
}

export function normalizeFeatureFlags(data: TFeatureFlags): TFeatureFlags {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    description: data?.description ?? '',
    isActive: data?.isActive ?? false,
    expDate: data?.expDate ? new Date(data.expDate) : undefined,
    code: data?.code ?? '',
  };
}

export function normalizeWorkspaceCustomer(data: Dto<TWorkspaceCustomer>) {
  return {
    customerId: data.customerId ?? '',
    businessId: data.businessId ?? undefined,
    addressId: data.addressId ?? undefined,
  };
}

export type NumerifyDateType<T> = {
  [key in keyof T]: T[key] extends Date | undefined
    ? Date | number | undefined
    : T[key];
};

export function normalizeSubscriptionItem(item: Dto<TSubscriptionItemDto>) {
  return {
    priceId: item.priceId,
    productId: item.productId,
    productName: item.productName,
    priceName: item.priceName,
    currencyCode: item.currencyCode,
    priceAmount: item.priceAmount,
  };
}

export function normalizeSubscriptionNextTransaction(
  data: NumerifyDateType<TSubscriptionNextTransaction>,
): TSubscriptionNextTransaction {
  return {
    total: data.total,
    discount: data.discount,
    currencyCode: data.currencyCode && getSymbolFromCurrency(data.currencyCode),
  };
}

export function normalizeSubscription(
  data: NumerifyDateType<TSubscriptionDto>,
): TSubscriptionDto {
  return {
    id: data.id,
    subscriptionId: data.subscriptionId,
    nextBillingDate: data.nextBillingDate
      ? new Date(data.nextBillingDate)
      : undefined,
    billingPeriodStart: data.billingPeriodStart
      ? new Date(data.billingPeriodStart)
      : undefined,
    billingPeriodEnd: data.billingPeriodEnd
      ? new Date(data.billingPeriodEnd)
      : undefined,
    currencyCode: data.currencyCode,
    status: data.status,
    billingCycle: data.billingCycle,
    scheduledAction: data.scheduledAction,
    scheduledChangeAt: data.scheduledChangeAt
      ? new Date(data.scheduledChangeAt)
      : undefined,
    maxProjects: data.maxProjects ?? 0,
    // items: data.items?.map(normalizeSubscriptionItem) ?? [],
    nextTransaction: data.nextTransaction
      ? normalizeSubscriptionNextTransaction(data.nextTransaction)
      : undefined,
    lastUpdatedAt: data.lastUpdatedAt
      ? new Date(data.lastUpdatedAt)
      : new Date(),
    waitingForUpdate: data.waitingForUpdate ?? false,
  };
}

export function normalizeWorkspace(data: TUserWorkspace): TUserWorkspace {
  return {
    id: data?.id,
    role: data?.role ?? USER_ROLE.OWNER,
    name: data?.name ?? '',
    permission: data?.permission ?? WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    weeklyEmail: data?.weeklyEmail ?? false,
    mode: data?.mode ?? WORKSPACE_MODE.DAYS,
    autoSync: data?.autoSync ?? false,
    autoHide: data?.autoHide ?? false,
    features: data?.features?.map(normalizeFeatureFlags) ?? [],
    skipTrailing: data?.skipTrailing ?? false,
    accessToken: data?.accessToken
      ? normalizeTokenInfo(
          data.accessToken as unknown as Dto<AccessTokenInfo<number>>,
        )
      : undefined,
    customer: data?.customer && normalizeWorkspaceCustomer(data.customer),
  };
}

export function normalizeUserConsent(data: TAccountConsent): TAccountConsent {
  return {
    mustShow: data?.mustShow ?? false,
    modalText: data?.modalText ?? '',
  };
}
export function normalizeUserAccount(data: TUserAccount): TUserAccount {
  return {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    image: normalizeAttachmentFile(data?.image) ?? undefined,
    workspaces: data?.workspaces?.map(normalizeWorkspace) ?? [],
  };
}

export function normalizeFileToAttachmentDataUrl(
  file: File,
): TAttachmentDataUrls {
  const url = (window.URL || window.webkitURL).createObjectURL(file);
  const id = generateUUID();
  return {
    id,
    url,
    name: file.name,
    ref: undefined,
    mimeType: file.type,
    size: file.size,
  };
}

export function normalizeInvitationInfo(
  data: Dto<TInvitationInfo>,
): TInvitationInfo {
  return {
    email: data?.email ?? '',
    workspaceId: data?.workspaceId ?? '',
    workspace: data?.workspace ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    status: data?.status ?? '',
  };
}

export function normalizeInsightsCapacityChartData(
  data: Dto<TInsightsCapacityChartData>,
): TInsightsCapacityChartData {
  return {
    startDate: new Date(`${data?.startDate}T00:00`) as unknown as Date,
    endDate: new Date(`${data?.endDate}T23:59`) as unknown as Date,
    capacity: data?.capacity ?? 0,
  };
}

export function normalizeInsightsProject(
  data: Dto<TInsightsProject>,
): TInsightsProject {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    color: data?.color ?? PROJECT_COLOR.GREY,
    emoji: data?.emoji ?? '',
  };
}

export function normalizeInsightsActiveProjectsChartData(
  data: Dto<TInsightsActiveProjectsChartData>,
): TInsightsActiveProjectsChartData {
  return {
    startDate: new Date(`${data?.startDate}T00:00`) as unknown as Date,
    endDate: new Date(`${data?.endDate}T23:59`) as unknown as Date,
    activeProjectsCount: data?.activeProjectsCount ?? 0,
    projects: data?.projects?.map(normalizeInsightsProject) ?? [],
  };
}

export function normalizeInsightsActiveProjects(
  data: Dto<TInsightsActiveProjects>,
): TInsightsActiveProjects {
  return {
    today: normalizeInsightsActiveProjectsChartData(data?.today),
    lastWeeks:
      data?.lastWeeks?.map(normalizeInsightsActiveProjectsChartData) ?? [],
  };
}

export function normalizeWhosFreeInsight(
  whosFreeInsight: TCountedInsights<Dto<TWhosFree>>,
) {
  return {
    count: whosFreeInsight?.count ?? 0,
    data:
      whosFreeInsight?.data?.map(normalizeWhosFree)?.sort((first, second) => {
        const dateCompare =
          (first.startDate as Date).getTime() -
          (second.startDate as Date).getTime();
        const freeTimeCompare = second.freeTime - first.freeTime;
        return dateCompare === 0
          ? freeTimeCompare === 0
            ? first.lastName.localeCompare(second.lastName)
            : freeTimeCompare
          : dateCompare;
      }) ?? [],
  };
}

export function normalizeInsightsCapacity(
  data: Dto<TInsightsCapacity>,
): TInsightsCapacity {
  return {
    today: normalizeInsightsCapacityChartData(data?.today),
    lastWeeks: data?.lastWeeks?.map(normalizeInsightsCapacityChartData) ?? [],
  };
}

export function normalizeInsightsAvailableAndOverburdened(
  data: Dto<TInsightsOverburdened | TInsightsAvailable>,
): TInsightsOverburdened | TInsightsAvailable {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: normalizeAttachmentFile(data?.image) ?? undefined,
    allocation: data?.allocation ?? 0,
    max: data?.max ?? '',
    difference: data?.difference ?? 0,
  };
}

export function normalizeInsightsAvailableAndOverburdenedInsigths(
  available: TCountedInsights<Dto<TInsightsOverburdened | TInsightsAvailable>>,
) {
  return {
    count: available?.count ?? 0,
    data: available?.data?.map(normalizeInsightsAvailableAndOverburdened) ?? [],
  };
}

export function normalizeInsightsResource(
  data: Dto<TInsightsResource>,
): TInsightsResource {
  return {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: normalizeAttachmentFile(data?.image) ?? undefined,
  };
}

export function normalizeInsightsThisWeek(
  data: Dto<TInsightsThisWeek>,
): TInsightsThisWeek {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    color: data?.color ?? PROJECT_COLOR.GREY,
    emoji: data?.emoji ?? '',
    resources: data?.resources?.map(normalizeInsightsResource) ?? [],
  };
}

export function normalizeInsightsThisWeekInsights(
  thisWeek: TCountedInsights<Dto<TInsightsThisWeek>>,
) {
  return {
    count: thisWeek?.count ?? 0,
    data: thisWeek?.data?.map(normalizeInsightsThisWeek) ?? [],
  };
}

export function normalizeInsightsTopProjects(
  data: Dto<TInsightsTopProjects>,
): TInsightsTopProjects {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    color: data?.color ?? PROJECT_COLOR.GREY,
    emoji: data?.emoji ?? '',
    effort: data?.effort ?? 0,
  };
}

export function normalizeInsightsTopProjectsInsights(
  topProjects: TCountedInsights<Dto<TInsightsTopProjects>>,
) {
  return {
    count: topProjects?.count ?? 0,
    data: topProjects?.data?.map(normalizeInsightsTopProjects) ?? [],
  };
}

export function normalizeWorkloadPreferences(
  data: TWorkloadPreference,
): TWorkloadPreference {
  return {
    to: data?.to ?? 0,
    from: data?.from ?? 0,
    name: (data?.name && titleCase(data.name)) ?? '',
    emoji: data?.emoji,
    color: data?.color,
  };
}

export function normilizeAccessToken({
  accessToken,
  id,
}: {
  accessToken: string;
  id: string;
}) {
  return {
    accessToken,
    id,
  };
}

export function normalizeTokenInfo(
  data: Dto<AccessTokenInfo<number>>,
): AccessTokenInfo {
  return {
    id: data?.id ?? '',
    workspaceName: data?.workspaceName ?? '',
    createdAt: data?.createdAt ? new Date(data.createdAt) : new Date(),
    expiresAt: data?.expiresAt ? new Date(data.expiresAt) : undefined,
  };
}

export function normalizeWorkloadInsights(
  workloads: TCountedInsights<Dto<TWorkloadData>>,
) {
  return {
    count: workloads?.count ?? 0,
    data: (workloads?.data?.map((workload) => {
      return {
        startDate: new Date(`${workload.startDate}T00:00`) as unknown as Date,
        endDate: new Date(`${workload.endDate}T23:59`) as unknown as Date,
        workload: workload.workload ?? 0,
        workloadPref:
          workload?.workloadPref &&
          normalizeWorkloadPreferences(
            workload.workloadPref as TWorkloadPreference,
          ),
      };
    }) ?? []) as TWorkloadData[],
  };
}

export function normalizeInsightsStartingSoonProjects(
  data: Dto<TInsightsStartingSoon>,
): TInsightsStartingSoon {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    color: data?.color ?? PROJECT_COLOR.GREY,
    emoji: data?.emoji ?? '',
    effort: data?.effort ?? 0,
    begin: new Date(`${data?.begin}T00:00`) as unknown as Date,
  };
}

export function normalizeInsightsStartingSoonProjectsInsights(
  startingSoon: TCountedInsights<Dto<TInsightsStartingSoon>>,
) {
  return {
    count: startingSoon?.count ?? 0,
    data: startingSoon?.data?.map(normalizeInsightsStartingSoonProjects) ?? [],
  };
}

export function normalizeAssignedResource(
  data: Dto<TResourceWorkspaceAssigned>,
): TResourceWorkspaceAssigned {
  return {
    id: data?.id,
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: data?.image ?? undefined,
    email: data?.email ?? undefined,
    role: data?.role ?? undefined,
    permission: data?.permission ?? undefined,
    workspaceAccess: data?.workspaceAccess ?? undefined,
    capacity: data?.capacity ? data.capacity : 0,
  } as TResourceWorkspaceAssigned;
}

export function normalizePaddleProduct(data: Dto<TProduct>): TProduct {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    description: data?.description ?? '',
    features: data?.features ?? [],
    prices: data?.prices ?? [],
    type: data?.type ?? 'FREE',
    imageUrl: data?.imageUrl ?? undefined,
  };
}

export function normalizeWhosFree(dto: Dto<TWhosFree>) {
  return {
    firstName: dto.firstName ?? '',
    lastName: dto.lastName ?? '',
    image: dto.image ? normalizeAttachmentFile(dto.image) : undefined,
    allocation: dto.allocation ?? 0,
    freeTime: dto.freeTime ?? 0,
    startDate: new Date(`${dto.startDate}T00:00`) as unknown as Date,
    thisWeek: dto.thisWeek ?? false,
  } as TWhosFree;
}

export function normalizeInsightsPart<T extends INSIGHTS_TYPE>(
  insightsType: T,
  data: Dto<TInsights[TInsightsPartKey<T>]>,
): TInsights[TInsightsPartKey<T>] | undefined {
  switch (insightsType) {
    case INSIGHTS_TYPE.ACTIVE_PROJECTS:
      return normalizeInsightsActiveProjects(
        data as Dto<TInsightsActiveProjects>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.CAPACITY:
      return normalizeInsightsCapacity(
        data as Dto<TInsightsCapacity>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.WHOS_FREE:
      return normalizeWhosFreeInsight(
        data as TCountedInsights<Dto<TWhosFree>>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.STARTING_SOON:
      return normalizeInsightsStartingSoonProjectsInsights(
        data as TCountedInsights<Dto<TInsightsStartingSoon>>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.OVERBURDENED:
      return normalizeInsightsAvailableAndOverburdenedInsigths(
        data as TCountedInsights<
          Dto<TInsightsOverburdened | TInsightsAvailable>
        >,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.AVAILABLE:
      return normalizeInsightsAvailableAndOverburdenedInsigths(
        data as TCountedInsights<
          Dto<TInsightsOverburdened | TInsightsAvailable>
        >,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.THIS_WEEK:
      return normalizeInsightsThisWeekInsights(
        data as TCountedInsights<Dto<TInsightsThisWeek>>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.TOP_PROJECTS:
      return normalizeInsightsTopProjectsInsights(
        data as TCountedInsights<Dto<TInsightsTopProjects>>,
      ) as TInsights[TInsightsPartKey<T>];
    case INSIGHTS_TYPE.WORKLOAD:
      return normalizeWorkloadInsights(
        data as TCountedInsights<Dto<TWorkloadData>>,
      ) as TInsights[TInsightsPartKey<T>];
    default:
      return undefined;
  }
}

export function normalizeInsights(data: Dto<TInsights>): TInsights {
  return {
    activeProjects: normalizeInsightsActiveProjects(data?.activeProjects),
    capacity: normalizeInsightsCapacity(data?.capacity),
    overburdened: normalizeInsightsAvailableAndOverburdenedInsigths(
      data.overburdened,
    ),
    available: normalizeInsightsAvailableAndOverburdenedInsigths(
      data?.available,
    ),
    thisWeek: normalizeInsightsThisWeekInsights(data?.thisWeek),
    startingSoon: normalizeInsightsStartingSoonProjectsInsights(
      data?.startingSoon,
    ),
    topProjects: normalizeInsightsTopProjectsInsights(data?.topProjects),
    whosFree: normalizeWhosFreeInsight(data?.whosFree),
    workload: normalizeWorkloadInsights(data?.workload),
  };
}

export function normalizeResourceSearchResultInvitation(
  data: Dto<TResourceSearchResultInvitation>,
): TResourceSearchResultInvitation {
  return {
    id: data?.id ?? '',
    expirationDate: data?.expirationDate
      ? new Date(data?.expirationDate)
      : undefined,
  };
}

export function normalizeResourceSearchResult(
  data: Dto<TResourceSearchResult>,
): TResourceSearchResult {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    image: data?.image ? normalizeAttachmentFile(data.image) : undefined,
    role: data?.role ?? undefined,
    permission: data?.permission ?? undefined,
    email: data?.email ?? undefined,
    accountId: data?.accountId ?? undefined,
    capacity: data?.capacity ?? 0,
    status: data?.status ?? RESOURCE_STATUS.ACTIVE,
    labels: data?.labels?.length ? data.labels.map(normalizeLabels) : [],
    invitation: data?.invitation
      ? normalizeResourceSearchResultInvitation(data.invitation)
      : undefined,
  };
}

export function normalizeResourceItem(
  data: Dto<TResourceItemList | Omit<TResourceItemList, 'timeblocks'>>,
): TResourceItemList {
  const timeblocks = !isUndefined(get(data, 'timeblocks', undefined))
    ? get(data, 'timeblocks', [])?.map(normalizeTimeblock)
    : [];

  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    capacity: data?.capacity ?? 0,
    image: data?.image ? normalizeAttachmentFile(data.image) : undefined,
    totalAllocation: data?.totalAllocation ? data?.totalAllocation : 0,
    timeblocks,
    status: data?.status ?? RESOURCE_STATUS.ACTIVE,
    labels: data?.labels?.length ? data.labels.map(normalizeLabels) : [],
    workspaceAccess: normalizeWorkspaceMemberPermission(data?.workspaceAccess),
  };
}

export function normalizeProjectListWithResourcesStatus(
  data: Dto<TProjectListWithResourcesStatus<TResourceItemList>>,
) {
  const res = {
    status: data?.status ?? PROJECT_STATUS.UNCONFIRMED,
    projects: data?.projects.map(normalizeProjectListWithResources),
  };
  return res;
}

export function normalizeProjectListWithResources(
  data: Dto<TProjectListWithResourcesStatus<TResourceItemList>['projects'][0]>,
): TProjectListWithResourcesStatus<TResourceItemList>['projects'][0] {
  return {
    id: data?.id ?? '',
    type: data?.type ?? PROJECT_TYPE.PROJECT,
    color: data?.color ?? PROJECT_COLOR.GREY,
    maxWeek: data?.maxWeek ?? 0,
    name:
      data?.name === PROJECT_TYPE.TIME_OFF
        ? t('common:timeOff')
        : (data?.name ?? ''),
    emoji: data?.emoji ?? undefined,
    totalAllocation: data?.totalAllocation ? data?.totalAllocation : 0,
    resources: data?.resources?.map(normalizeResourceItem) ?? [],
    status: data?.status ?? undefined,
    shared: data?.shared ? normalizeSharedProjectInfo(data.shared) : undefined,
  };
}

export function normalizeTimelineProjects(
  data: Dto<TResourceItemList>,
): TResourceItemList {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    capacity: data?.capacity ?? 0,
    image: data?.image ? normalizeAttachmentFile(data.image) : undefined,
    totalAllocation: data?.totalAllocation ?? 0,
    timeblocks: data?.timeblocks?.map(normalizeTimeblock) ?? [],
    workspaceAccess: normalizeWorkspaceMemberPermission(data?.workspaceAccess),
    labels: data?.labels?.length ? data.labels.map(normalizeLabels) : [],
    status: data?.status ?? RESOURCE_STATUS.ACTIVE,
  };
}

export function normalizeInfiniteQuery(
  data: Dto<Omit<TPagedQuery<unknown>, 'results'>>,
): Omit<TPagedQuery<unknown>, 'results'> {
  return {
    count: data?.count ?? 0,
    nextPage: data?.nextPage ?? null,
    previousPage: data?.previousPage ?? null,
    totalCount: data?.totalCount ?? 0,
    page: data?.page ?? 0,
    pageSize: data?.pageSize ?? 0,
  };
}

export function normalizeUsageInfo(data: TWorkspaceUsageInfo) {
  return {
    projects: data?.projects?.length
      ? data.projects.map((p) => ({
          id: p.id,
          name: p.name,
          allocation: p.allocation ?? 0,
        }))
      : [],
    activeProjects: data?.activeProjects ?? 0,
  };
}

export function normalizeCompletedProject(data: Dto<TCompletedProject>[]) {
  return data.map((project) => {
    const prj = normalizeProject(project);
    return { ...prj, totalAllocation: project.totalAllocation ?? 0 };
  });
}

export function normalizeCompletedProjectList(
  data: TResponseList<Dto<TCompletedProject>>,
) {
  return {
    ...normalizeInfiniteQuery(data),
    results: (data?.data && normalizeCompletedProject(data?.data)) ?? [],
  } as TPagedQuery<TCompletedProject>;
}

export function normalizePaymentInfo(data: Dto<TPaymentInfo>): TPaymentInfo {
  return {
    type: data?.type ?? '',
    card: data?.card
      ? {
          type: data?.card?.type ?? undefined,
          last4: data?.card?.last4 ?? undefined,
          expiryMonth: data?.card?.expiryMonth ?? 0,
          expiryYear: data?.card?.expiryYear ?? 0,
        }
      : undefined,
  };
}

export function normalizeTransaction(data: Dto<TTransactions>): TTransactions {
  return {
    id: data?.id ?? '',
    status: data?.status ?? '',
    invoiceNumber: data?.invoiceNumber ?? '',
    subscriptionId: data?.subscriptionId ?? '',
    baseAmount: data?.baseAmount ?? 0,
    currency: data?.currency ?? '',
    billedAt: data?.billedAt ? new Date(data.billedAt) : undefined,
    billingPeriodEnd: data?.billingPeriodEnd
      ? new Date(data.billingPeriodEnd)
      : undefined,
    billingPeriodStart: data?.billingPeriodStart
      ? new Date(data.billingPeriodStart)
      : undefined,
    discount: data?.discount ?? 0,
    discountPercentage: data?.discountPercentage ?? 0,
    payment: data?.payment ? normalizePaymentInfo(data.payment) : undefined,
    tax: data?.tax ?? 0,
    totalAmount: data?.totalAmount ?? 0,
    productType: data?.productType ?? 'MONTHLY',
    productName: data?.productName ?? '',
  };
}

export function normalizeTransactionList(data: Dto<TTransactionsList>) {
  return {
    pageSize: data?.pageSize ?? 0,
    total: data.total ?? 0,
    count: data.count ?? 0,
    previousId: data.previousId ?? null,
    nextId: data.nextId ?? null,
    hasNext: data.hasNext ?? false,
    transactions: data?.transactions?.map(normalizeTransaction) ?? [],
  } as TTransactionsList;
}

export function normalizeCanDeleteAccountResourceAccountInfo(
  data: Dto<TResourceAccountInfo>,
): TResourceAccountInfo {
  return {
    id: data.id,
    accountId: data.accountId,
    accountEmail: data.accountEmail,
    image: data.image ? normalizeAttachmentFile(data.image) : undefined,
    lastName: data.lastName,
    firstName: data.firstName,
  };
}

export function normalizeSubscriptionInfo(
  data: NumerifyDateType<TSubscriptionInfo>,
): TSubscriptionInfo {
  return {
    id: data.id,
    status: data.status,
    nextBillingAt: data.nextBillingAt
      ? new Date(data.nextBillingAt)
      : undefined,
    scheduledAction: data.scheduledAction
      ? {
          status: data.scheduledAction.status,
          changeAt: data.scheduledAction.changeAt
            ? new Date(data.scheduledAction.changeAt)
            : undefined,
        }
      : undefined,
  };
}

export function normalizeCanDeleteAccountWs(
  data: NumerifyDateType<TOwnedWorkspaceInfo>,
): TOwnedWorkspaceInfo {
  return {
    id: data.id,
    name: titleCase(data.name) ?? '',
    owners: data.owners
      ? data.owners.map(normalizeCanDeleteAccountResourceAccountInfo)
      : [],
    members: data.members
      ? data.members.map(normalizeCanDeleteAccountResourceAccountInfo)
      : [],
    subscription: data.subscription
      ? normalizeSubscriptionInfo(data.subscription)
      : undefined,
  };
}

export function normalizeCanDeleteAccount(
  data: NumerifyDateType<TCanDeleteAccount>,
): TCanDeleteAccount {
  return {
    canDelete: data.canDelete,
    workspaces: data.workspaces?.length
      ? data.workspaces.map(normalizeCanDeleteAccountWs)
      : [],
  };
}

export function normalizeLabels(data: Dto<TLabel>): TLabel {
  return {
    id: data.id,
    value: data.value,
    color: data.color,
    order: data.order,
  };
}

export function normalizeFilterProject(data: Dto<TFiltersProject>) {
  return {
    id: data.id,
    name: data.name,
    color: data.color,
    status: data.status ?? PROJECT_STATUS.UNCONFIRMED,
    emoji: data.emoji ?? '',
    resCount: data.resCount || 0,
  };
}
