import { createContext, RefObject, useContext } from 'react';

import { TAttachmentDataUrls } from '@/types/generic';
import { TTimeBlockRange } from '@/types/timeline';

export type MouseEventRef = {
  mouseDownX?: number;
  mouseMoveX?: number;
  targetRect?: DOMRect;
  currentProps?: TTimeBlockRange;
  handle?: string;
};

const Context = createContext<{
  lengthWeeks: number;
  localProps: TTimeBlockRange;
  resourceName: string;
  resourceId: string;
  block: TTimeBlockRange;
  mouseEventRef: RefObject<MouseEventRef>;
  shouldShowTooltip: boolean;
  avatar?: TAttachmentDataUrls;
}>({
  mouseEventRef: { current: {} },
  lengthWeeks: 0,
  resourceId: '',
  localProps: { id: '', allocation: 0, start: new Date(), end: new Date() },
  resourceName: '',
  avatar: undefined,
  shouldShowTooltip: false,
  block: { id: '', allocation: 0, start: new Date(), end: new Date() },
});

export function useBlockContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('BlockContext must be used within a BlockContextProvider');
  }
  return context;
}

export default Context;
