import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { contextMenu } from 'react-contexify';

import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconChevronDown,
  IconChevronRight,
  IconDots,
  IconGripVertical,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { find } from 'lodash';

import {
  RESOURCE_STATUS,
  USER_ROLE,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import { TTimelineResource } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import { UIContext } from '@contexts/UIContext';
import { UserContext } from '@contexts/UserContext';
import { getCssVariable } from '@services/helpers';

import Avatar from '@components/Avatar';
import Button from '@components/Button';
import ResourceHandlerMenu from '@components/MenuUtility/ResourceHanlderMenu';
import LabelsIndicator from '@components/Timelines/common/LabelsIndicator';
import FlexContainer from '@components/utils/FlexContainer';

import styles from './styles.module.css';

type Props = {
  resource: TTimelineResource;
  isCompressed?: boolean;
  onCompress?: () => void;
  isDraggableOverlay?: boolean;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function ResourceHeader({
  resource,
  isCompressed,
  onCompress,
  isDraggableOverlay,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number } | undefined>(
    undefined,
  );

  const { id, firstName, lastName, capacity, image, workspaceAccess, labels } =
    resource;

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (160 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const MENU_ID = `team-context-menu-${firstName}-${lastName}`;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const { layoutIsExpanded } = useContext(UIContext);

  const isYou = useMemo(() => {
    return workspaceAccess?.email === account?.email;
  }, [account?.email, workspaceAccess?.email]);

  const canDelete = useMemo(() => {
    return (
      !isYou &&
      (workspaceAccess?.role !== USER_ROLE.OWNER ||
        workspace.role === USER_ROLE.OWNER)
    );
  }, [isYou, workspace.role, workspaceAccess?.role]);

  const labelTooltip = useMemo(() => {
    if (!resource.labels) return <span>No labels</span>;
    const len = resource.labels.length;
    const strPart = resource.labels
      .slice(0, 3)
      .map((label) => label.value)
      .sort();
    if (len > 3) strPart.push(`+${len - 3}`);

    return <span>{strPart.join(', ')}</span>;
  }, [resource.labels]);

  const resLabel = useMemo(() => {
    return {
      id: resource.id,
      labels: resource.labels?.slice(0, 3) ?? [],
    };
  }, [resource.id, resource.labels]);

  return (
    <>
      <div
        aria-hidden
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
          [styles.menuIsOpen]: menuIsOpen,
        })}
        onClick={onCompress}
      >
        <div className={styles.content}>
          {sortHandlerProps?.activator && (
            <div
              className={classNames(styles.sortHandler, {
                [styles.sortHandlerHidden]:
                  workspace?.permission ===
                  WORKSPACE_MEMBER_PERMISSION.READ_ONLY,
              })}
              {...sortHandlerProps?.listeners}
              {...sortHandlerProps?.attributes}
              ref={sortHandlerProps?.activator}
            >
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}
          {isDraggableOverlay && (
            <div className={styles.sortHandler}>
              <IconGripVertical size={16} />
            </div>
          )}
          <span className={styles.collapse}>
            {!isCompressed ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </span>
          <div className={styles.avatar}>
            <Avatar
              image={image?.ref}
              name={`${firstName} ${lastName}`}
              size="medium"
            />
          </div>
          <div className={styles.desktop}>
            <p className={styles.resourceName}>
              {firstName} {lastName}
            </p>
            <FlexContainer
              gap={0}
              justify="flex-end"
              align="center"
              className={styles.resourceLabels}
            >
              <LabelsIndicator
                withTooltip={true}
                tooltip={labelTooltip}
                resource={resLabel}
                dotClassName={styles.indicatorDot}
              />
              {workspace?.permission ===
                WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
                <Button
                  ref={triggerRef}
                  icon={IconDots}
                  variant="ghost"
                  size="small"
                  onClick={onClickFn}
                  className={styles.menuButton}
                />
              )}
            </FlexContainer>
          </div>
        </div>
      </div>
      <ResourceHandlerMenu
        id={id}
        firstName={firstName}
        lastName={lastName}
        menuId={MENU_ID}
        isYourself={isYou}
        capacity={capacity}
        canDelete={canDelete}
        image={image}
        workspaceAccess={workspaceAccess}
        status={RESOURCE_STATUS.ACTIVE}
        labels={labels}
        onVisibilityChange={(isVisible: boolean) => setMenuIsOpen(isVisible)}
      />
    </>
  );
}
