import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconGripVertical, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';

import { PROJECT_COLOR } from '@/types/enums';
import { TLabel } from '@/types/labels';

import useDeleteLabel from '@hooks/workspace/labels/useDeleteLabel';
import useUpdateLabel from '@hooks/workspace/labels/useUpdateLabel';
import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';
import ColorDot from '@components/ColorDot';
import Input from '@components/Input';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';

import ColorMenu from './ColorMenu';
import styles from './styles.module.css';

type LabelItemProps = {
  label: TLabel;
  focus?: boolean;
  onBlur?: () => void;
};

export default function LabelItem({ label, focus, onBlur }: LabelItemProps) {
  const { mutate: deleteLabel } = useDeleteLabel();

  const deleteLabelFn = useCallback(() => {
    deleteLabel(label.id);
  }, [deleteLabel, label.id]);

  const { mutate: updateLabel } = useUpdateLabel();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus) inputRef.current?.focus();
  }, [focus]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({
    id: label?.id,
    data: label,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
  };

  const [value, setValue] = useState(label.value);

  const menuRef = useRef<DropdownMenuRefType>(null);

  const updateLabelFn = useCallback(() => {
    if (value !== label.value)
      updateLabel(
        { labelId: label.id, label: { value, color: label.color } },
        { onSuccess: onBlur },
      );
  }, [label.color, label.id, label.value, onBlur, updateLabel, value]);

  const handleEnterKey: KeyboardEventHandler = useCallback((e) => {
    if (e.key === 'Enter') inputRef.current?.blur();
  }, []);

  const onColorChanged = (color: PROJECT_COLOR) => {
    updateLabel({
      labelId: label.id,
      label: { value, color },
    });
  };

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isDragging || menuIsOpen) {
      const handleEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          setMenuIsOpen(false);
        }
      };
      const opt = { capture: true };
      document.addEventListener('keydown', handleEsc, opt);
      return () => {
        document.removeEventListener('keydown', handleEsc, opt);
      };
    }
  }, [isDragging, menuIsOpen]);

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        position: isDragging ? 'relative' : undefined,
      }}
    >
      <div className={styles.labelItemContainer}>
        <div
          className={classNames(
            styles.sortHandler,
            styles.sortHandlerHidden,
            {},
          )}
          {...listeners}
          {...attributes}
          ref={setActivatorNodeRef}
        >
          <IconGripVertical
            size={16}
            color={getCssVariable('--color-neutral-500')}
          />
        </div>
        <ColorDot
          asButton
          color={label.color}
          size={16}
          onClick={(e) => menuRef.current?.show(e)}
        />
        <ColorMenu
          ref={menuRef}
          label={label}
          onColorSelect={onColorChanged}
          onMenuClose={() => {
            setMenuIsOpen(false);
          }}
          onMenuOpen={() => {
            setMenuIsOpen(true);
          }}
        />

        <Input
          ref={inputRef}
          value={value}
          name={`labelValue-${label.id}`}
          onBlur={() => updateLabelFn()}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleEnterKey}
        />
        <Button
          size="medium"
          variant="ghost"
          icon={IconTrash}
          onClick={deleteLabelFn}
        />
      </div>
    </div>
  );
}
