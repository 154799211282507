import { createContext, useContext } from 'react';

import { TTimelineHiddenProject } from '@/types/timeline';

const Context = createContext<{
  projects?: TTimelineHiddenProject[];
  resourceId: string;
  count: number;
}>({
  projects: undefined,
  resourceId: '',
  count: 0,
});

export function useProjectHideRowContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'ProjectRowContext must be used within a ProjectRowProvider',
    );
  }
  return context;
}

export default Context;
