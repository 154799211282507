import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TSharedProjectInfo } from '@/types/timeline';

import { UserContext } from '@contexts/UserContext';
import { shareProject } from '@services/api/workspace/projects';

import { onShareSuccess } from './on-success.util';

type ShareProjctProp = {
  projectId: string;
};

export default function useShareProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation<TSharedProjectInfo | null, Error, ShareProjctProp>({
    mutationFn: async ({ projectId }) => {
      if (!workspaceId) return null;
      return shareProject({ workspaceId, projectId });
    },
    onSuccess: (data, { projectId }) =>
      onShareSuccess?.(data, {
        projectId,
        workspaceId: workspaceId ?? '',
        queryClient,
      }),
  });
}
