import { initReactI18next } from 'react-i18next';

import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from '@locales/en/common.json';
import errorsEn from '@locales/en/errors.json';
import formsEn from '@locales/en/forms.json';
import modalsEn from '@locales/en/modals.json';
import notificationEn from '@locales/en/notifications.json';
import pagesEn from '@locales/en/pages.json';
import timelineEn from '@locales/en/timeline.json';

export const defaultNS = 'en';

export const resources = {
  en: {
    common: commonEn,
    timeline: timelineEn,
    errors: errorsEn,
    forms: formsEn,
    pages: pagesEn,
    notifications: notificationEn,
    modals: modalsEn,
  },
} as const;

const ns = Object.keys(resources.en);

// eslint-disable-next-line react-hooks/rules-of-hooks
use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en'],
    resources,
    ns,
  });
