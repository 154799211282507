import { forwardRef, useCallback, useRef } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';

import { GLOBAL_SCOPE } from '@/types/constants';

import BaseDropdownMenu from '@components/MenuUtility/DropdownMenu';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';
import { ShortcutContext } from '@components/ShortcutMenu';

import LabelFilterMenuItems from './childrens/LabelFilterMenuItems';
import styles from './styles.module.css';

type Props = {
  onVisibilityChange?: (isVisible: boolean) => void;
};

const MENU_ID = 'filters-menu';

// type MenuItem = {
//   id: 'label' | 'person' | 'project' | 'status';
//   value: string;
//   icon: React.ElementType;
// };

// const menuItems: MenuItem[] = [
//   { id: 'label', value: 'Label', icon: IconLabel },
//   { id: 'person', value: 'Person', icon: IconUserSquareRounded },
//   { id: 'project', value: 'Project', icon: IconCube },
//   { id: 'status', value: 'Status', icon: IconCircleDashed },
// ] as const;

// type MenuItemIds = (typeof menuItems)[number]['id'];
const FiltersMenu = forwardRef<DropdownMenuRefType, Props>(
  ({ onVisibilityChange }, ref) => {
    const { disableScope, enableScope } = useHotkeysContext();
    const isMenuOpen = useRef<boolean>(false);

    const onVisibilityChangeHandler = useCallback(
      (isVisible: boolean) => {
        if (isVisible) {
          enableScope(MENU_ID);
          disableScope(GLOBAL_SCOPE);
        } else if (isMenuOpen.current) {
          enableScope(GLOBAL_SCOPE);
          disableScope(MENU_ID);
          // setFilterSelection(undefined);
        }
        onVisibilityChange?.(isVisible);
        isMenuOpen.current = isVisible;
      },
      [disableScope, enableScope, onVisibilityChange],
    );

    // const [filterSelection, setFilterSelection] = useState<
    //   MenuItemIds | undefined
    // >();

    // const onFilterSelected = useCallback((item: MenuItem) => {
    //   setFilterSelection(item.id);
    // }, []);

    // const resetFiltersMenu = useCallback(() => {
    //   setFilterSelection(undefined);
    // }, []);

    // const onKeydown = useCallback(
    //   (e: KeyboardEvent) => {
    //     if (filterSelection && e.key === 'Escape') {
    //       e.stopPropagation();
    //       e.preventDefault();
    //       resetFiltersMenu();
    //     }
    //   },
    //   [filterSelection, resetFiltersMenu],
    // );

    // useEffect(() => {
    //   if (isMenuOpen.current) {
    //     const opt = { capture: true };
    //     window.addEventListener('keydown', onKeydown, opt);
    //     return () => {
    //       window.removeEventListener('keydown', onKeydown, opt);
    //     };
    //   }
    // }, [filterSelection, onKeydown]);

    // const renderFilterItems = useCallback((): ReactNode => {
    //   switch (filterSelection) {
    //     case 'label':
    //       return <LabelFilterMenuItems onNavigateBack={resetFiltersMenu} />;
    //     case 'person':
    //       return <PersonFilterMenuItems onNavigateBack={resetFiltersMenu} />;
    //     case 'project':
    //       return <ProjectsFilterMenuItems onNavigateBack={resetFiltersMenu} />;
    //     case 'status':
    //       return <StatusFilterMenuItems onNavigateBack={resetFiltersMenu} />;
    //     case undefined:
    //       throw new Error('Filter selection is undefined');
    //     default:
    //       // eslint-disable-next-line no-case-declarations
    //       const _exhaustiveCheck: never = filterSelection;
    //       throw new Error(`Unhandled filter selection: ${_exhaustiveCheck}`);
    //   }
    // }, [filterSelection, resetFiltersMenu]);

    return (
      <BaseDropdownMenu
        ref={ref}
        id={'filters-menu'}
        className={styles.filtersMenuContainer}
        onVisibilityChange={onVisibilityChangeHandler}
      >
        <ShortcutContext.Provider value={{ scope: 'filters-menu' }}>
          {/* <LabelFilterMenuItems onNavigateBack={resetFiltersMenu} /> */}
          <LabelFilterMenuItems />
          {/* {!filterSelection
            ? menuItems.map((item, idx) => (
                <ShortcutMenu.Item
                  key={item.id}
                  keyHandler={() => onFilterSelected(item)}
                  keyMatch={`${idx + 1}`}
                  closeOnClick={false}
                >
                  <div className={styles.itemWrapper}>
                    <item.icon size={20} />
                    <span>{item.value}</span>
                    <span className={styles.shortcut}>{idx + 1}</span>
                  </div>
                </ShortcutMenu.Item>
              ))
            : renderFilterItems()} */}
        </ShortcutContext.Provider>
      </BaseDropdownMenu>
    );
  },
);

FiltersMenu.displayName = 'FiltersMenu';
export default FiltersMenu;
