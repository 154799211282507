import { HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { RightSlot } from 'react-contexify';

import { IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';

import { PROJECT_STATUS } from '@/types/enums';
import { TProject } from '@/types/timeline';

import { StatusIndicator } from '@components/Indicators';
import ShortcutMenu from '@components/ShortcutMenu';

import ChangeStatusMenu from './ChangeStatusMenu';
import styles from './styles.module.css';

type Props = {
  project: TProject;
  menuId: string;
  keyMatch: string | readonly string[];
  keyHandler: () => void;
  submenuOpened: boolean;
  menuWidth: number;
  onItemClick?: (status: PROJECT_STATUS) => void;
  view?: 'people' | 'projects';
} & Pick<
  HTMLAttributes<HTMLElement>,
  'onMouseEnter' | 'onMouseLeave' | 'onFocus'
>;
function ChangeProjectStatusItem({
  project,
  menuId,
  keyMatch,
  keyHandler,
  onMouseEnter,
  onMouseLeave,
  submenuOpened,
  onItemClick,
  onFocus,
  menuWidth,
}: Props) {
  const statusRef = useRef<HTMLDivElement>(null);
  const subMenuPosition = useMemo(() => {
    if (submenuOpened) {
      const rect = statusRef?.current?.getBoundingClientRect();
      return {
        y: rect?.y ?? 0,
        x: (rect?.x ?? 0) + menuWidth - 6,
        width: menuWidth,
      };
    }
  }, [menuWidth, statusRef, submenuOpened]);

  const onItemClickFn = useCallback(
    (status: PROJECT_STATUS) => {
      if (status === project.status) return;
      else onItemClick?.(status);
    },
    [onItemClick, project?.status],
  );

  return (
    <ShortcutMenu.Item
      ref={statusRef}
      closeOnClick={false}
      keyHandler={keyHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      keyMatch={keyMatch ?? 's'}
      className={classNames(styles.subMenuTrigger, {
        [styles.subMenuTriggerActive]: submenuOpened,
      })}
    >
      <StatusIndicator
        showTooltip={false}
        type={project.status!}
        size={20}
        variant="blackAndWhite"
        classNames={styles.menuStatusIndicator}
      />
      Status <RightSlot>S</RightSlot>
      <RightSlot className="no-bg no-padding">
        <IconChevronRight size={14} />
      </RightSlot>
      {submenuOpened && (
        <ChangeStatusMenu
          menuId={menuId}
          status={project.status!}
          onItemClicked={onItemClickFn}
          position={subMenuPosition}
        />
      )}
    </ShortcutMenu.Item>
  );
}

export default ChangeProjectStatusItem;
