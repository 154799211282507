import { format, subDays } from 'date-fns';

import styles from './styles.module.css';

type Props = {
  startDate: Date;
  endDate: Date;
  note?: string;
};
export default function BlockTooltip({ startDate, endDate, note }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {note && (
          <div className={styles.noteContainer}>
            <span className={styles.note}>{note}</span> <span>•</span>
          </div>
        )}
        <span>
          {`${format(startDate, 'E, dd MMM')} - ${format(
            subDays(endDate, 2),
            'E, dd MMM',
          )}`}
        </span>
      </div>
    </div>
  );
}
