import { PropsWithChildren } from 'react';
import { Item } from 'react-contexify';
import { ClipLoader } from 'react-spinners';

import { useIntersectionObserver } from '@hooks/utils/useIntersectionObserver';

import FlexContainer from '@components/utils/FlexContainer';

import styles from './styles.module.css';

type Props = PropsWithChildren<{
  fetchOnScroll?: (entry: IntersectionObserverEntry[]) => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
}>;

export default function ItemScrollableContent({
  fetchOnScroll,
  hasNextPage = false,
  isFetching = false,
  isLoading = false,
  children,
}: Props) {
  const { setTarget } = useIntersectionObserver(fetchOnScroll);
  return (
    <FlexContainer
      className={styles.itemMenuItems}
      direction="column"
      align="stretch"
      gap={0}
    >
      {children}
      {hasNextPage && (
        <Item closeOnClick={false} className="unstyled-item">
          {!(isLoading || isFetching) ? (
            <div ref={setTarget} className={styles.pagination}></div>
          ) : (
            <ClipLoader size={32} />
          )}
        </Item>
      )}
    </FlexContainer>
  );
}
