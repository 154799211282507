import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { BaseResponse } from '@/types/base-responses';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TLabel, TLabelCreate } from '@/types/labels';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { http } from '@services/api';
import { normalizeLabels } from '@services/normalizer';

import { GET_WORKSPACE_LABELS_KEY } from './useGetWorkspaceLabels';

type Props = TSuccessErrorHook<TLabel>;

export default function useCreateLabel({ onSuccess, onError }: Props = {}) {
  const { workspace } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation({
    mutationFn: async (label: TLabelCreate) => {
      if (!workspace) return undefined;
      const {
        data: { data },
      } = await http.post<BaseResponse<TLabel>>(
        `workspaces/${workspace?.id}/labels`,
        label,
      );
      return data && normalizeLabels(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_LABELS_KEY],
        exact: false,
      });
      onSuccess?.(data);
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
      onError?.(error as unknown as TResponseError);
    },
  });
}
