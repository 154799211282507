import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { TWorkspaceAccess } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { addNewResourceAndAssignToProject } from '@services/api/workspace/resources';

type Props = {
  projectId: string;
  firstName: string;
  lastName: string;
  capacity: number;
  workspaceAccess: TWorkspaceAccess;
  image: {
    filePath: string;
  };
};

export default function useAddNewResourceAndAssignToProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({
      projectId,
      firstName,
      lastName,
      capacity,
      image,
      workspaceAccess,
    }: Props): Promise<void> => {
      await addNewResourceAndAssignToProject({
        workspaceId,
        firstName,
        lastName,
        projectId: projectId as string,
        capacity,
        image,
        workspaceAccess,
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
