import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { TWorkspaceAccess } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { SEARCH_RESOURCES_KEY } from '@hooks/workspace/resources/useSearchResourcesQuery';
import { addNewResource } from '@services/api/workspace/resources';

import { GET_MEMBERS_COUNT_BY_STATUS_QUERY } from './useResourceCountByStatusQuery';
import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';

type Props = {
  firstName: string;
  lastName: string;
  capacity: number;
  labels?: string[];
  workspaceAccess: TWorkspaceAccess;
  image: {
    filePath: string;
  };
};

export default function useAddNewResource() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({
      firstName,
      lastName,
      capacity,
      image,
      labels,
      workspaceAccess,
    }: Props): Promise<void> => {
      await addNewResource({
        workspaceId,
        firstName,
        lastName,
        capacity,
        image,
        labels,
        workspaceAccess,
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_RESOURCES_KEY] });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_MEMBERS_COUNT_BY_STATUS_QUERY],
      });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
