import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { EventEmitter } from '@services/eventEmitter';

type EventEmitteContextProp = {
  eventEmitter?: EventEmitter;
};

const EventEmitterContext = createContext<EventEmitteContextProp>({
  eventEmitter: undefined,
});

export function useEventEmitter<T>(
  event: string,
  callback?: (data?: T) => void,
  enabled = true,
): { emitter?: EventEmitter; next?: (data?: T) => void } {
  const context = useContext(EventEmitterContext);
  const emitter = useRef(context.eventEmitter);

  useEffect(() => {
    if (callback && enabled) return emitter.current?.subscribe(event, callback);
  }, [event, callback, emitter, enabled]);

  return emitter
    ? {
        emitter: emitter.current,
        next: (data?: T) => emitter.current?.dispatch(event, data),
      }
    : { emitter: undefined, next: undefined };
}

export default function EventEmitterProvider({
  children,
}: {
  children: ReactNode | ReactElement | undefined;
}) {
  const eventEmitter = useMemo(() => new EventEmitter(), []);

  return (
    <EventEmitterContext.Provider value={{ eventEmitter: eventEmitter }}>
      {children}
    </EventEmitterContext.Provider>
  );
}
