import { RefObject } from 'react';
import { Item } from 'react-contexify';

import { IconArrowLeft } from '@tabler/icons-react';
import { t } from 'i18next';

import Button from '@components/Button';
import Input from '@components/Input';

import styles from './styles.module.css';

type Props = {
  value: string;
  onNavigateBack?: () => void;
  onValueChanged?: (value: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  ref?: RefObject<HTMLInputElement>;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onKeyDown' | 'onFocus' | 'onBlur'
>;

export default function ItemSearch({
  onNavigateBack,
  onValueChanged,
  autoFocus = true,
  value,
  onKeyDown,
  placeholder,
  onBlur,
  onFocus,
  ref,
}: Props) {
  return (
    <Item
      className="unstyled-item"
      style={{ opacity: 1 }}
      disabled
      closeOnClick={false}
    >
      <div className={styles.searchWrapper}>
        {onNavigateBack && (
          <Button
            icon={IconArrowLeft}
            iconProps={{ size: 20 }}
            variant="ghost"
            size="medium"
            onClick={onNavigateBack}
          />
        )}
        <Input
          ref={ref}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={onFocus}
          isDark
          name="input"
          variant="simple"
          placeholder={placeholder ?? `${t('common:search')}...`}
          value={value}
          onChange={(e) => onValueChanged?.(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </div>
    </Item>
  );
}
