import { useContext } from 'react';

import { getAuth, getIdTokenResult } from '@firebase/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { TUserAccount } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { ACCOUNT_QUERY_KEY } from '@hooks/account/useAccountQuery';
import { updateAccount } from '@services/api/account';

type Props = {
  sendSuccessNotification?: boolean;
  sendErrorNotification?: boolean;
};
export default function useUpdateAccount({
  sendErrorNotification,
  sendSuccessNotification,
}: Props = {}) {
  const queryClient = useQueryClient();
  const auth = getAuth();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<
    TUserAccount | undefined,
    Error,
    Partial<Omit<TUserAccount, 'workspaces' | 'image'>>
  >({
    mutationFn: async (
      accountData: Partial<Omit<TUserAccount, 'workspaces' | 'image'>>,
    ) => await updateAccount(accountData),
    onMutate: async (
      accountData: Partial<
        Omit<TUserAccount, 'workspaces' | 'image'> & {
          password?: string;
        }
      >,
    ) => ({
      firstName: accountData.lastName,
      lastName: accountData.lastName,
    }),
    onSuccess: async () => {
      if (auth.currentUser) await getIdTokenResult(auth.currentUser, true);
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
      if (sendSuccessNotification)
        addNotification({
          type: 'success',
          title: t('notifications:generic.success') as string,
          description: t('notifications:userSettings.success'),
        });
    },
    onError: (error) => {
      if (sendErrorNotification)
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
    },
  });
}
