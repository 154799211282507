import { createContext, useCallback, useMemo, useState } from 'react';

import useValidatePublicToken from '@/shared-app/hooks/useValidatePublicToken';
import axios, { AxiosHeaders, type AxiosInstance, isAxiosError } from 'axios';
import i18next from 'i18next';
import { noop } from 'lodash';
import { useLocalStorage } from 'use-hooks';

import Loader from '@components/Loader';
import { Error403 } from '@screens/ErrorScreen/403';
import { Error500 } from '@screens/ErrorScreen/500';

type SharedAppContextType = {
  uuid?: string;
  valid: boolean;
  publicHttpClient: AxiosInstance;
  clientInitialized: boolean;
  layoutIsExpanded: boolean;
  onChangeLayout: () => void;
};

const httpClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/public`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const SharedAppContext = createContext<SharedAppContextType>({
  uuid: '',
  valid: false,
  publicHttpClient: httpClient,
  clientInitialized: false,
  layoutIsExpanded: false,
  onChangeLayout: noop,
});

export function SharedAppProvider({
  children,
  uuid,
}: {
  children: React.ReactNode;
  uuid: string;
}) {
  const [clientInitialized, setClientInitialized] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);

  const [layoutIsExpanded, setLayoutIsExpanded] = useLocalStorage<boolean>(
    'pbl-layoutIsExpanded',
    true,
  );

  const onChangeLayout = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setLayoutIsExpanded((prevState: boolean) => !prevState);
  }, [setLayoutIsExpanded]);

  const initializeClient = useCallback(
    (data?: { isValid: boolean; uuid: string }) => {
      if (!data) return;
      setValid(data.isValid);
      const { uuid } = data;
      if (uuid) {
        httpClient.interceptors.request.use(async (config) => {
          if (!config.headers) config.headers = new AxiosHeaders();
          config.headers.Authorization = `Bearer ${uuid}`;
          config.headers['x-language'] = i18next.language;
          return config;
        });
        setClientInitialized(true);
      }
    },
    [],
  );

  const { isPending, isError, error } = useValidatePublicToken({
    uuid,
    onSuccess: initializeClient,
    onError(_error) {
      setValid(false);
    },
  });

  const ErrorComponent = useMemo(() => {
    if (isError) {
      if (isAxiosError(error) && error.response?.data?.status === 403) {
        return <Error403 onClearError={noop} />;
      } else {
        return <Error500 />;
      }
    }
    return false;
  }, [error, isError]);

  return (
    <SharedAppContext.Provider
      value={{
        uuid,
        valid,
        publicHttpClient: httpClient,
        clientInitialized,
        layoutIsExpanded,
        onChangeLayout,
      }}
    >
      {isPending ? <Loader /> : !isError ? children : ErrorComponent}
    </SharedAppContext.Provider>
  );
}
