import { useContext } from 'react';

import classNames from 'classnames';

import { UIContext } from '@contexts/UIContext';
import { weeksToPixels } from '@services/helpers';

import { TimelineProjectsContext } from '@components/Timelines/TimelineProjects/context';

import { useResourceRowContext } from './Context';
import styles from './styles.module.css';

export default function Cursor() {
  const { hoverWeek } = useResourceRowContext();

  const { weekSizeInPx, timeInterval } = useContext(TimelineProjectsContext);
  const { layoutIsExpanded } = useContext(UIContext);

  return (
    <div
      className={classNames(styles.cursor, {
        [styles.isExpanded]: layoutIsExpanded,
      })}
      style={{
        width: weekSizeInPx + 1,
        left: hoverWeek
          ? weeksToPixels(hoverWeek, timeInterval.start, false, weekSizeInPx)
          : 0,
      }}
    ></div>
  );
}
