import { useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';

import { FirebaseError } from '@firebase/app';
import { getAuth, sendPasswordResetEmail } from '@firebase/auth';
import classNames from 'classnames';
import { t } from 'i18next';

import { UserContext } from '@contexts/UserContext';

import Button from '@components/Button';
import CheckYourInbox from '@components/CheckInbox';
import Modal from '@components/Modals/Modal';
import ModalChangeEmail from '@components/Modals/ModalChangeEmail';
import Section from '@components/Section';

import styles from './styles.module.css';

export default function AccountSecuritySection() {
  const { account } = useContext(UserContext);
  const [resetEmailSent, setResetEmailSent] = useState<boolean>(false);
  const [isResettingPassword, setIsResettingPassword] =
    useState<boolean>(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] =
    useState<boolean>(false);
  const auth = getAuth();

  const onResetPassword = useCallback(() => {
    if (!account?.email) return;
    setIsResettingPassword(true);
    const signIn = async () => {
      try {
        await sendPasswordResetEmail(auth, account?.email).then(() => {
          setResetEmailSent(true);
        });
      } catch (error) {
        const err = error as FirebaseError;
        console.error(err);
      } finally {
        setIsResettingPassword(false);
      }
    };
    signIn();
  }, [account?.email, auth]);

  return (
    <Section title="Account security">
      <div className={classNames(styles.inputWrapper, styles.marginTop)}>
        <div className={styles.rowWrapper}>
          <div className={styles.rowLabelWrapper}>
            <span className={styles.rowLabel}>
              {t('forms:profileSettings.email.label')}
            </span>
            <span className={styles.rowDescription}>{account?.email}</span>
          </div>
          <Button
            size="medium"
            label={t('forms:profileSettings.email.cta')}
            onClick={() => {
              setIsChangeEmailModalOpen(true);
            }}
            variant="outline"
          />
        </div>
      </div>
      <div className={classNames(styles.inputWrapper, styles.marginTop)}>
        <div className={styles.rowWrapper}>
          <div className={styles.rowLabelWrapper}>
            <span className={styles.rowLabel}>
              {t('forms:profileSettings.password.label')}
            </span>
            <span className={styles.rowDescription}>
              {t('forms:profileSettings.password.description')}
            </span>
          </div>
          <Button
            size="medium"
            label={t('forms:profileSettings.password.cta')}
            onClick={() => {
              setResetEmailSent(false);
              setIsResetModalOpen(true);
            }}
            variant="outline"
            disabled={isResettingPassword}
            isLoading={isResettingPassword}
          />
        </div>
      </div>
      {isResetModalOpen &&
        (resetEmailSent ? (
          <Modal
            modalKey={'reset-password-modal'}
            enableClose
            isOpen={isResetModalOpen}
            onClose={() => setIsResetModalOpen(false)}
          >
            <div className={styles.resetSentModal}>
              <CheckYourInbox
                title={t('common:checkInbox')}
                message={t('common:inboxResetLink')}
              />
            </div>
          </Modal>
        ) : (
          <Modal
            title={t('forms:profileSettings.password.modalReset.title')}
            modalKey={'reset-password-modal'}
            enableClose
            isOpen={isResetModalOpen}
            onClose={() => setIsResetModalOpen(false)}
            footer={
              <>
                <Button
                  label={'Send reset link'}
                  size="medium"
                  onClick={onResetPassword}
                  isLoading={isResettingPassword}
                  disabled={isResettingPassword}
                />
                <Button
                  label={t('common:cancel')}
                  size="medium"
                  variant="ghost"
                  onClick={() => {
                    setIsResetModalOpen(false);
                  }}
                />
              </>
            }
          >
            <p className={styles.resetModalMessage}>
              <Trans
                t={t}
                ns={'forms'}
                i18nKey="profileSettings.password.modalReset.message"
                values={{ email: account?.email }}
                components={{ strong: <strong /> }}
              />
            </p>
          </Modal>
        ))}

      {isChangeEmailModalOpen && (
        <ModalChangeEmail
          isOpen={isChangeEmailModalOpen}
          modalKey="change-email-modal"
          onClose={() => setIsChangeEmailModalOpen(false)}
        />
      )}
    </Section>
  );
}
