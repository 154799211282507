import { useContext } from 'react';

import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { BaseResponse } from '@/types/base-responses';
import {
  TPagedQuery,
  TResponseError,
  TSuccessErrorHook,
} from '@/types/generic';
import { TLabel, TLabelUpdate } from '@/types/labels';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { http } from '@services/api';
import { normalizeLabels } from '@services/normalizer';

import { GET_WORKSPACE_LABELS_KEY } from './useGetWorkspaceLabels';

type Props = TSuccessErrorHook<TLabel>;

export default function useUpdateLabel({ onSuccess, onError }: Props = {}) {
  const { workspace } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation({
    mutationFn: async ({
      labelId,
      label,
    }: {
      labelId: string;
      label: TLabelUpdate;
    }) => {
      if (!workspace) return undefined;
      const {
        data: { data },
      } = await http.put<BaseResponse<TLabel>>(
        `workspaces/${workspace?.id}/labels/${labelId}`,
        label,
      );
      return data && normalizeLabels(data);
    },
    onMutate: async ({ labelId, label }) => {
      const _prevLabels = queryClient.getQueriesData<
        InfiniteData<TPagedQuery<TLabel>>
      >({
        queryKey: [GET_WORKSPACE_LABELS_KEY],
      });

      queryClient.setQueriesData<InfiniteData<TPagedQuery<TLabel>>>(
        { queryKey: [GET_WORKSPACE_LABELS_KEY] },
        (prevLabels) => {
          if (!prevLabels) return prevLabels;
          const cloned = cloneDeep(prevLabels);
          cloned?.pages.forEach((page) => {
            page.results
              .filter((lb) => lb.id == labelId)
              .forEach((lb) => {
                lb.value = label.value;
                lb.color = label.color;
              });
          });
          return cloned;
        },
      );

      return _prevLabels;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_LABELS_KEY] });
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError: (error, _vars, prevValues) => {
      prevValues?.forEach(([_query, data]) => {
        if (data) queryClient.setQueryData(_query, { ...data });
      });
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
      onError?.(error as unknown as TResponseError);
    },
  });
}
