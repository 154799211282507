import BlockTooltip from '@components/Blocks/BlockTooltip';

import { useBlockContext } from './Context';

export default function Tooltip() {
  const { localProps, shouldShowTooltip } = useBlockContext();

  return shouldShowTooltip ? (
    <BlockTooltip
      startDate={localProps?.start}
      endDate={localProps?.end}
      note={localProps?.note}
    />
  ) : (
    false
  );
}
