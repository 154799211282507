import { useCallback, useContext } from 'react';

import { t } from 'i18next';

import { AccessTokenInfo } from '@/types/workspace';

import { UserContext } from '@contexts/UserContext';
import useRevokeAccessToken from '@hooks/access-token/useRevokeAccessToken';
import { ANALYTICS_EVENTS, useAnalytics } from '@hooks/utils/useAnalytics';

import Button from '@components/Button';
import Modal from '@components/Modals/Modal';

import styles from './styles.module.css';

type Props = {
  tokenInfo: AccessTokenInfo & { workspaceId: string };
  isOpen: boolean;
  onClose: () => void;
};

export default function ModalRevokeAccessToken({
  tokenInfo,
  isOpen,
  onClose,
}: Props) {
  const { mutate: revokeToken, isPending: isRevoking } = useRevokeAccessToken();
  const { workspace } = useContext(UserContext);
  const { trackEvent } = useAnalytics();
  const revokeAccessTokenFn = useCallback(async () => {
    const { id: accessTokenId, workspaceId } = tokenInfo;
    revokeToken(
      { workspaceId, accessTokenId },
      {
        onSuccess: () => {
          trackEvent(
            ANALYTICS_EVENTS.PERSONAL_ACCESS_TOKEN_REVOKED,
            workspace?.id as string,
          );
          onClose();
        },
      },
    );
  }, [onClose, revokeToken, tokenInfo, trackEvent, workspace?.id]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-revoke-token-${tokenInfo.id}`}
      onClose={onClose}
      title={t('common:modals.api.revoke.title')}
      footer={
        <>
          <Button
            label={t('common:modals.api.revoke.primaryCtaLabel')}
            onClick={revokeAccessTokenFn}
            isLoading={isRevoking}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.container}>
        <p className={styles.alertTitle}>
          {t('common:modals.api.revoke.description')}
        </p>
        <p className={styles.actionLabel}>
          {t('common:modals.api.revoke.actionLabel')}
        </p>
      </div>
    </Modal>
  );
}
