import { useContext, useMemo } from 'react';

import { Emoji } from 'emoji-picker-react';
import { t } from 'i18next';

import { UIContext } from '@contexts/UIContext';
import { UserContext } from '@contexts/UserContext';

import { useBlockContext } from './Context';
import styles from './styles.module.css';

export default function Content() {
  const { layoutIsExpanded } = useContext(UIContext);
  const { workspaceMode } = useContext(UserContext);
  const { lengthWeeks, projectName, block, projectEmoji, localProps } =
    useBlockContext();

  const canShowProject = useMemo(() => {
    return !localProps.note || lengthWeeks > 2;
  }, [lengthWeeks, localProps.note]);

  return (
    <p className={styles.content}>
      {lengthWeeks >= 1 || layoutIsExpanded ? (
        <span className={styles.projectNameWrapper}>
          {projectEmoji && <Emoji unified={projectEmoji ?? ''} size={14} />}
          <strong className={styles.projectName} title={projectName}>
            {canShowProject && (
              <>
                {projectName}
                {!layoutIsExpanded && ' · '}
              </>
            )}
            {localProps?.note && (
              <>
                {layoutIsExpanded && canShowProject && ' · '}
                <span>{localProps.note}</span>
                {`${!layoutIsExpanded ? ' · ' : ''}`}
              </>
            )}
          </strong>
        </span>
      ) : null}{' '}
      <strong>{block.allocation}</strong>{' '}
      {layoutIsExpanded
        ? lengthWeeks < 1
          ? block.allocation === 1
            ? t(`timeline:blockAllocationSingularShort${workspaceMode}`)
            : t(`timeline:blockAllocationPluralShort${workspaceMode}`)
          : block.allocation === 1
            ? t(`timeline:blockAllocationSingular${workspaceMode}`)
            : t(`timeline:blockAllocationPlural${workspaceMode}`)
        : lengthWeeks < 1
          ? t(`timeline:blockAllocationCompactShort${workspaceMode}`)
          : t(`timeline:blockAllocationCompact${workspaceMode}`)}
    </p>
  );
}
