import { t } from 'i18next';

import { useProfileModal } from '@contexts/ModalPreferenceContext';
import { useWorkspaceFromContext } from '@hooks/workspace/useWorkspaceFromContext';

import Button from '@components/Button';
import Modal from '@components/Modals/Modal';
import { ContentPages } from '@components/Modals/ModalUserProfile';

import styles from './styles.module.css';

type Props = Omit<
  Parameters<typeof Modal>[0],
  'children' | 'modalKey' | 'title'
>;

export default function RemoveSubAlert({ isOpen, onClose }: Props) {
  const { setSelectedPageId } = useProfileModal();
  const { workspace } = useWorkspaceFromContext();
  return (
    <Modal
      modalKey={'remove-sub-first'}
      title={'Subscription found'}
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <>
          <Button
            label="Manage plan"
            size="medium"
            variant="outline"
            onClick={() => {
              onClose?.();
              setSelectedPageId(ContentPages.PLANS);
            }}
          />
          <Button
            label="Cancel"
            size="medium"
            variant="ghost"
            onClick={() => onClose?.()}
          />
        </>
      }
      bodyClassName={styles.removeSubAlert}
    >
      <p className={styles.actionLabel}>
        {t('common:modals.deleteWorkspace.removeSubAlert', {
          name: workspace?.name,
        })}
      </p>
    </Modal>
  );
}
