import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { SEARCH_RESOURCES_KEY } from '@hooks/workspace/resources/useSearchResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@hooks/workspace/resources/useTimelineResourceQuery';
import { USAGE_INFO_QUERY_KEY } from '@hooks/workspace/useUsageInfo';
import { removeProjectToResource } from '@services/api/workspace/resources';

type Props = {
  resourceId: string;
  projectId: string;
};

export default function useUnassignProjectToResource() {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>({
    mutationFn: async ({ resourceId, projectId }: Props): Promise<void> => {
      await removeProjectToResource({ workspaceId, resourceId, projectId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_RESOURCES_KEY] });
      queryClient.invalidateQueries({
        queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
      });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
