import { useMutation, useQueryClient } from '@tanstack/react-query';

import { http } from '@services/api';

import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';

type InviteToWorkspaceProps = {
  resourceId: string;
  email: string;
  permission: string;
  workspaceId: string;
};

const INVITE_TO_WORKSPACE = 'invite-to-workspace';
export default function useInviteToWorkspace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [INVITE_TO_WORKSPACE],
    mutationFn: async ({
      email,
      permission,
      workspaceId,
      resourceId,
    }: InviteToWorkspaceProps) => {
      await http.put(
        `/workspaces/${workspaceId}/resources/${resourceId}/invite-to-workspace`,
        {
          email,
          permission,
        },
      );
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
    },
  });
}
