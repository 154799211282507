import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router';

import { AxiosError, isAxiosError } from 'axios';
import { find, first } from 'lodash';

import { PlannException } from '@/types/base-responses';
import { ROUTES } from '@/types/routes';
import { TUserWorkspace } from '@/types/workspace';

import { ModalPreferencesProvider } from '@contexts/ModalPreferenceContext';
import { UserContext } from '@contexts/UserContext';
import { http } from '@services/api';

import { Error403 } from './ErrorScreen/403';
import Error404 from './ErrorScreen/404';
import { Error500 } from './ErrorScreen/500';

enum ERROR_STATUS {
  ERROR = 500,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
}

export default function Home() {
  const { workspaceId: workspaceIdFromUrl } = useParams<{
    workspaceId?: string;
  }>();
  const { account, isUserEmailVerified, workspaceId, setWorkspaceId } =
    useContext(UserContext);

  const workspaceHasValue = useMemo(
    () => Boolean(workspaceIdFromUrl || workspaceId),
    [workspaceId, workspaceIdFromUrl],
  );

  const firstWorkspace = useMemo(
    () => first(account?.workspaces),
    [account?.workspaces],
  );

  const currentWorkspaceByUrl = useMemo(
    () =>
      find(account?.workspaces, {
        id: workspaceIdFromUrl,
      }) as TUserWorkspace,
    [workspaceIdFromUrl, account?.workspaces],
  );

  const currentWorkspaceByLocalStorage = useMemo(
    () => find(account?.workspaces, { id: workspaceId }) as TUserWorkspace,
    [workspaceId, account?.workspaces],
  );

  const currentWorkspace =
    currentWorkspaceByLocalStorage || currentWorkspaceByUrl;

  const [hasError, setHasError] = useState<ERROR_STATUS | null>(null);

  const _id = useMemo(
    () =>
      http.interceptors.response.use(
        (response) => response,
        (error: AxiosError<PlannException>) => {
          setHasError(error?.response?.status as ERROR_STATUS);
          if (isAxiosError(error) && error.response?.data.message)
            return Promise.reject({
              message: error.response?.data.message,
              error: error.response?.data,
              status: error.response?.status,
              code: error.response?.data.code,
            });
          return Promise.reject(error);
        },
      ),
    [],
  );

  useEffect(() => {
    if (
      workspaceIdFromUrl !== workspaceId ||
      (firstWorkspace && !workspaceIdFromUrl)
    ) {
      setWorkspaceId(workspaceIdFromUrl ?? firstWorkspace?.id);
    }
  }, [
    currentWorkspace,
    currentWorkspaceByLocalStorage,
    currentWorkspaceByUrl,
    firstWorkspace,
    setWorkspaceId,
    workspaceId,
    workspaceIdFromUrl,
  ]);

  const navigate = useNavigate();

  const onClearErrorFn = useCallback(() => {
    setHasError(null);
    navigate(ROUTES.ROOT, { replace: true });
  }, [navigate]);

  if (hasError === ERROR_STATUS.FORBIDDEN) {
    return <Error403 onClearError={onClearErrorFn} />;
  } else if (hasError === ERROR_STATUS.NOT_FOUND) {
    return <Error404 />;
  } else if (hasError === ERROR_STATUS.ERROR) {
    return <Error500 onClearError={onClearErrorFn} />;
  }

  if (
    !currentWorkspaceByUrl &&
    !currentWorkspaceByLocalStorage &&
    !workspaceHasValue &&
    isUserEmailVerified &&
    !firstWorkspace
  ) {
    // Navigate to workspace creation if no workspace is found and no workspaceId is provided
    return <Navigate to={ROUTES.ON_BOARDING} replace />;
  } else if (!isUserEmailVerified) {
    return <Navigate to={ROUTES.EMAIL_VERIFICATION} replace />;
  } else if (
    (!currentWorkspaceByUrl && workspaceIdFromUrl) ||
    (!currentWorkspaceByLocalStorage && workspaceId)
  ) {
    // Navigate to 404 if no workspace is found and workspaceId is provided
    return <Error404 />;
  } else if (currentWorkspace && !workspaceIdFromUrl) {
    return (
      <Navigate
        to={`${ROUTES.DASHBOARD?.replace(
          ':workspaceId',
          currentWorkspace?.id,
        )}`}
        replace
      />
    );
  } else {
    // Navigate to dashboard if workspace is found
    return (
      <ModalPreferencesProvider>
        <Outlet />
      </ModalPreferencesProvider>
    );
  }
}
