import NB from '@components/Timelines/TimelineProjects/NewBlock';

import { useResourceRowContext } from './Context';

export default function NewBlock() {
  const { resource, mouseEventRef, newBlock, project } =
    useResourceRowContext();

  return (
    newBlock &&
    resource?.id === mouseEventRef.current.resourceId && (
      <NB
        projectColor={project?.color ?? ''}
        block={newBlock}
        projectName={resource?.firstName ?? ''}
      />
    )
  );
}
