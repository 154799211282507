import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

export function initSentry() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/mz-plann-staging\.herokuapp\.com\/api/,
      /^https:\/\/backendd.hellotime\.team\/api/,
    ],
    integrations: [
      // eslint-disable-next-line
      Sentry.browserTracingIntegration(),

      // eslint-disable-next-line
      // Sentry.replayIntegration(),
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
