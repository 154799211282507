import { useContext } from 'react';

import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { BaseResponse } from '@/types/base-responses';
import {
  TPagedQuery,
  TResponseError,
  TSuccessErrorHook,
} from '@/types/generic';
import { TLabel } from '@/types/labels';

import { UserContext } from '@contexts/UserContext';
import { http } from '@services/api';

import { GET_WORKSPACE_LABELS_KEY } from './useGetWorkspaceLabels';

export default function useOrderLabels({
  onSuccess,
  onError,
}: Omit<TSuccessErrorHook<unknown>, 'enabled'> = {}) {
  const { workspace } = useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      fromLabel,
      toLabel,
    }: {
      fromLabel: TLabel;
      toLabel: TLabel;
    }) => {
      if (!workspace) throw new Error('Workspace not found');
      const data = await http.put<BaseResponse<unknown>>(
        `workspaces/${workspace?.id}/labels/order`,
        {
          from: fromLabel.id,
          to: toLabel.id,
        },
      );
      return data.data;
    },
    onMutate: ({ fromLabel, toLabel }) => {
      const oldData = queryClient.getQueriesData<
        InfiniteData<TPagedQuery<TLabel>>
      >({ queryKey: [GET_WORKSPACE_LABELS_KEY] });

      queryClient.setQueriesData<InfiniteData<TPagedQuery<TLabel>>>(
        { queryKey: [GET_WORKSPACE_LABELS_KEY] },
        (data) => {
          if (!data) return data;
          const cloned = cloneDeep(data);
          cloned.pages.forEach(({ results }) => {
            results.forEach((label) => {
              if (label.id === fromLabel.id) label.order = toLabel.order;
              else if (
                label.order > fromLabel.order &&
                label.order <= toLabel.order
              )
                label.order--;
              else if (
                label.order < fromLabel.order &&
                label.order >= toLabel.order
              )
                label.order++;
            });
            results.sort((a, b) => a.order - b.order);
          });
          return cloned;
        },
      );
      return oldData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_WORKSPACE_LABELS_KEY, workspace?.id],
        predicate: (query) => !!query.queryKey[2],
      });
      onSuccess?.();
    },
    onError: (error, _var, oldData) => {
      oldData?.forEach(([_query, data]) => {
        queryClient.setQueryData(_query, data);
      });
      onError?.(error as unknown as TResponseError);
    },
  });
}
