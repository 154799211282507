import { IconCircle, IconCircleCheckFilled } from '@tabler/icons-react';
import classNames from 'classnames';

import styles from './styles.module.css';

type Props = {
  onClick: () => void;
  checked: boolean;
  modeKey?: string;
  Icon: React.ReactNode;
};
export default function ContentSwitcher({
  Icon,
  modeKey,
  checked,
  onClick,
}: Props) {
  return (
    <div
      onClick={onClick}
      role="checkbox"
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick();
      }}
      tabIndex={0}
      aria-checked={checked}
      className={classNames(styles.container, {
        [styles.selected]: checked,
      })}
    >
      <div className={styles.main}>
        {Icon}
        <span>{modeKey}</span>
        {checked ? (
          <IconCircleCheckFilled width={24} />
        ) : (
          <IconCircle width={24} />
        )}
      </div>
    </div>
  );
}
