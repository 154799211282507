import { useContext } from 'react';

import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { find } from 'lodash';

import { PROJECT_TYPE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import EventEmitterProvider from '@contexts/EventEmitterContext';
import { UserContext } from '@contexts/UserContext';

import AccordionRowWrapper from '@components/Timelines/TimelineProjects/AccordionRowWrapper';

import styles from './styles.module.css';

type Props = {
  sortableId: UniqueIdentifier;
  project?: TProjectListWithResources<TResourceItemList>;
  activeSortingId: UniqueIdentifier | null;
};
export default function SortableRow({
  project,
  activeSortingId,
  sortableId,
}: Props) {
  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id: sortableId,
    disabled:
      workspace?.permission !== WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ||
      project?.type === PROJECT_TYPE.TIME_OFF,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: activeSortingId === project?.id ? 0.2 : 1,
  };

  return project ? (
    <div ref={setNodeRef} style={{ ...style, position: 'relative' }}>
      <EventEmitterProvider>
        <AccordionRowWrapper
          sortHandlerProps={{
            listeners,
            activator: setActivatorNodeRef,
            attributes,
          }}
          project={project}
        />
      </EventEmitterProvider>
    </div>
  ) : (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={styles.placeholderItem}
    />
  );
}
