import { ComponentType } from 'react';

import { type IconProps } from '@tabler/icons-react';
import { t } from 'i18next';

import { ROUTES } from '@/types/routes';

import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';

import styles from './styles.module.css';

type ErrorBlockProps = {
  Icon: ComponentType<IconProps>;
  title: string;
  message: string;
};
const errorColor = getCssVariable('--icon-critical');

export default function ErrorBlock({ Icon, title, message }: ErrorBlockProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.errorContainer}>
        <Icon size={40} color={errorColor} />
        <div className={styles.messageContainer}>
          <h4>{title}</h4>
          <p>{t(message)}</p>
        </div>
      </div>
      <div className={styles.errorButtonContainer}>
        <Button
          label={t('common:backToLogin')}
          variant="outline"
          size="large"
          link={{ to: ROUTES.SIGN_IN, preventScrollReset: false }}
        />
      </div>
    </div>
  );
}
