import { useCallback, useContext, useState } from 'react';
import { RightSlot } from 'react-contexify';

import { IconEyeOff, IconPencil, IconTrash } from '@tabler/icons-react';
import { t } from 'i18next';

import { RESOURCE_STATUS } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TLabel } from '@/types/labels';
import { TUserMember, TWorkspaceAccess } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import useHideMember from '@hooks/workspace/resources/useHideMember';
import useUnhideMember from '@hooks/workspace/resources/useUnhideMember';

import ModalDeleteTeamMember from '@components/Modals/ModalDeleteTeamMember';
import ModalEditTeamMember from '@components/Modals/ModalEditTeamMember';
import ModalHideRevokeAccess from '@components/Modals/ModalHideRevokeAccess';
import ShortcutMenu from '@components/ShortcutMenu';
import RightSlotDelete from '@components/Timelines/common/utils/RightSlotDelete';

type Props = {
  id: string;
  firstName: string;
  lastName: string;
  image?: TAttachmentDataUrls;
  capacity: number;
  labels: TLabel[];
  workspaceAccess?: TWorkspaceAccess;
  canDelete?: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
  status?: RESOURCE_STATUS;
  onDeleteSuccess?: () => void;
  onEditSuccess?: (obj?: object | TUserMember, updated?: boolean) => void;
  menuId: string;
  isYourself?: boolean;
};

export default function ResourceHandlerMenu({
  id,
  capacity,
  labels,
  firstName,
  lastName,
  image,
  workspaceAccess,
  onVisibilityChange,
  canDelete = true,
  isYourself = false,
  status = RESOURCE_STATUS.ACTIVE,
  onDeleteSuccess,
  onEditSuccess,
  menuId,
}: Props) {
  const { workspaceId } = useContext(UserContext);

  const [isModalDeleteTeamMemberOpen, setIsModalDeleteTeamMemberOpen] =
    useState(false);
  const [isModalEditTeamMemberOpen, setIsModalEditTeamMemberOpen] =
    useState(false);

  const onOpenModalDeleteTeamMember = useCallback(
    () => setIsModalDeleteTeamMemberOpen(true),
    [],
  );
  const onCloseModalDeleteTeamMember = useCallback(() => {
    setIsModalDeleteTeamMemberOpen(false);
  }, []);

  const onOpenModalEditTeamMember = useCallback(
    () => setIsModalEditTeamMemberOpen(true),
    [],
  );
  const onCloseModalEditTeamMember = useCallback(
    (obj?: object | TUserMember, updated?: boolean) => {
      if (obj && updated) onEditSuccess?.(obj, updated);
      setIsModalEditTeamMemberOpen(false);
    },
    [onEditSuccess],
  );

  const { addNotification } = useContext(NotificationsContext);

  const { mutate: hide } = useHideMember({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: t('common:hideMember.hideSuccess.title', {
          name: `${firstName} ${lastName}`,
        }),
        description: t('common:hideMember.hideSuccess.description'),
      });
    },
  });

  const hideMemberFn = useCallback(
    (revokeAccess: boolean = false) => {
      if (workspaceId) hide({ workspaceId, resourceId: id, revokeAccess });
    },
    [id, hide, workspaceId],
  );

  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] =
    useState<boolean>(false);

  const onHideMember = useCallback(() => {
    if (isYourself || !workspaceAccess?.hasAccess) {
      if (workspaceId) hideMemberFn();
    } else if (workspaceAccess?.hasAccess) {
      setIsRevokeAccessModalOpen(true);
      return;
    }
  }, [hideMemberFn, isYourself, workspaceAccess?.hasAccess, workspaceId]);

  const { mutate: unhide } = useUnhideMember();

  const onUnhideMember = useCallback(() => {
    if (workspaceId && id && status === RESOURCE_STATUS.HIDDEN) {
      unhide({ workspaceId, resourceId: id });
    }
  }, [workspaceId, id, status, unhide]);

  return (
    <>
      <ShortcutMenu
        onVisibilityChange={onVisibilityChange}
        animation={false}
        id={menuId}
      >
        <ShortcutMenu.Item keyMatch="e" keyHandler={onOpenModalEditTeamMember}>
          <IconPencil size={20} style={{ marginRight: 8 }} />
          {t('common:editContextMenuActions.edit')} <RightSlot>E</RightSlot>
        </ShortcutMenu.Item>
        {status === RESOURCE_STATUS.ACTIVE && (
          <ShortcutMenu.Item keyMatch="h" keyHandler={onHideMember}>
            <IconEyeOff size={20} style={{ marginRight: 8 }} />
            {t('common:editContextMenuActions.hide')} <RightSlot>H</RightSlot>
          </ShortcutMenu.Item>
        )}
        {status === RESOURCE_STATUS.HIDDEN && (
          <ShortcutMenu.Item keyMatch="H" keyHandler={onUnhideMember}>
            <IconEyeOff size={20} style={{ marginRight: 8 }} />
            {t('common:unhide')} <RightSlot>H</RightSlot>
          </ShortcutMenu.Item>
        )}
        <ShortcutMenu.Item
          disabled={!canDelete}
          keyMatch={'shift+backspace'}
          keyHandler={onOpenModalDeleteTeamMember}
        >
          <IconTrash size={20} style={{ marginRight: 8 }} />
          {t('common:editContextMenuActions.delete')} <RightSlotDelete />
        </ShortcutMenu.Item>
      </ShortcutMenu>
      {isModalDeleteTeamMemberOpen && (
        <ModalDeleteTeamMember
          isOpen={isModalDeleteTeamMemberOpen}
          resourceId={id}
          key={`delete-resource-${id}`}
          name={`${firstName} ${lastName}`}
          onClose={onCloseModalDeleteTeamMember}
          onDeleteSuccess={onDeleteSuccess}
        />
      )}
      {isModalEditTeamMemberOpen && (
        <ModalEditTeamMember
          isOpen={isModalEditTeamMemberOpen}
          onClose={onCloseModalEditTeamMember}
          resourceId={id}
          image={image}
          onDelete={() => {
            onCloseModalEditTeamMember();
            onOpenModalDeleteTeamMember();
          }}
          member={{
            firstName,
            lastName,
            capacity,
            labels,
            workspaceAccess,
          }}
        />
      )}
      {isRevokeAccessModalOpen && workspaceAccess?.email && (
        <ModalHideRevokeAccess
          isOpen={true}
          name={`${firstName} ${lastName}`}
          email={workspaceAccess.email}
          onRevokeAccess={() => {
            setIsRevokeAccessModalOpen(false);
            hideMemberFn(true);
          }}
          onKeepAccess={() => {
            setIsRevokeAccessModalOpen(false);
            hideMemberFn(false);
          }}
          onClose={() => setIsRevokeAccessModalOpen(false)}
        />
      )}
    </>
  );
}
