import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { contextMenu, Menu, type TriggerEvent } from 'react-contexify';

import classNames from 'classnames';

import Portal from '@components/Portal';

import styles from './styles.module.css';
import { DropdownMenuRefType } from './types';

type Props = {
  id: string;
  children: React.ReactNode;
  onVisibilityChange?: (isVisible: boolean) => void;
  closeOnClickOutside?: boolean;
  className?: string;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
};
const BaseDropdownMenu = forwardRef<DropdownMenuRefType, Props>(
  (
    {
      id,
      children,
      onVisibilityChange,
      closeOnClickOutside = true,
      className,
      onMenuClose,
      onMenuOpen,
    },
    menunref,
  ) => {
    const menuPositionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    const MENU_ID = `menu-${id}`;

    function getMenuPosition(e: HTMLElement | null) {
      const { bottom, left } = e?.getBoundingClientRect() ?? {
        bottom: 0,
        left: 0,
      };
      menuPositionRef.current = {
        x: left,
        y: bottom + 8,
      };
      return menuPositionRef.current;
    }

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const onClickFn = useCallback(
      (e: TriggerEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsVisible(true);
        onMenuOpen?.();
        contextMenu?.show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(e.target as HTMLElement),
        });
      },
      [MENU_ID, onMenuOpen],
    );

    const closeMenu = useCallback(() => {
      onMenuClose?.();
      contextMenu.hideAll();
    }, [onMenuClose]);

    useImperativeHandle(
      menunref,
      () => ({
        show: onClickFn,
        hide: closeMenu,
        isOpen: isVisible,
      }),
      [onClickFn, closeMenu, isVisible],
    );

    return (
      <Portal>
        {isVisible && (
          <div
            className={styles.overlay}
            onClick={(e) => {
              if (closeOnClickOutside) closeMenu();
              else {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          />
        )}
        <Menu
          data-property={menuPositionRef.current.x}
          animation={false}
          className={classNames(styles.menu, 'top-triangle', className)}
          id={MENU_ID}
          onVisibilityChange={(isVisible) => {
            setIsVisible(isVisible);
            onVisibilityChange?.(isVisible);
          }}
        >
          {children}
        </Menu>
      </Portal>
    );
  },
);

BaseDropdownMenu.displayName = 'BaseDropdownMenu';

export default BaseDropdownMenu;
