import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { Item, Separator } from 'react-contexify';

import { some } from 'lodash';
import { useDebounce } from 'use-hooks';

import { TLabel } from '@/types/labels';

import { useSortAndFilter } from '@contexts/SortAndFilterContext';
import useGetWorkspaceLabels from '@hooks/workspace/labels/useGetWorkspaceLabels';

import ColorDot from '@components/ColorDot';
import { FilterItemsProps } from '@components/MenuUtility/FiltersMenu/types';
import ItemScrollableContent from '@components/MenuUtility/UtilityItems/component/ItemScrollableContent';
import ItemSearch from '@components/MenuUtility/UtilityItems/component/ItemSearch/ItemSearch';
import ItemWithCheckbox from '@components/MenuUtility/UtilityItems/component/ItemWithCheckbox';

export default function LabelFilterMenuItems({
  onNavigateBack,
}: FilterItemsProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const debounced = useDebounce(searchValue, 300);

  const { data, hasNextPage, isLoading, isFetching, fetchNextPage } =
    useGetWorkspaceLabels({
      q: debounced,
      pageSize: 10,
    });
  const { filters, updateFilters } = useSortAndFilter();

  const onItemClick = useCallback(
    (selected: boolean, item: TLabel) =>
      updateFilters({
        key: 'labels',
        action: selected ? 'ADD' : 'REMOVE',
        value: item,
      }),
    [updateFilters],
  );

  const labels = useMemo(
    () => data?.pages.flatMap((page) => page.results ?? []) ?? [],
    [data?.pages],
  );
  const fetchOnScroll = useCallback(
    (entry: IntersectionObserverEntry[]) => {
      if (fetchNextPage && entry?.[0]?.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  // const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
  //   (e) => {
  //     if (e.key === 'Enter') {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       if (!labels?.length) return;
  //       const val = searchValue.trim().toLowerCase();
  //       const lbl =
  //         labels.length === 1
  //           ? labels[0]
  //           : labels.find((label) => label.value.trim().toLowerCase() === val);
  //       if (lbl)
  //         onItemClick(
  //           !some(filters?.labels ?? [], (itemId) => itemId.id === lbl.id),
  //           lbl,
  //         );
  //     }
  //   },
  //   [filters?.labels, labels, onItemClick, searchValue],
  // );
  const inputRef = useRef<HTMLInputElement>(null);

  // const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  // const onFocus = useCallback(() => setIsInputFocused(true), []);
  // const onBlur = useCallback(() => setIsInputFocused(false), []);

  // useEffect(() => {
  //   if (!isInputFocused && inputRef.current) {
  //     const handleKeyDown = (e: KeyboardEvent) => {
  //       console.log(e.key);
  //       if (/^[A-Za-z0-9]$/.test(e.key)) {
  //         console.log('key pressed');
  //         // setSearchValue((prev) => prev + e.key);
  //         inputRef.current?.focus();
  //       }
  //     };
  //     const opt = { capture: true };
  //     document.addEventListener('keydown', handleKeyDown, opt);
  //     return () => {
  //       document.removeEventListener('keydown', handleKeyDown, opt);
  //     };
  //   }
  // }, [isInputFocused]);

  return (
    <>
      <ItemSearch
        onValueChanged={setSearchValue}
        value={searchValue}
        onNavigateBack={onNavigateBack}
        // onBlur={onBlur}
        // onFocus={onFocus}
        placeholder="Labels"
        ref={inputRef as RefObject<HTMLInputElement>}
      />
      <Separator />
      <ItemScrollableContent
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchOnScroll={fetchOnScroll}
      >
        {labels?.length ? (
          labels?.map((label) => {
            return (
              <ItemWithCheckbox
                key={label.id}
                id={label.id}
                isItemSelected={some(
                  filters?.labels ?? [],
                  (itemId) => itemId.id === label.id,
                )}
                onItemClick={(selected) => onItemClick(selected, label)}
              >
                <ColorDot color={label.color} size={16} />
                {label.value}
              </ItemWithCheckbox>
            );
          })
        ) : (
          <Item className="unstyled-item" closeOnClick={false} disabled>
            No labels found
          </Item>
        )}
      </ItemScrollableContent>
    </>
  );
}
