import {
  Fragment,
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { contextMenu } from 'react-contexify';

import { IconDots } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';

import {
  RESOURCE_STATUS,
  USER_ROLE,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';
import { TWorkspaceAccess } from '@/types/workspace';

import { UserContext } from '@contexts/UserContext';
import { titleCase } from '@services/helpers';

import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Chip from '@components/Chip';
import ResourceHandlerMenu from '@components/MenuUtility/ResourceHanlderMenu';
import ModalInviteToWorkspace from '@components/Modals/ModalInviteToWorkspace';
import Portal from '@components/Portal';
import SelectDropdown from '@components/SelectDropdown';

import styles from './styles.module.css';

type Props = {
  workspaceId: string;
  resources: TResourceSearchResult[];
  onUpdatePermission?: (data: {
    resource: TResourceSearchResult;
    permission: WORKSPACE_MEMBER_PERMISSION;
    workspaceId: string;
  }) => void;
  onUpdateRole: (data: {
    workspaceId: string;
    role: USER_ROLE;
    accountId: string;
  }) => void;
};

type ResourceMenuButtonProps = {
  resource: TResourceSearchResult;
};
function ResourceMenuButton({ resource }: ResourceMenuButtonProps) {
  const { firstName, lastName, id, image, capacity, status, labels } = resource;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { account, workspace } = useContext(UserContext);
  const MENU_ID = `member-context-menu-${firstName}-${lastName}`;
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>(undefined);

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (160 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const isYou = useMemo<boolean>(() => {
    return !!(
      resource?.email &&
      account?.email &&
      resource.email === account.email
    );
  }, [account?.email, resource.email]);

  const canDelete = useMemo<boolean>(() => {
    return (
      !isYou &&
      (workspace?.role === USER_ROLE.OWNER || resource.role !== USER_ROLE.OWNER)
    );
  }, [isYou, resource.role, workspace?.role]);

  const access = useMemo<TWorkspaceAccess | undefined>(() => {
    if (resource.accountId) {
      return {
        email: resource.email,
        accountId: resource.accountId,
        hasAccess: true,
        pending: false,
        permission: resource.permission,
      };
    } else if (resource.invitation) {
      return {
        email: resource.email,
        hasAccess: true,
        pending: true,
      };
    } else return undefined;
  }, [resource]);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const onOutsideClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    contextMenu.hideAll();
    setMenuIsOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="ghost"
        icon={IconDots}
        ref={triggerRef}
        iconProps={{ size: 24 }}
        onClick={onClickFn}
      />
      {menuIsOpen && (
        <Portal>
          <div className={styles.overlay} onClick={onOutsideClick}></div>
        </Portal>
      )}
      <ResourceHandlerMenu
        id={id}
        menuId={MENU_ID}
        firstName={firstName}
        lastName={lastName}
        capacity={capacity ?? 0}
        canDelete={canDelete}
        isYourself={isYou}
        image={image}
        status={status}
        labels={labels}
        onVisibilityChange={(isVisible) => setMenuIsOpen(isVisible)}
        workspaceAccess={access}
      />
    </>
  );
}

export default function MemberManageSection({
  workspaceId,
  resources,
  onUpdatePermission,
  onUpdateRole,
}: Props) {
  const { account, workspace } = useContext(UserContext);

  const hasPermissionUpdate = useMemo(
    () => !!onUpdatePermission,
    [onUpdatePermission],
  );
  const [resourceSelected, setResourceSelected] =
    useState<TResourceSearchResult | null>(null);
  const PERMISSION_DATA: Record<WORKSPACE_MEMBER_PERMISSION, string> = useMemo(
    () => ({
      [WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE]: titleCase(
        t('common:modals.roles.editor.label'),
      ) as string,
      [WORKSPACE_MEMBER_PERMISSION.READ_ONLY]: titleCase(
        t('common:modals.roles.viewer.label'),
      ) as string,
    }),
    [],
  );

  return (
    <div
      className={classNames(styles.peopleContainer, {
        [styles.withPermission]: hasPermissionUpdate,
      })}
    >
      <div className={styles.tableHeaders}>
        <span>User</span>
        <span>Role</span>
        {hasPermissionUpdate && (
          <span className={styles.hideSmall}>Permission</span>
        )}
        <span></span>
      </div>

      {resources?.length ? (
        resources.map((resource) => {
          const isHidden = resource.status === RESOURCE_STATUS.HIDDEN;
          return (
            <div key={resource.id} className={styles['grid-row']}>
              <div className={classNames(styles.userInfo, styles['grid-cell'])}>
                <Avatar
                  name={`${resource.firstName} ${resource.lastName}`}
                  image={resource.image?.ref}
                  size="large"
                />
                <div className={styles.userInfoText}>
                  <span
                    className={classNames(styles.userInfoTextName, {
                      [styles.hidden]: isHidden,
                    })}
                  >
                    <span>{`${resource.firstName} ${resource.lastName}`}</span>
                    {account && account.email === resource.email ? (
                      <Chip size="small" type="neutral">
                        you
                      </Chip>
                    ) : resource.invitation ? (
                      <Chip size="small" type="informative">
                        {t('common:invited')}
                      </Chip>
                    ) : undefined}
                    {isHidden && (
                      <Chip size="small" type="neutral">
                        hidden
                      </Chip>
                    )}
                  </span>
                  {resource.email && (
                    <span
                      className={styles.userInfoTextEmail}
                    >{`${resource.email}`}</span>
                  )}
                </div>
              </div>

              {resource.role ? (
                <>
                  <span className={styles['grid-cell']}>
                    <SelectDropdown
                      variant="simple"
                      align="end"
                      label={titleCase(resource?.role ?? 'MEMBER') as string}
                      disabled={
                        !!resource.invitation ||
                        account?.email === resource.email ||
                        workspace?.role !== USER_ROLE.OWNER
                      }
                    >
                      {Object.values(USER_ROLE).map((ur) => (
                        <SelectDropdown.Item
                          checked={resource.role === ur}
                          key={ur}
                          label={titleCase(ur) as string}
                          disabled={!resource.accountId || resource.role === ur}
                          onSelect={() => {
                            if (workspaceId && resource.accountId)
                              onUpdateRole({
                                workspaceId,
                                role: ur,
                                accountId: resource.accountId,
                              });
                          }}
                        />
                      ))}
                    </SelectDropdown>
                  </span>
                  {hasPermissionUpdate && (
                    <span
                      className={classNames(
                        styles['grid-cell'],
                        styles.hideSmall,
                      )}
                    >
                      <SelectDropdown
                        variant="simple"
                        align="end"
                        disabled={
                          (resource?.email &&
                            account?.email &&
                            resource.email === account.email) ||
                          resource.role === USER_ROLE.OWNER
                        }
                        label={
                          resource.permission
                            ? PERMISSION_DATA[resource.permission]
                            : ''
                        }
                      >
                        {Object.values(WORKSPACE_MEMBER_PERMISSION).map((p) => (
                          <SelectDropdown.Item
                            checked={resource.permission === p}
                            key={p}
                            label={PERMISSION_DATA[p]}
                            disabled={resource.permission === p}
                            onSelect={() =>
                              onUpdatePermission?.({
                                resource,
                                permission: p,
                                workspaceId,
                              })
                            }
                          />
                        ))}
                      </SelectDropdown>
                    </span>
                  )}
                </>
              ) : (
                <Fragment>
                  <span
                    className={classNames(styles['grid-cell'], styles.spanTwo)}
                  >
                    <Button
                      size="medium"
                      variant="ghost"
                      label={t('common:modals.inviteToWorkspace')}
                      onClick={() => setResourceSelected(resource)}
                    />
                  </span>
                </Fragment>
              )}
              <div className={styles['grid-cell']}>
                <ResourceMenuButton resource={resource} />
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.noData}>
          <div className={styles.noData_text_container}>
            <span>No resources found</span>
          </div>
        </div>
      )}
      {resources?.length > 0 && resourceSelected?.id && (
        <ModalInviteToWorkspace
          isOpen={!!resourceSelected?.id}
          onClose={() => setResourceSelected(null)}
          resource={resourceSelected}
        />
      )}
    </div>
  );
}
