import { useContext } from 'react';

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { PROJECT_STATUS } from '@/types/enums';
import { TUserAccount } from '@/types/workspace';

import { UserContext } from '@contexts/UserContext';
import { ACCOUNT_QUERY_KEY } from '@hooks/account/useAccountQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@hooks/utils/useAnalytics';
import { http } from '@services/api';

import { COMPLETED_PROJECT_QUERY } from './projects/useCompletedProject';
import { PROJECT_COUNT_BY_STATUS_QUERY_KEY } from './projects/useProjectCountByStatus';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from './projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from './resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from './resources/useTimelineResourceQuery';

const setWorkspaceAutoSync = (
  queryClient: QueryClient,
  autoSync: boolean,
  workspaceId: string,
) => {
  const meData = queryClient.getQueryData<TUserAccount>([ACCOUNT_QUERY_KEY]);
  const clonedData = cloneDeep(meData);
  const workspace = clonedData?.workspaces?.find((w) => w.id === workspaceId);
  if (!workspace) return;
  workspace.autoSync = autoSync;
  queryClient.setQueryData([ACCOUNT_QUERY_KEY], clonedData);
  return workspace;
};

export function useUpdateAutoSync() {
  const { workspaceId } = useContext(UserContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<void, Error, boolean>({
    mutationFn: async (autoSync: boolean) => {
      await http.put(`/workspaces/${workspaceId}/auto-sync`, { autoSync });
    },
    onMutate: (autoSync) => {
      const key = [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId];
      setWorkspaceAutoSync(queryClient, autoSync, workspaceId!);
      if (autoSync) {
        const data = queryClient.getQueryData(key);
        queryClient.setQueryData(key, { pageParams: [], pages: [] });
        return data;
      }
    },
    onSuccess: (_data, autoSync) => {
      trackEvent(
        autoSync
          ? ANALYTICS_EVENTS.AUTO_UPDATE_STATUS_TURNED_ON
          : ANALYTICS_EVENTS.AUTO_UPDATE_STATUS_TURNED_OFF,
        workspaceId!,
      );
      if (autoSync) {
        queryClient.invalidateQueries({
          queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
        });
        queryClient.invalidateQueries({
          queryKey: [COMPLETED_PROJECT_QUERY, workspaceId],
        });
        queryClient.invalidateQueries({
          queryKey: [RESOURCES_QUERY_KEY, workspaceId],
        });
        queryClient.invalidateQueries({
          queryKey: [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
        });
        queryClient.invalidateQueries({
          queryKey: [
            PROJECT_COUNT_BY_STATUS_QUERY_KEY,
            workspaceId,
            PROJECT_STATUS.COMPLETED,
          ],
        });
      }
    },
    onError: (_error, autoSync, context) => {
      setWorkspaceAutoSync(queryClient, !autoSync, workspaceId!);
      if (context) {
        queryClient.setQueryData(
          [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
          context,
        );
      }
    },
  });
}
