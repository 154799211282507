import React from 'react';

import { useProfileModal } from '@contexts/ModalPreferenceContext';
import { useWorkloadPref } from '@hooks/workspace/useWorkloadPref';

import Input from '@components/Input';
import ProjectEmoji from '@components/ProjectEmoji';

import styles from './styles.module.css';

export default function WorkloadPreferences() {
  const { isPreferenceOpened } = useProfileModal();
  const { data: workloads, isLoading } = useWorkloadPref({
    enabled: isPreferenceOpened,
  });

  return (
    <div className={styles.workloadsContainer}>
      {!isLoading &&
        workloads?.map((workload, index) => {
          return (
            <div
              className={styles.workloadRow}
              key={`${workload.name}_${index}`}
            >
              <div className={styles.workloadRowContent}>
                <ProjectEmoji
                  emoji={workload.emoji ?? ''}
                  color={workload.color}
                  size={32}
                />
                <p>{workload.name}</p>
              </div>
              <div className={styles.workloadRowActions}>
                <span>From</span>
                <Input
                  name={workload.name}
                  TrailingIcon="%"
                  value={String(workload.from)}
                  disabled
                  onChange={function (
                    _e: React.ChangeEvent<HTMLInputElement>,
                  ): void {}}
                />
                <span>to {workload.to}%</span>
              </div>
            </div>
          );
        })}
    </div>
  );
}
