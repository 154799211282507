import { PropsWithChildren } from 'react';
import { ClipLoader } from 'react-spinners';

import * as Collapsible from '@radix-ui/react-collapsible';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classNames from 'classnames';

import { useCollapsibleDrawer } from '@contexts/CollapsibleDrawer';
import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';

import styles from './styles.module.css';

type Props = PropsWithChildren<{
  draweId: string;
  title?: string | JSX.Element;
  variant?: 'full' | 'half';
  emptyLabel?: string;
  emoji?: string;
  rightTitle?: string | JSX.Element;
  isLoading?: boolean;
  loader?: JSX.Element;
  className?: string;
}>;

export default function DrawerCard({
  draweId,
  title,
  emoji,
  children,
  variant = 'full',
  emptyLabel = '',
  rightTitle,
  isLoading = false,
  loader,
  className,
}: Props) {
  const { isCollapsed, onCollapseChange } = useCollapsibleDrawer(draweId);

  return (
    <Collapsible.Root
      open={!isCollapsed}
      onOpenChange={(openChange) => onCollapseChange(!openChange)}
      className={classNames(styles.container, {
        [styles.full]: variant === 'full',
      })}
    >
      {title && (
        <div
          className={classNames(styles.titleWrapper, {
            [styles.collapsed]: isCollapsed,
          })}
        >
          <p className={styles.title}>
            {emoji && <span className={styles.emoji}>{emoji}</span>}
            {title}
          </p>
          <div className={styles.rightTitleWrapper}>
            {rightTitle && <p className={styles.rightTitle}>{rightTitle}</p>}
            <Collapsible.Trigger asChild>
              <Button
                icon={!isCollapsed ? IconChevronUp : IconChevronDown}
                variant="ghost"
              />
            </Collapsible.Trigger>
          </div>
        </div>
      )}
      <Collapsible.Content
        className={classNames(styles.CollapsibleContent, className ?? '')}
      >
        {isLoading && (
          <div className={styles.loadingWrapper}>
            {loader ? (
              loader
            ) : (
              <ClipLoader
                color={getCssVariable('--color-blue-500')}
                cssOverride={{
                  borderWidth: '4px',
                }}
                size={32}
                speedMultiplier={0.8}
              />
            )}
          </div>
        )}
        {!isLoading && children ? (
          children
        ) : !isLoading ? (
          <p className={styles.empty}>{emptyLabel}</p>
        ) : undefined}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
