import ContentLoader from 'react-content-loader';
import type { IContentLoaderProps } from 'react-content-loader';

import { getCssVariable } from '@services/helpers';

export default function ChartSkeleton(props: IContentLoaderProps) {
  return (
    <ContentLoader
      speed={2}
      width={336}
      height={112}
      viewBox="0 0 336 112"
      backgroundColor={getCssVariable('--bg-subdued')}
      foregroundColor={getCssVariable('--bg-subdued-active')}
      {...props}
    >
      <path d="M 4.48 0 C 2.006 0 0 2.388 0 5.333 v 21.334 C 0 29.612 2.006 32 4.48 32 h 44.8 c 2.474 0 4.48 -2.388 4.48 -5.333 V 5.333 C 53.76 2.388 51.754 0 49.28 0 H 4.48 z M 332.267 48.663 L 336 50.798 V 112 H 0 V 75.282 l 3.733 2.63 c 3.734 2.628 11.2 7.886 18.667 12.063 c 7.467 4.177 14.933 7.274 22.4 2.911 c 7.467 -4.362 14.933 -16.184 22.4 -19.516 c 7.467 -3.332 14.933 1.825 22.4 -4.317 c 7.467 -6.142 14.933 -23.584 22.4 -31.639 c 7.467 -8.055 14.933 -6.724 22.4 1.358 c 7.467 8.083 14.933 22.915 22.4 30.805 c 7.467 7.89 14.933 8.835 22.4 3.173 c 7.467 -5.662 14.933 -17.933 22.4 -17.647 c 7.467 0.286 14.933 13.13 22.4 16.138 c 7.467 3.01 14.933 -3.816 22.4 -6.26 c 7.467 -2.443 14.933 -0.505 22.4 1.855 c 7.467 2.36 14.933 5.142 22.4 0.406 c 7.467 -4.738 14.933 -16.994 22.4 -20.988 c 7.467 -3.994 14.933 0.275 18.667 2.41 z" />
    </ContentLoader>
  );
}
