import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import RowLeftHeader from '@components/Timelines/TimelineResources/RowLeftHeader';

import { useProjectRowContext } from './Context';
import styles from './styles.module.css';

type Props = {
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function Header({ sortHandlerProps }: Props) {
  const { project, resource } = useProjectRowContext();

  if (!project || !resource) return null;
  return (
    <div className={styles.header}>
      <RowLeftHeader
        project={project}
        allocation={project?.totalAllocation}
        resourceName={resource.firstName + ' ' + resource.lastName}
        resourceId={resource.id}
        sortHandlerProps={sortHandlerProps}
      >
        {project.name}
      </RowLeftHeader>
    </div>
  );
}
