import {
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import { Item } from 'react-contexify';

import { IconCheck } from '@tabler/icons-react';
import { Checkbox as CB } from 'radix-ui';

import FlexContainer from '@components/utils/FlexContainer';

import styles from './styles.module.css';

type Props = {
  id: string;
  isItemSelected?: boolean;
  onItemClick?: (selected: boolean) => void;
};
export default function ItemWithCheckbox({
  id,
  isItemSelected,
  children,
  onItemClick,
}: PropsWithChildren<Props>) {
  const [hoveredLabel, setHoveredLabel] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  const onItemSelected: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onItemClick?.(!isItemSelected as boolean);
    },
    [isItemSelected, onItemClick],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(
    (e) => {
      switch (e.key) {
        case 'Enter':
          onItemClick?.(!isItemSelected);
          break;
        case ' ':
          e.preventDefault();
          e.stopPropagation();
          onItemClick?.(!isItemSelected);
          break;
        default:
          break;
      }
    },
    [isItemSelected, onItemClick],
  );

  return (
    <Item
      onMouseEnter={() => setHoveredLabel(true)}
      onMouseLeave={() => setHoveredLabel(false)}
      onClick={(_e) => onItemClick?.(!isItemSelected)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDownCapture={handleKeyDown}
    >
      <FlexContainer align="center" gap={8} style={{ width: '100%' }}>
        <span className={styles.checkboxWrapper}>
          {(hoveredLabel || focused || isItemSelected) && (
            <CB.Root
              name={id}
              id={id}
              checked={isItemSelected}
              className={styles.checkbox}
              onClick={onItemSelected}
            >
              <CB.Indicator className={styles.indicator}>
                <IconCheck size={20} />
              </CB.Indicator>
            </CB.Root>
          )}
        </span>
        {children}
      </FlexContainer>
    </Item>
  );
}
