import { createContext, MutableRefObject, useContext } from 'react';

import { noop } from 'lodash';

import { PROJECT_STATUS } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';

export type MouseEventRef = {
  mouseDownX?: number;
  mouseMoveX?: number;
  targetRect?: DOMRect;
  currentProps?: TTimeBlockRange;
  handle?: string;
};

const Context = createContext<{
  lengthWeeks: number;
  localProps: TTimeBlockRange;
  handleMouseUp: () => void;
  handleMove: (event: { clientX: number }) => void;
  resizeBlock: () => void;
  moveBlock: (event: { clientX: number }) => void;
  projectName: string;
  projectId: string;
  projectStatus?: PROJECT_STATUS;
  blockIdForActiveState: string;
  block: TTimeBlockRange;
  mouseEventRef: MutableRefObject<MouseEventRef>;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowTooltip: boolean;
  isDragging: boolean;
  projectEmoji?: string;
}>({
  mouseEventRef: { current: {} },
  lengthWeeks: 0,
  projectId: '',
  handleMouseUp: noop,
  handleMove: noop,
  blockIdForActiveState: '',
  projectStatus: undefined,
  resizeBlock: noop,
  moveBlock: noop,
  localProps: { id: '', allocation: 0, start: new Date(), end: new Date() },
  projectName: '',
  projectEmoji: undefined,
  shouldShowTooltip: false,
  setDragging: noop,
  isDragging: false,
  block: { id: '', allocation: 0, start: new Date(), end: new Date() },
});

export function useBlockContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('BlockContext must be used within a BlockContextProvider');
  }
  return context;
}

export default Context;
