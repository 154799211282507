import { MouseEventHandler } from 'react';

import { IconPointFilled } from '@tabler/icons-react';
import classNames from 'classnames';

import { PROJECT_COLOR } from '@/types/enums';

import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';

import styles from './styles.module.css';

type BaseProps = {
  size?: number;
  color: PROJECT_COLOR;
  asButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

type PropsSpan = BaseProps & {
  asButton?: false;
  onClick?: never;
};

type PropsButton = BaseProps & {
  asButton: true;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type Props = PropsSpan | PropsButton;

export default function ColorDot(props: Props) {
  return props.asButton ? (
    <Button
      variant="ghost"
      size="medium"
      style={{ padding: 0, ...(props.style ?? {}) }}
      className={classNames(styles.colorWrapperButton, props.className)}
      onClick={props.onClick}
      icon={IconPointFilled}
      iconProps={{
        color: getCssVariable(`--color-${props.color}-600`),
        size: props.size ?? 16,
      }}
    />
  ) : (
    <span
      className={classNames(styles.colorWrapper, props.className)}
      style={{
        width: `${props.size ?? 16}px`,
        height: `${props.size ?? 16}px`,
        ...(props.style ?? {}),
      }}
    >
      <IconPointFilled
        color={getCssVariable(
          `--color-${props.color === PROJECT_COLOR.GREY ? 'neutral' : props.color}-600`,
        )}
        size={props.size ?? 16}
      />
    </span>
  );
}
