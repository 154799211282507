import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { TUserAccount } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { ACCOUNT_QUERY_KEY } from '@hooks/account/useAccountQuery';
import { updateAccountImage } from '@services/api/account';

type Props = {
  filePath: string;
};

export default function useUpdateAccountImage() {
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<TUserAccount | undefined, Error, Props>({
    mutationFn: async (fileData: Props) => await updateAccountImage(fileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
