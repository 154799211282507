import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ACCOUNT_QUERY_KEY } from '@hooks/account/useAccountQuery';
import { deleteAccessToken } from '@services/api/workspace/workspace';

type Props = {
  workspaceId: string;
  accessTokenId: string;
};
export default function useRevokeAccessToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ workspaceId, accessTokenId }: Props) => {
      await deleteAccessToken(workspaceId, accessTokenId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
    },
  });
}
