import { useContext } from 'react';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { BaseResponse } from '@/types/base-responses';
import { TSuccessErrorHook } from '@/types/generic';
import { TResourceItemList, TTimeInterval } from '@/types/timeline';

import { handleApiCall } from '@services/api';

type TTimelineProjectQueryParam = TSuccessErrorHook<
  TResourceItemList[] | undefined
> & {
  timeInterval: TTimeInterval;
  getNextPageParam: (
    lastPage: TResourceItemList[],
  ) => TTimeInterval | undefined;
  getPreviousPageParam: (
    lastPage: TResourceItemList[],
  ) => TTimeInterval | undefined;
};

export const TIMELINE_PROJECT_QUERY_KEY = 'get-timeline-project';
export default function useGetPublicProjectTimeline({
  getNextPageParam,
  getPreviousPageParam,
  timeInterval,
  enabled = true,
  onSuccess,
}: TTimelineProjectQueryParam) {
  const { publicHttpClient, clientInitialized } = useContext(SharedAppContext);

  return useInfiniteQuery<
    TResourceItemList[],
    Error,
    InfiniteData<TResourceItemList[]>,
    [string],
    TTimeInterval
  >({
    queryKey: [TIMELINE_PROJECT_QUERY_KEY],
    initialPageParam: timeInterval,
    queryFn: ({ pageParam = timeInterval }) => {
      return handleApiCall(
        publicHttpClient
          .get<BaseResponse<TResourceItemList[]>>(`/timeline`, {
            params: {
              startDate: pageParam.start.getTime(),
              endDate: pageParam.end.getTime(),
            },
          })
          .then((response) => response.data?.data),
        onSuccess,
        undefined,
      );
    },
    enabled: clientInitialized && enabled,
    getNextPageParam,
    getPreviousPageParam,
  });
}
