import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { TSuccessErrorHook } from '@/types/generic';
import { TResources } from '@/types/timeline';

import { useSortAndFilter } from '@contexts/SortAndFilterContext';
import { UserContext } from '@contexts/UserContext';
import { handleApiCall } from '@services/api';
import { getResources } from '@services/api/workspace/resources';

export const RESOURCES_QUERY_KEY = 'get-resources';
export default function useResourcesQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResources[]>) {
  const { workspaceId } = useContext(UserContext);
  const { sortByName, filters } = useSortAndFilter();
  return useQuery({
    queryKey: [
      RESOURCES_QUERY_KEY,
      workspaceId,
      sortByName,
      filters?.labels.map(({ id }) => id),
    ] as [string, string, boolean, string[]],
    queryFn: ({ queryKey: [_, ws, sort, fltr] }) =>
      handleApiCall(
        getResources({
          workspaceId: ws,
          sortByName: sort ?? false,
          labels: fltr ?? undefined,
        }),
        onSuccess,
        onError,
      ),
    enabled,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
