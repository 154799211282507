import { omit } from 'lodash';

import { BaseListResponse, BaseResponse } from '@/types/base-responses';
import { PROJECT_COLOR, PROJECT_STATUS, SORTING_OPTIONS } from '@/types/enums';
import { Dto, TPagedQuery, TResponse, TResponseList } from '@/types/generic';
import {
  TAllocationEvent,
  TCompletedProject,
  TFiltersProject,
  THiddenProject,
  TProject,
  TProjectListWithResources,
  TProjectListWithResourcesStatus,
  TResourceItemList,
  TSharedProjectInfo,
  TTimelineHiddenProject,
} from '@/types/timeline';

import { PAGE_SIZE } from '@services/helpers/timelines/projects';
import {
  normalizeCompletedProjectList,
  normalizeFilterProject,
  normalizeHiddenProject,
  normalizeInfiniteQuery,
  normalizeProject,
  normalizeProjectListWithResources,
  normalizeProjectListWithResourcesStatus,
  normalizeSharedProjectInfo,
  normalizeTimelineHiddenProject,
} from '@services/normalizer';

import { http } from '..';

/**
 * Asynchronous function to fetch projects from an API.
 *
 * @param {object} params - An object containing necessary parameters for the request.
 * @param {string} params.query - The query string for searching projects.
 * @param {string} params.resourceId - The ID of the resource for which to fetch projects.
 * @param {string|null} [params.workspaceId=null] - The ID of the workspace from which to fetch projects.
 *
 * @returns {Promise<Dto<TProject[]>>} - Returns a promise that resolves to a Dto object containing an array of projects.
 *
 * @throws {Error} - If the request fails, the function throws an error.
 */

export async function getProjects({
  query,
  workspaceId,
  resourceId,
}: {
  query: string;
  resourceId: string;
  workspaceId?: string | null;
}) {
  const {
    data: { data },
  }: { data: TResponse<Dto<TProject[]>> } = await http.get(
    `/workspaces/${workspaceId}/projects?limit=8&q=${query}&resourceId=${resourceId}`,
  );
  return data.map(normalizeProject);
}

export async function getCompletedProjects({
  q,
  page,
  pageSize,
  workspaceId,
}: {
  q?: string;
  page?: number;
  pageSize?: number;
  workspaceId: string;
}) {
  const { data }: { data: TResponseList<Dto<TCompletedProject>> } =
    await http.get(`/workspaces/${workspaceId}/projects/completed`, {
      params: {
        search: q,
        page,
        pageSize,
      },
    });
  return normalizeCompletedProjectList(data);
}

export async function projectCountBySatatus({
  workspaceId,
  status,
}: {
  workspaceId: string;
  status: PROJECT_STATUS;
}) {
  const {
    data: {
      data: { count = 0 },
    },
  }: TResponse<{ data: Dto<{ count: number }> }> = await http.get(
    `/workspaces/${workspaceId}/projects/${status}/count`,
  );

  return { totalCount: Number(count) || 0 };
}

/**
 * Asynchronous function to fetch hidden projects from an API.
 *
 * @param {object} params - An object containing necessary parameters for the request.
 * @param {string} params.query - The query string for searching hidden projects.
 * @param {string} params.resourceId - The ID of the resource for which to fetch hidden projects.
 * @param {string|null} [params.workspaceId=null] - The ID of the workspace from which to fetch hidden projects.
 *
 * @returns {Promise<Dto<TProject[]>>} - Returns a promise that resolves to a Dto object containing an array of hidden projects.
 *
 * @throws {Error} - If the request fails, the function throws an error.
 */

export async function getHidden({
  query,
  workspaceId,
  resourceId,
}: {
  query: string;
  resourceId: string;
  workspaceId?: string | null;
}) {
  const {
    data: { data },
  }: { data: TResponse<Dto<THiddenProject[]>> } = await http.get(
    `/workspaces/${workspaceId}/projects/hidden?limit=8&q=${query}&resourceId=${resourceId}`,
  );
  return data.map(normalizeHiddenProject);
}

/**
 * This asynchronous function assigns a resource to a specific project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to be assigned to the project.
 * @param {string} params.projectId - The ID of the project to which the resource will be assigned.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function assignResourceToProject({
  workspaceId,
  resourceId,
  projectId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
}) {
  return http.post(`/workspaces/${workspaceId}/projects/${projectId}/assign`, {
    resourceId,
  });
}

/**
 * This asynchronous function creates a new project and assigns it to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project will be created. If null, the function will not proceed.
 * @param {string} params.name - The name of the new project.
 * @param {PROJECT_COLOR} params.color - The color of the new project.
 * @param {string} params.resourceId - The ID of the resource to which the project will be assigned.
 * @param {string} params.emoji - The emoji of the new project.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function addNewProject({
  workspaceId,
  name,
  color,
  resourceId,
  emoji,
  status,
}: {
  workspaceId?: string | null;
  color: PROJECT_COLOR;
  name: string;
  resourceId?: string;
  emoji?: string;
  status?: PROJECT_STATUS;
}) {
  return http.post(
    `/workspaces/${workspaceId}/projects${resourceId ? '/createAndAssign' : ''}`,
    {
      name,
      color,
      resourceId,
      emoji,
      status,
    },
  );
}

/**
 * This asynchronous function updates the details of a specific project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project is located. If null, the function will not proceed.
 * @param {string} params.name - The new name of the project.
 * @param {string} params.projectId - The ID of the project to be updated.
 * @param {string} params.emoji - The new emoji of the project.
 * @param {PROJECT_COLOR} params.color - The new color of the project.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateProject({
  workspaceId,
  name,
  projectId,
  emoji,
  color,
  status,
}: {
  workspaceId?: string | null;
  name: string;
  projectId: string;
  emoji?: string;
  color: PROJECT_COLOR;
  status: PROJECT_STATUS;
}) {
  return http.put(`/workspaces/${workspaceId}/projects/${projectId}`, {
    name,
    color,
    emoji,
    status,
  });
}

export async function updateProjectStatus({
  workspaceId,
  projectId,
  status,
}: {
  workspaceId?: string | null;
  projectId: string;
  status: PROJECT_STATUS;
}) {
  return http.put(`/workspaces/${workspaceId}/projects/${projectId}/status`, {
    status,
  });
}

/**
 * This asynchronous function deletes a specific project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project is located. If null, the function will not proceed.
 * @param {string} params.projectId - The ID of the project to be deleted.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function deleteProject({
  workspaceId,
  projectId,
}: {
  workspaceId?: string | null;
  projectId: string;
}) {
  return http.delete(`/workspaces/${workspaceId}/projects/${projectId}`);
}

/**
 * This asynchronous function hides a project assigned to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource is located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource to which the project is assigned.
 * @param {string} params.projectId - The ID of the project to be hidden.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function hideProject({
  workspaceId,
  resourceId,
  projectId,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
}) {
  return http.put(
    `/workspaces/${workspaceId}/resources/${resourceId}/hide/${projectId}`,
    {},
  );
}

/**
 * This asynchronous function updates the order of projects assigned to a specific resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string[]} params.newOrder - The new order of the projects.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateProjectsOrder({
  workspaceId,
  projectId,
  newOrder,
  newStatus,
}: {
  workspaceId?: string | null;
  projectId: string;
  newOrder: number;
  newStatus?: PROJECT_STATUS;
}) {
  return http.put(
    `/workspaces/${workspaceId}/projects/${projectId}/changeOrder`,
    { newOrder, newStatus },
  );
}

/**
 * This asynchronous function retrieves the hidden projects in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the hidden projects are to be retrieved. If null, the function will not proceed.
 *
 * @returns {Promise<Array<{id: string, count: number, hidden: TTimelineHiddenProject[]}>>} The list of hidden projects if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getHiddenProject({
  workspaceId,
}: {
  workspaceId?: string | null;
}) {
  const {
    data: { data },
  }: {
    data: TResponse<
      Dto<
        {
          hidden: TTimelineHiddenProject[];
          id: string;
          totalCount: number;
        }[]
      >
    >;
  } = await http.get(`/workspaces/${workspaceId}/hidden-projects`);
  return data?.map((d) => ({
    id: d.id,
    count: d?.totalCount,
    hidden: d.hidden.map(normalizeTimelineHiddenProject),
  }));
}

/**
 * This asynchronous function retrieves the list of resources associated with a specific project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project is located. If null, the function will not proceed.
 * @param {string | null} params.projectId - The ID of the project whose resources are to be retrieved. If null, the function will not proceed.
 *
 * @returns {Promise<TProjectListWithResources>} The list of resources associated with the project if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getProjectListWithResources({
  pageParam,
  workspaceId,
  sortByName,
  labels,
}: {
  workspaceId?: string | null;
  pageParam: number;
  sortByName?: boolean;
  labels?: string[];
}) {
  const {
    data,
  }: {
    data: TResponse<
      Dto<
        Omit<TPagedQuery<unknown>, 'results'> &
          TProjectListWithResources<Omit<TResourceItemList, 'timeblocks'>>[]
      >
    >;
  } = await http.get(`/workspaces/${workspaceId}/projects/list-status/`, {
    params: {
      pageSize: PAGE_SIZE,
      page: pageParam,
      sortByName,
      labels: labels ?? undefined,
    },
  });

  return {
    ...normalizeInfiniteQuery(
      omit(data, 'data') as unknown as Omit<TPagedQuery<unknown>, 'results'>,
    ),
    results: data?.data?.map(normalizeProjectListWithResources) ?? [],
  };
}

/**
 * This asynchronous function retrieves the timeline resources associated with a specific project in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the project is located. If null, the function will not proceed.
 * @returns {Promise<TProjectListWithResources<TResourceItemList>[]>} The timeline resources associated with the project if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getTimelineProjectListWithResources({
  workspaceId,
  page,
  sortByName,
}: {
  workspaceId?: string | null;
  page: number;
  sortByName?: boolean;
}) {
  const {
    data,
  }: {
    data: TResponse<Dto<TProjectListWithResources<TResourceItemList>[]>>;
  } = await http.get(`/workspaces/${workspaceId}/projects/timeline-status`, {
    params: {
      page,
      pageSize: PAGE_SIZE,
      sortByName,
    },
  });

  return data?.data?.map(normalizeProjectListWithResources);
}

/**
 * This asynchronous function updates the allocation of a specific project to a resource in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the resource and project are located. If null, the function will not proceed.
 * @param {string} params.resourceId - The ID of the resource whose allocation is to be updated.
 * @param {string} params.projectId - The ID of the project to which the resource is allocated.
 * @param {TAllocationEvent[]} params.events - An array of TAllocationEvent objects representing the allocation events to be updated.
 *
 * @returns {Promise<TProjectListWithResources<TResourceItemList>[] | undefined>} - A promise that resolves to a TTimelineAllocationResponse object representing the updated allocation, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateAllocation({
  workspaceId,
  resourceId,
  projectId,
  events,
}: {
  workspaceId?: string | null;
  resourceId: string;
  projectId: string;
  events: TAllocationEvent[];
}) {
  const {
    data: { data },
  }: {
    data: TResponse<Dto<TProjectListWithResourcesStatus<TResourceItemList>[]>>;
  } = await http.put(
    `/workspaces/${workspaceId}/projects/${projectId}/allocations`,
    {
      resourceId,
      events,
    },
  );
  return data?.map(normalizeProjectListWithResourcesStatus);
}

/**
 * Changes the sort order of projects in a specific workspace.
 *
 * @param {object} params - An object containing the parameters.
 * @param {string|null} params.workspaceId - The ID of the workspace where the sort order needs to be changed. If not provided, the default workspace is used.
 * @param {Omit<SORTING_OPTIONS, 'MANUAL'>} params.order - The new sort order. It should be one of the values from SORTING_OPTIONS, except 'MANUAL'.
 *
 * @returns {Promise<void>} A Promise that resolves when the sort order has been successfully changed.
 *
 * @throws Will throw an error if the request fails, which should be caught and handled by the caller.
 */
export async function changeSortOrder({
  workspaceId,
  order,
}: {
  workspaceId?: string | null;
  order: Omit<SORTING_OPTIONS, 'MANUAL'>;
}) {
  return http.put(`/workspaces/${workspaceId}/projects/order/${order}`, {});
}

export async function shareProject({
  workspaceId,
  projectId,
}: {
  workspaceId: string;
  projectId: string;
}) {
  const {
    data: { data },
  } = await http.post<BaseResponse<TSharedProjectInfo>>(
    `/workspaces/${workspaceId}/projects/${projectId}/share-public`,
  );
  if (!data) return null;
  else return normalizeSharedProjectInfo(data) ?? null;
}

export async function revokeShareProject({
  workspaceId,
  projectId,
  shareId,
}: {
  workspaceId: string;
  projectId: string;
  shareId: string;
}) {
  await http.delete<BaseResponse<void>>(
    `/workspaces/${workspaceId}/projects/${projectId}/share-public/${shareId}`,
  );
  return null;
}

export async function searchProjectFilter({
  workspaceId,
  page,
  pageSize,
  q,
}: {
  workspaceId: string;
  page: number;
  pageSize: number;
  q?: string;
}): Promise<TPagedQuery<TFiltersProject>> {
  const { data } = await http.get<BaseListResponse<TFiltersProject>>(
    `/workspaces/${workspaceId}/projects/filters`,
    {
      params: {
        search: q ?? '',
        page,
        pageSize,
      },
    },
  );
  return {
    ...normalizeInfiniteQuery(data),
    results: data.data?.length ? data.data?.map(normalizeFilterProject) : [],
  };
}
