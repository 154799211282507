import { useCallback, useMemo, useState } from 'react';
import { ClipLoader } from 'react-spinners';

import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IconSearch, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { useDebounce } from 'use-hooks';

import { PROJECT_COLOR } from '@/types/enums';
import { TLabel } from '@/types/labels';

import { useIntersectionObserver } from '@hooks/utils/useIntersectionObserver';
import useCreateLabel from '@hooks/workspace/labels/useCreateLabel';
import useGetWorkspaceLabels from '@hooks/workspace/labels/useGetWorkspaceLabels';
import useOrderLabels from '@hooks/workspace/labels/useOrderLabels';
import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';
import Input from '@components/Input';
import Section from '@components/Section';

import LabelItem from './LabelItem';
import styles from './styles.module.css';

const baseI18n: string = 'common:profileModal.labels';
const LABEL_PACEHOLDER = 'New Label';
const leadingIconColor = getCssVariable('--icon-subdued');

export default function ModalLabels() {
  const [labelFilter, setLabelFilter] = useState<string>('');

  const onChangeFn = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelFilter(e.target.value);
  }, []);

  const debounced = useDebounce(labelFilter, 300);

  const { mutate: createLabel } = useCreateLabel();

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useGetWorkspaceLabels({
      q: debounced,
      pageSize: 5,
    });

  const fetchOnScroll = useCallback(
    (entry: IntersectionObserverEntry[]) => {
      if (entry?.[0]?.isIntersecting) {
        fetchNextPage?.();
      }
    },
    [fetchNextPage],
  );
  const currentPage = data?.pages[data.pages.length - 1];

  const { setTarget } = useIntersectionObserver(fetchOnScroll);

  const labels = useMemo(
    () => data?.pages.flatMap((page) => page.results ?? []) ?? [],
    [data?.pages],
  );

  const { mutate: changeOrder } = useOrderLabels();

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.active.data.current?.id && event.over?.data.current?.id) {
        changeOrder({
          fromLabel: event.active.data.current as TLabel,
          toLabel: event.over?.data.current as TLabel,
        });
      }
    },
    [changeOrder],
  );

  const isEmpty = labels.length === 0;

  const [newLabelId, setNewLabelId] = useState<string | undefined>(undefined);

  const addNewLabel = useCallback(() => {
    const colors = Object.values(PROJECT_COLOR);
    createLabel(
      {
        value: labelFilter || LABEL_PACEHOLDER,
        color: colors[Math.round(Math.random() * (colors.length - 1))],
      },
      {
        onSuccess: (data) => setNewLabelId(data?.id),
      },
    );
  }, [createLabel, labelFilter]);

  const renderContent = useCallback(() => {
    if (isEmpty) {
      return (
        <div className={styles.emptyState}>
          <p>{t(`${baseI18n}.emptyState.title`)}</p>
          <span>{t(`${baseI18n}.emptyState.description`)}</span>
        </div>
      );
    } else {
      return (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={labels}
            disabled={labelFilter?.length > 0}
          >
            {labels.map((label) => (
              <LabelItem
                key={label.id}
                label={label}
                focus={newLabelId === label.id}
                onBlur={() => setNewLabelId(undefined)}
              />
            ))}
            {hasNextPage &&
              (!(isLoading || isFetching) ? (
                <div
                  key={`loader_${currentPage.nextPage ?? -1}`}
                  id={`loader_${currentPage.nextPage ?? -1}`}
                  ref={setTarget}
                  className={styles.pagination}
                ></div>
              ) : (
                <ClipLoader size={32} />
              ))}
          </SortableContext>
        </DndContext>
      );
    }
  }, [
    currentPage.nextPage,
    hasNextPage,
    isEmpty,
    isFetching,
    isLoading,
    labelFilter?.length,
    labels,
    newLabelId,
    onDragEnd,
    setTarget,
  ]);
  return (
    <Section
      title={t(`${baseI18n}.title`)}
      subTitle={t(`${baseI18n}.subTitle`)}
    >
      <div className={styles.actionContainer}>
        <div>
          <Input
            TrailingIcon={
              <div className={styles.clearButtonContainer}>
                <Button
                  variant="ghost"
                  icon={IconX}
                  iconProps={{ size: 20 }}
                  disabled={!labelFilter}
                  onClick={() => setLabelFilter('')}
                  className={classNames({
                    [styles.hideButton]: !labelFilter,
                  })}
                />
              </div>
            }
            LeadingIcon={<IconSearch color={leadingIconColor} size={20} />}
            name={'filter-labels'}
            placeholder="Filter labels"
            value={labelFilter}
            onChange={onChangeFn}
          />
        </div>
        <Button
          size="medium"
          label={t(`${baseI18n}.actions.add`)}
          className={styles.addLabelButton}
          onClick={addNewLabel}
        />
      </div>
      <div className={styles.labelContainer}>{renderContent()}</div>
    </Section>
  );
}
