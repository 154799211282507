import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { BaseResponse } from '@/types/base-responses';
import { TSuccessErrorHook } from '@/types/generic';

import { UserContext } from '@contexts/UserContext';
import { http } from '@services/api';

type Props = {
  invoiceId: string;
  subscriptionId: string;
} & TSuccessErrorHook<string>;

export const GET_INVOICE_URL_KEY = 'get-invoice-url';
export default function useGetInvoiceUrl({
  invoiceId,
  subscriptionId,
  enabled = true,
}: Props) {
  const { workspace } = useContext(UserContext);
  const KEY = [GET_INVOICE_URL_KEY, invoiceId, subscriptionId];
  return useQuery({
    queryKey: KEY,
    queryFn: async ({ queryKey: [_, transactionId, subscriptionId] }) => {
      const {
        data: { data },
      } = await http.get<BaseResponse<{ invoiceUrl: string }>>(
        `/workspaces/${workspace?.id}/subscriptions/${subscriptionId}/invoices/${transactionId}`,
      );
      return data.invoiceUrl;
    },
    enabled: enabled && !!workspace,
  });
}
