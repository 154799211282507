import { useCallback, useContext, useEffect, useState } from 'react';

import { DndContext } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import { useProfileModal } from '@contexts/ModalPreferenceContext';
import SortAndFilter from '@contexts/SortAndFilterContext';
import { UserContext, useSubscription } from '@contexts/UserContext';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@hooks/utils/useAnalytics';
import useSubscriptionWSResponse from '@hooks/utils/useSubscriptionWSResponse';

import Button from '@components/Button';
import Drawer from '@components/Drawer';
import Header from '@components/Header';
import Layout from '@components/Layout';
import ModalMobileExperience from '@components/Modals/ModalMobileExperience';
import ModalPastDueSubscription from '@components/Modals/ModalPastDueSubscription';
import ModalTermsOfService from '@components/Modals/ModalTermsOfService';
import { ContentPages } from '@components/Modals/ModalUserProfile';
import TimelineResources from '@components/Timelines/TimelineResources';
import { TimelineProvider } from '@components/Timelines/TimelineResources/context';
import WarningHeader from '@components/WarningHeader';

import styles from './styles.module.css';

export default function DashboardResourceView() {
  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);
  const { isPastDue } = useSubscription();

  const [isOpened, setIsOpened] = useState<boolean>(isPastDue);
  const { openModal } = useProfileModal();
  const openBillingModal = useCallback(() => {
    openModal(ContentPages.BILLING_INFO);
  }, [openModal]);

  useEffect(() => {
    setIsOpened(isPastDue);
  }, [isPastDue]);

  useEffect(() => {
    trackPage(ANALYTICS_PAGE_NAME.PEOPLE, workspaceId as string);
  }, [workspaceId, trackPage]);

  useSubscriptionWSResponse();

  const [isTermsOfServiceOpened, setIsTermsOfServiceOpened] = useState(false);

  const handleTermsOfServiceOpenChange = useCallback(
    (status: 'opened' | 'closed') => {
      setIsTermsOfServiceOpened(status === 'opened');
    },
    [],
  );

  return (
    <Layout>
      <SortAndFilter namePrefix="timeline-resource">
        <TimelineProvider>
          <WarningHeader isOpened={isPastDue}>
            <span>
              We were unable to process your last payment. Please{' '}
              <Button
                label="update your billing information"
                variant="link"
                size="small"
                onClick={openBillingModal}
                className={styles.linkWarning}
              />
            </span>
          </WarningHeader>

          <Header />
          <DndContext
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <TimelineResources />
            <Drawer />
          </DndContext>
        </TimelineProvider>
      </SortAndFilter>
      <ModalMobileExperience />
      <ModalTermsOfService onOpenChange={handleTermsOfServiceOpenChange} />
      {isOpened && !isTermsOfServiceOpened && (
        <ModalPastDueSubscription
          isOpen={true}
          onClose={() => {
            setIsOpened(false);
          }}
        />
      )}
    </Layout>
  );
}
