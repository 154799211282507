import { useCallback, useRef } from 'react';
import { type ControllerProps } from 'react-hook-form';

import { IconLabel, IconX } from '@tabler/icons-react';
import classNames from 'classnames';

import { TLabel } from '@/types/labels';
import { TUserMember } from '@/types/workspace';

import { getCssVariable } from '@services/helpers';

import Chip from '@components/Chip';
import ColorDot from '@components/ColorDot';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';
import SelectLabelMenu from '@components/MenuUtility/SelectLabelMenu';

import styles from './styles.module.css';

type Props = Parameters<ControllerProps<TUserMember, 'labels'>['render']>[0];

export default function AddLabelController({
  field: { name, value: selectedLabels, onChange },
}: Props) {
  const selectMenuRef = useRef<DropdownMenuRefType>(null);
  const onSelectLabel = useCallback(
    (select: boolean, label: TLabel) => {
      let ret: TLabel[] = [];
      if (select) ret = [...(selectedLabels ?? []), label];
      else ret = selectedLabels?.filter((item) => item.id !== label.id) ?? [];
      onChange(ret);
      return ret;
    },
    [onChange, selectedLabels],
  );
  return (
    <div className={styles.labelSection}>
      <span className={styles.labelSectionTitle}>{name}</span>
      <div className={styles.labelWrapper}>
        {selectedLabels?.map((label) => (
          <Chip key={label.id} size="medium" className={styles.chip}>
            <ColorDot color={label.color} size={24} />
            <div className={styles.labelChipName}>
              <span className={styles.label}>{label.value}</span>
            </div>
            <IconX
              size={16}
              cursor={'pointer'}
              className={styles.iconDelete}
              onClick={() => onSelectLabel(false, label)}
            />
          </Chip>
        ))}
        <Chip
          type="dashed"
          asButton
          size="medium"
          onClick={(e) => selectMenuRef.current?.show(e)}
          className={classNames(styles.chip, styles.addLabelChip)}
        >
          <IconLabel size={20} color={getCssVariable('--icon-subdued')} />
          <div className={styles.labelChipName}>
            <span className={classNames(styles.label, styles.addLabelCta)}>
              Add Label
            </span>
          </div>
        </Chip>

        <SelectLabelMenu
          selectedLabels={selectedLabels ?? []}
          onItemClick={onSelectLabel}
          ref={selectMenuRef}
        />
      </div>
    </div>
  );
}
