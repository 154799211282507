import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Item, Separator } from 'react-contexify';

import { KeyboardCode } from '@dnd-kit/core';
import { IconLabel } from '@tabler/icons-react';
import { some } from 'lodash';
import { useDebounce } from 'use-hooks';

import { PROJECT_COLOR } from '@/types/enums';
import { TLabel } from '@/types/labels';

import useCreateLabel from '@hooks/workspace/labels/useCreateLabel';
import useGetWorkspaceLabels from '@hooks/workspace/labels/useGetWorkspaceLabels';

import ColorDot from '@components/ColorDot';
import BaseDropdownMenu from '@components/MenuUtility/DropdownMenu';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';
import ItemScrollableContent from '@components/MenuUtility/UtilityItems/component/ItemScrollableContent';
import ItemSearch from '@components/MenuUtility/UtilityItems/component/ItemSearch/ItemSearch';
import ItemWithCheckbox from '@components/MenuUtility/UtilityItems/component/ItemWithCheckbox';
import FlexContainer from '@components/utils/FlexContainer';

import styles from './styles.module.css';

type Props = {
  onItemClick: (selected: boolean, label: TLabel) => void;
  selectedLabels: TLabel[];
};
const SelectLabelMenu = forwardRef<DropdownMenuRefType, Props>(
  ({ onItemClick, selectedLabels }, ref) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const debounced = useDebounce(searchValue, 300);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
      useGetWorkspaceLabels({
        q: debounced,
        pageSize: 10,
      });

    const labels = useMemo(
      () => data?.pages.flatMap((page) => page.results ?? []) ?? [],
      [data?.pages],
    );

    const [menuOpen, setMenuOpen] = useState(false);
    const { mutate: createLabel } = useCreateLabel();

    const onCreateNewLabels = useCallback(() => {
      const colors = Object.values(PROJECT_COLOR);
      setSearchValue('');
      createLabel(
        {
          color: colors[Math.round(Math.random() * (colors.length - 1))],
          value: debounced.trim(),
        },
        {
          onSuccess(data) {
            if (data) onItemClick(true, data);
          },
        },
      );
    }, [createLabel, onItemClick, debounced]);

    const oneExact = useMemo(() => {
      if (debounced) {
        const val = debounced.trim().toLowerCase();
        return !!labels.find((label) => label.value.toLowerCase() === val);
      }
      return false;
    }, [debounced, labels]);

    const handleKeyboardEvent = useCallback(
      (e: KeyboardEvent) => {
        switch (e.key) {
          case KeyboardCode.Enter:
            if (debounced) {
              e.preventDefault();
              e.stopPropagation();
              if (oneExact) {
                const val = debounced.trim().toLowerCase();
                const label = labels.find((l) => l.value === val);
                if (label) {
                  const isLabelSelected = some(
                    selectedLabels,
                    (itemId) => itemId.id === label.id,
                  );
                  if (!isLabelSelected) onItemClick(true, label);
                }
              } else {
                onCreateNewLabels();
              }
            }
            setSearchValue('');
            return;

          case KeyboardCode.Esc:
            e.preventDefault();
            e.stopPropagation();
            (ref as RefObject<DropdownMenuRefType>)?.current?.hide();
            return;
          default:
            return;
        }
      },
      [
        debounced,
        ref,
        oneExact,
        labels,
        selectedLabels,
        onItemClick,
        onCreateNewLabels,
      ],
    );

    useEffect(() => {
      if (!menuOpen) return;
      const opt = { capture: true };
      window.addEventListener('keydown', handleKeyboardEvent, opt);
      return () =>
        window.removeEventListener('keydown', handleKeyboardEvent, opt);
    }, [handleKeyboardEvent, menuOpen]);

    const fetchOnScroll = useCallback(
      (entry: IntersectionObserverEntry[]) => {
        if (fetchNextPage && entry?.[0]?.isIntersecting) {
          fetchNextPage();
        }
      },
      [fetchNextPage],
    );

    return (
      <BaseDropdownMenu
        id={'add-label'}
        className={styles.labelMenu}
        ref={ref}
        onVisibilityChange={(isVisible) => {
          setSearchValue('');
          setMenuOpen(isVisible);
        }}
      >
        <ItemSearch
          value={searchValue}
          onValueChanged={setSearchValue}
          placeholder="Labels"
          ref={inputRef as RefObject<HTMLInputElement>}
        />
        <Separator />
        <ItemScrollableContent
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isFetching={isFetching}
          fetchOnScroll={fetchOnScroll}
        >
          {labels?.length ? (
            labels?.map((label) => {
              const isLabelSelected = some(
                selectedLabels,
                (itemId) => itemId.id === label.id,
              );

              return (
                <ItemWithCheckbox
                  key={label.id}
                  id={label.id}
                  isItemSelected={isLabelSelected}
                  onItemClick={(selected) => onItemClick(selected, label)}
                >
                  <ColorDot color={label.color} size={16} />
                  {label.value}
                </ItemWithCheckbox>
              );
            })
          ) : (
            <Item className="unstyled-item" closeOnClick={false} disabled>
              No labels found
            </Item>
          )}
        </ItemScrollableContent>
        {searchValue && !oneExact && <Separator />}
        {searchValue && !oneExact && (
          <Item onClick={onCreateNewLabels} closeOnClick={false}>
            <FlexContainer
              gap={8}
              align="center"
              className={styles.labelMenuItem}
            >
              <IconLabel size={20} />
              <span>New label:</span>
              <span
                className={styles.newLabelPreview}
              >{`"${searchValue}"`}</span>
            </FlexContainer>
          </Item>
        )}
      </BaseDropdownMenu>
    );
  },
);

SelectLabelMenu.displayName = 'SelectLabelMenu';
export default SelectLabelMenu;
