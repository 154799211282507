import { useCallback, useContext } from 'react';

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from '@firebase/storage';
import { useMutation } from '@tanstack/react-query';
import imageCompression from 'browser-image-compression';
import { t } from 'i18next';

import { TAttachmentDataUrls } from '@/types/generic';

import { NotificationsContext } from '@contexts/NotificationContext';
import { generateUUID } from '@services/helpers';

const types = ['image/png', 'image/jpeg', 'image/jpg'];
type Props = { file: File };

export default function useFirebaseUploader(
  basePath: string,
  onSuccess?: (data: TAttachmentDataUrls) => void,
) {
  const storage = getStorage();

  const uploadToFirebase = useCallback(
    async (file: File): Promise<TAttachmentDataUrls> => {
      let localFile = file;
      const uuid = generateUUID();
      const fileName = localFile?.name?.substring(
        0,
        localFile?.name?.lastIndexOf('.'),
      );
      const fileExtension = localFile?.name?.split('.')?.pop();
      const REF_PATH = `${basePath.replace(/(\/+)$/, '')}/${fileName?.replace(
        /\s/g,
        '-',
      )}-${uuid}.${fileExtension}`;

      const storageRef = ref(storage, REF_PATH);
      // Compress image if it is a png or jpeg and size is greater than 0.5MB
      if (types.includes(localFile?.type) && localFile?.size > 500000) {
        localFile = await imageCompression(localFile, {
          maxSizeMB: 0.5,
          useWebWorker: true,
          maxWidthOrHeight: 128,
        });
      }
      await uploadBytes(storageRef, localFile);
      const url = await getDownloadURL(storageRef);
      return {
        name: localFile.name,
        url,
        id: '',
        ref: REF_PATH,
        size: localFile.size,
        mimeType: localFile.type,
      };
    },
    [basePath, storage],
  );

  // const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);

  return useMutation<TAttachmentDataUrls, Error, Props>({
    mutationFn: async ({ file }: Props): Promise<TAttachmentDataUrls> =>
      await uploadToFirebase(file),
    onSuccess,
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
