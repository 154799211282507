import Header from '@/shared-app/components/common/Header';
import Layout from '@/shared-app/components/Layout';
import TimelineProject from '@/shared-app/components/TimelineProject';
import TimelineDetails from '@/shared-app/components/TimelineProject/TimelineDetail';
import { TimelineProjectProvider } from '@/shared-app/context/TimelineProjectContext';

export default function Home() {
  return (
    <Layout title="Timeline">
      <TimelineProjectProvider>
        <Header />
        <TimelineProject />
        <TimelineDetails />
      </TimelineProjectProvider>
    </Layout>
  );
}
