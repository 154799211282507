import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { IconSparkles } from '@tabler/icons-react';
import { t } from 'i18next';

import { useUpdateAutoHide } from '@hooks/workspace/useUpdateAutoHide';
import { useUpdateAutoSync } from '@hooks/workspace/useUpdateAutoSync';
import { useWorkspaceFromContext } from '@hooks/workspace/useWorkspaceFromContext';
import { getCssVariable } from '@services/helpers';

import Section from '@components/Section';
import Switch from '@components/Switch';

import styles from './styles.module.css';
import WorkloadPreferences from './WorkloadPreferences';

export type UpdateWorkspaceDataForm = {
  workspaceName: string;
};

export default function ModalWorkspaceSettingContent() {
  const { setValue } = useForm<UpdateWorkspaceDataForm>({ mode: 'onSubmit' });

  const { workspace } = useWorkspaceFromContext();

  useEffect(() => {
    setValue('workspaceName', workspace?.name ?? '');
  }, [setValue, workspace?.name]);

  const iconColor = getCssVariable('--icon-caution');

  const { mutate: updateAutoSync } = useUpdateAutoSync();
  const { mutate: updateAutoHide } = useUpdateAutoHide();

  return (
    <React.Fragment>
      <Section
        title={
          <div className={styles.automationSectionHeader}>
            <IconSparkles size={24} color={iconColor} fill={iconColor} />
            {'Automations'}
          </div>
        }
      >
        <div className={styles.mainContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoSync.label',
                )}
              </div>
              <div className={styles.rowDescription}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoSync.description',
                )}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <Switch
                id={'autoSync'}
                checked={!!workspace?.autoSync}
                onChange={updateAutoSync}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoHide.label',
                )}
              </div>
              <div className={styles.rowDescription}>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.autoHide.description',
                )}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <Switch
                id={'autoHide'}
                checked={workspace?.autoHide ?? false}
                onChange={updateAutoHide}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.linkWrapper}>
              <a
                href={import.meta.env.VITE_BLOG_URL}
                target="_blank"
                rel="noreferrer"
              >
                <u>
                  {t(
                    'common:profileModal.workspaceSettings.manageAutomation.linkHighlightedText',
                  )}
                </u>
              </a>
              <span>
                {t(
                  'common:profileModal.workspaceSettings.manageAutomation.linkNormalText',
                )}
              </span>
            </div>
          </div>
        </div>
      </Section>
      <Section title={'Workload'}>
        <WorkloadPreferences />
      </Section>
    </React.Fragment>
  );
}
