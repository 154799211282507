import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';
import { TimelineProjectContext } from '@/shared-app/context/TimelineProjectContext';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  IconArrowsDiagonal,
  IconArrowsDiagonalMinimize2,
} from '@tabler/icons-react';
import { type VirtualItem } from '@tanstack/react-virtual';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import classNames from 'classnames';
import {
  add,
  differenceInCalendarDays,
  format,
  getDay,
  getWeekOfMonth,
  getWeeksInMonth,
  isBefore,
  isSameWeek,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { t } from 'i18next';

import { weeksToPixels } from '@services/helpers';

import Button from '@components/Button';
import {
  WEEKS_TO_ADD,
  WORKING_DAYS_IN_A_WEEK,
} from '@components/Timelines/TimelineProjects/context';
import Toolbar from '@components/Toolbar';

import ResourceRow from './ResourceRow';
import styles from './styels.module.css';

export default function TimelineProject() {
  const {
    updateTimeInterval,
    timeInterval,
    weeks,
    virtualizer,
    ref: containerRef,
    weekSizeInPx,
    todayRef,
    data,
  } = useContext(TimelineProjectContext);

  const today = startOfToday();
  const currentWeek = startOfWeek(today);
  const weekday = getDay(today) - 1 >= 0 ? getDay(today) - 1 : 6; // We want Monday to equal 0 and Sunday to be 6

  const roundedWeekday = Math.min(weekday, WORKING_DAYS_IN_A_WEEK); // Exclude weekends

  const previousScrollInPx = useRef(0);
  const isAddingWeeks = useRef(false);

  const { layoutIsExpanded, onChangeLayout } = useContext(SharedAppContext);

  // add weeks on scroll in both directions
  const handleAddWeekOnScrollFn = useThrottledCallback(
    () => {
      if (containerRef.current && !isAddingWeeks.current) {
        // get all the values needed to calculate the scroll
        const scrollWidth = virtualizer?.getTotalSize();
        const scrollLeft = virtualizer.scrollOffset ?? 0;
        const direction = virtualizer.scrollDirection;
        const clientWidth = virtualizer.scrollElement?.clientWidth ?? 0;

        // reset the previousScrollInPx to prevent wrong scroll position when scrolling back
        if (direction === 'forward') previousScrollInPx.current = 0;

        // check if the scroll is at the end or the beginning
        if (
          direction === 'forward' &&
          scrollWidth - scrollLeft - clientWidth < clientWidth / 4
        ) {
          isAddingWeeks.current = true;
          updateTimeInterval('after');
        } else if (direction === 'backward' && scrollLeft < clientWidth / 4) {
          isAddingWeeks.current = true;
          // add weeks to add to the previous scroll position to prevent wrong scroll position
          // and set the value to previousScrollInPx that will be used in the useEffect
          previousScrollInPx.current = scrollLeft + WEEKS_TO_ADD * weekSizeInPx;
          updateTimeInterval('before');
        }
      }
    },
    [updateTimeInterval],
    100,
  );

  useEffect(() => {
    if (
      containerRef.current &&
      previousScrollInPx.current !== 0 &&
      isAddingWeeks.current
    ) {
      virtualizer.scrollToOffset(previousScrollInPx.current);
    }
    isAddingWeeks.current = false;
  }, [containerRef, timeInterval, virtualizer]);

  const renderWeekHeader = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const endDate = add(startDate, { days: WORKING_DAYS_IN_A_WEEK - 1 });
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const weeksInMonth =
        getWeeksInMonth(startDate) -
        (weekOfMonth === 2 && isBefore(firstDayOfMonth, startDate) ? 1 : 0);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <Tooltip.Provider key={virtualWeek.key} delayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={classNames(styles.weekHeader, {
                  [styles.firstWeekHeader]: isFirstWeekOfMonth,
                })}
                style={{
                  cursor: 'pointer',
                  width: `${virtualWeek.size}px`,
                  transform: `translateX(${virtualWeek.start}px) `,
                }}
              >
                {isFirstWeekOfMonth && (
                  <span
                    className={styles.monthLabel}
                    style={{ width: weeksInMonth * weekSizeInPx }}
                  >
                    {format(startDate, 'MMMM y')}
                  </span>
                )}
                <span
                  className={classNames(styles.weekLabel, {
                    [styles.currentWeek]: isSameWeek(currentWeek, startDate),
                  })}
                >
                  <span>{format(startDate, 'd')}</span>
                  <span>{format(startDate, 'MMM')}</span>
                </span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={-12}>
                {`${format(startDate, 'E, dd MMM')} - ${format(
                  endDate,
                  'E, dd MMM',
                )}`}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [currentWeek, weekSizeInPx, weeks],
  );

  const renderWeek = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <div
          key={virtualWeek.key}
          className={classNames(styles.week, {
            [styles.firstWeek]: isFirstWeekOfMonth,
          })}
          style={{
            width: `${virtualWeek.size}px`,
            transform: `translateX(${virtualWeek.start}px) `,
          }}
        />
      );
    },
    [weeks],
  );

  const virtualItems = virtualizer?.getVirtualItems();

  const todayPosition = useMemo(
    () =>
      weeksToPixels(currentWeek, timeInterval.start, false, weekSizeInPx) +
      roundedWeekday * (weekSizeInPx / WORKING_DAYS_IN_A_WEEK),
    [currentWeek, roundedWeekday, timeInterval.start, weekSizeInPx],
  );

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      onScroll={handleAddWeekOnScrollFn}
    >
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className={styles.floatingButton}>
              <Toolbar>
                <Button
                  icon={
                    layoutIsExpanded
                      ? IconArrowsDiagonalMinimize2
                      : IconArrowsDiagonal
                  }
                  variant="ghost"
                  size="large"
                  onClick={onChangeLayout}
                />
              </Toolbar>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal
            container={document.getElementById('radix-tooltip-portal')}
          >
            <Tooltip.Content className="TooltipContent" sideOffset={8}>
              {layoutIsExpanded ? t('common:compress') : t('common:expand')}
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
      <div
        className={styles.container}
        style={{ width: `${virtualizer?.getTotalSize()}px` }}
      >
        <div className={styles.header}>
          {virtualItems.map(renderWeekHeader)}
        </div>
        <div className={styles.grid}>
          {virtualItems.map(renderWeek)}
          <div
            ref={todayRef}
            className={styles.today}
            data-position={todayPosition}
            style={{
              left: todayPosition,
            }}
          />
        </div>
        <div className={styles.content}>
          {data?.resources?.map((resource) => (
            <ResourceRow resource={resource} key={resource.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
