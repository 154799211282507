import { PropsWithChildren, useRef } from 'react';

import { setDefaultOptions } from 'date-fns';

import { TResourceItemList } from '@/types/timeline';

import ResourceRowBlock from './Blocks';
import ResourceRowContent from './Content';
import type { MouseEventRef } from './Context';
import ProjectRowContext from './Context';
import styles from './styles.module.css';

type Props = {
  disabled?: boolean;
  resource: TResourceItemList;
};

const ResourceRow = ({ resource }: PropsWithChildren<Props>) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const mouseEventRef = useRef<MouseEventRef>({});

  // const { virtualizer, weeks, scrollToBlock } = useContext(
  //   TimelineProjectContext,
  // );
  // const { timeblocks } = resource;

  // const hasBlock = useMemo(() => !!timeblocks?.length, [timeblocks?.length]);
  // const items = virtualizer.getVirtualItems();

  // const [startDate, endDate] = useMemo(() => {
  //   if (!items.length) return [null, null];
  //   const { 0: first, [items.length - 1]: last } = items;
  //   const startDate: Date = weeks[first.index + virtualizer.options.overscan];
  //   const endDate: Date = weeks[last.index - virtualizer.options.overscan];
  //   return [startDate, endDate];
  // }, [items, virtualizer.options.overscan, weeks]);

  // const firstLeftNotVisibleBlock: TTimeBlockRange | undefined = useMemo(() => {
  //   if (!startDate || !endDate || !timeblocks?.length) return undefined;
  //   return timeblocks?.findLast(
  //     ({ end: blockEnd }) => compareAsc(blockEnd, startDate) < 0,
  //   );
  // }, [endDate, startDate, timeblocks]);

  // const firstRightNotVisibleBlock: TTimeBlockRange | undefined = useMemo(() => {
  //   if (!startDate || !endDate || !timeblocks?.length) return undefined;
  //   return (
  //     timeblocks?.find(
  //       ({ start: blockStart }) => compareAsc(blockStart, endDate) >= 0,
  //     ) ?? undefined
  //   );
  // }, [endDate, startDate, timeblocks]);

  // const scrollToNear = useCallback(
  //   (block?: TTimeBlockRange, position?: 'left' | 'right') => {
  //     if (block?.end && block?.start)
  //       scrollToBlock({
  //         start: block.start,
  //         end: block.end,
  //         position: position === 'left' ? 'end' : 'start',
  //       });
  //   },
  //   [scrollToBlock],
  // );

  return (
    <ProjectRowContext.Provider
      value={{
        resource,
        mouseEventRef,
      }}
    >
      <div className={styles.container}>
        {/* <ResourceBlockIndicator
          resource={resource}
          show={hasBlock && !!firstLeftNotVisibleBlock}
          position="left"
          nearBlock={firstLeftNotVisibleBlock}
          onClick={scrollToNear}
        /> */}

        <ResourceRowContent>
          <ResourceRowBlock />
        </ResourceRowContent>

        {/* <ResourceBlockIndicator
          resource={resource}
          show={hasBlock && !!firstRightNotVisibleBlock}
          position="right"
          nearBlock={firstRightNotVisibleBlock}
          onClick={scrollToNear}
        /> */}
      </div>
    </ProjectRowContext.Provider>
  );
};

export default ResourceRow;
