import { useContext } from 'react';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';
import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { BaseResponse } from '@/types/base-responses';
import { TSuccessErrorHook } from '@/types/generic';
import { TResourceItemList } from '@/types/timeline';

import { handleApiCall } from '@services/api';
import { normalizeResourceItem } from '@services/normalizer';

export const GET_PUBLIC_PROJECT_RESOURCES_KEY = 'get-public-project-resources';

export default function useGetPublicProjectResources({
  enabled = true,
  onSuccess = noop,
}: TSuccessErrorHook<{
  resources: TResourceItemList[] | null;
  totalAllocation: number;
} | null> = {}) {
  const { publicHttpClient, clientInitialized } = useContext(SharedAppContext);
  return useQuery({
    queryKey: [GET_PUBLIC_PROJECT_RESOURCES_KEY],
    queryFn: async () =>
      handleApiCall(
        publicHttpClient
          ?.get<
            BaseResponse<{
              resources: TResourceItemList[];
              totalAllocation: number;
            }>
          >('timeline/resources')
          .then((response) => {
            return response
              ? {
                  resources: response?.data?.data?.resources
                    ? response?.data.data.resources.map(normalizeResourceItem)
                    : null,
                  totalAllocation: response?.data?.data?.totalAllocation,
                }
              : null;
          }),
        onSuccess,
        undefined,
      ),
    enabled: clientInitialized && enabled,
  });
}
