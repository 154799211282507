import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClipLoader } from 'react-spinners';

import * as Tooltip from '@radix-ui/react-tooltip';
import { IconChevronLeft, IconNoteOff } from '@tabler/icons-react';
import { t } from 'i18next';

import { TTimeBlockRange } from '@/types/timeline';

import { UserContext } from '@contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@hooks/utils/useAnalytics';
import useUpdateAllocationDetails from '@hooks/workspace/allocations/useUpdateAllocationDetails';

import Button from '@components/Button';
import Input from '@components/Input';

import { ItemWithTooltip } from './ItemWithTooltip';
import styles from './styles.module.css';

type Props = {
  block: TTimeBlockRange;
  onClose: () => void;
  onUpdateDetail: (block: TTimeBlockRange | undefined) => void;
};
export default function AllocationNoteForm({
  block,
  onClose,
  onUpdateDetail,
}: Props) {
  const [noteValue, setNoteValue] = useState<string | undefined>(block.note);
  const { workspaceId } = useContext(UserContext);
  const formRef = React.useRef<HTMLFormElement>(null);
  const limit = 40;
  const noteActiveHasError = useMemo(() => {
    return !!noteValue && (noteValue.length <= 0 || noteValue.length > limit);
  }, [noteValue]);

  const { trackEvent } = useAnalytics();

  const { mutate: updateDetail, isPending } = useUpdateAllocationDetails();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const timerRef = React.useRef<number | undefined>(undefined);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(
    () => {
      if (!inputRef.current) return;
      if (noteValue && inputRef.current !== document.activeElement)
        inputRef.current.select();
      else inputRef.current?.focus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateNote = useCallback(
    ({ target: { value } }: { target: { value: string } }) => {
      if (value.length > limit) setNoteValue(value.substring(0, limit));
      else setNoteValue(value);
    },
    [],
  );

  const clearTimerRef = useCallback(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }
  }, []);

  //On destroy
  useEffect(() => clearTimerRef, [clearTimerRef]);

  const updateBlockDetailFn = useCallback(
    (noteValue: string | undefined) => {
      updateDetail(
        { note: noteValue, allocationId: block.id },
        {
          onError: (_error) => {
            setNoteValue(block.note);
            clearTimerRef();
          },
          onSuccess: (newAllocation: TTimeBlockRange | undefined) => {
            trackEvent(
              ANALYTICS_EVENTS.BLOCK_DETAIL_UPDATED,
              workspaceId as string,
            );
            onUpdateDetail(newAllocation);
            clearTimerRef();
            onClose();
          },
        },
      );
      if (timerRef.current) window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setShowLoading(true);
        clearTimerRef();
      }, 500);
    },
    [
      block.id,
      block.note,
      clearTimerRef,
      onClose,
      onUpdateDetail,
      trackEvent,
      updateDetail,
      workspaceId,
    ],
  );

  const handleUpdateNote = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (noteActiveHasError) return;
      if (!noteActiveHasError && block.note !== noteValue && !isPending)
        updateBlockDetailFn(noteValue);
      else onClose();
    },
    [
      block.note,
      isPending,
      noteActiveHasError,
      noteValue,
      onClose,
      updateBlockDetailFn,
    ],
  );

  const onClearNote = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();
      setNoteValue(undefined);
      updateBlockDetailFn(undefined);
    },
    [updateBlockDetailFn],
  );
  const onKeyEnter = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        e.preventDefault();
        if (!isPending) handleUpdateNote();
      }
    },
    [handleUpdateNote, isPending],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyEnter);
    return () => {
      window.removeEventListener('keydown', onKeyEnter);
    };
  }, [onKeyEnter]);

  return (
    <form onSubmit={handleUpdateNote} autoCorrect="false" ref={formRef}>
      <Button
        type="button"
        onClick={handleUpdateNote}
        size="medium"
        icon={IconChevronLeft}
      />
      <Input
        ref={inputRef}
        error={noteActiveHasError}
        placeholder={t('common:allocation.notePlaceHolder')}
        name={'note'}
        value={noteValue ?? ''}
        disabled={isPending}
        onChange={updateNote}
        className={styles.noteInput}
      />

      <div className={styles.shortcutContainer}>
        {isPending && showLoading ? (
          <ClipLoader size={18} color="white" />
        ) : (
          <span className={styles.shortcut}>⏎</span>
        )}
      </div>

      {block?.note && (
        <Tooltip.Provider delayDuration={500}>
          <ItemWithTooltip shortcutLabel={'Clear'}>
            <Button
              type="button"
              size="medium"
              onClick={onClearNote}
              icon={IconNoteOff}
            />
          </ItemWithTooltip>
        </Tooltip.Provider>
      )}
    </form>
  );
}
