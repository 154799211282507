import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  differenceInWeeks,
  endOfWeek,
  isBefore,
  setDefaultOptions,
  subDays,
  subWeeks,
} from 'date-fns';
import { noop } from 'lodash';

import { ALLOCATION_EVENT_OPERATION_TYPE, PROJECT_STATUS } from '@/types/enums';
import {
  TTimeBlockRange,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

import { UIContext } from '@contexts/UIContext';
import { generateUUID, linearMap, weeksToPixels } from '@services/helpers';
import { updateBlocksWithProperEvents } from '@services/helpers/timelines/resources';

import { TimelineResourcesContext } from '@components/Timelines/TimelineResources/context';
import ScrollDraggingOverlay from '@components/Timelines/TimelineResources/DraggingOverlay';
import { useProjectRowContext } from '@components/Timelines/TimelineResources/ProjectRow/Context';

import Content from './Content';
import BlockContext, { MouseEventRef } from './Context';
import Handle from './Handle';
import styles from './styles.module.css';
import Tooltip from './Tooltip';

export type Props = {
  isTimeoff?: boolean;
  resourceId: string;
  projectId: string;
  projectColor: string;
  projectStatus?: PROJECT_STATUS;
  onClick: ({
    blockId,
    isMulti,
    projectId,
    resourceId,
  }: {
    blockId: string;
    isMulti: boolean;
    projectId: string;
    resourceId: string;
  }) => void;
  block: TTimeBlockRange;
  projectName: string;
  isActive?: boolean;
  emoji?: string;
};

const BlockWrap = ({
  children,
  block,
  isTimeoff,
  projectColor = 'grey',
  resourceId,
  projectId,
  projectStatus,
  onClick,
  isActive,
  projectName,
  emoji,
}: PropsWithChildren<Props>) => {
  setDefaultOptions({ weekStartsOn: 1 });

  const mouseEventRef = useRef<MouseEventRef>({});

  const [_shiftIsPressed, setShiftIsPressed] = useState(false);
  const [localProps, setLocalProps] = useState(block);
  const [isDragging, setDragging] = useState(false);
  const { onActiveBlockFn, rowDisabled } = useProjectRowContext();

  const {
    timeInterval,
    setBlocksToUpdate,
    weekSizeInPx,
    setActiveBlockIds,
    activeBlockIds,
    data,
    // setData,
    virtualizer,
    setSelectedBlockId,
  } = useContext(TimelineResourcesContext);
  const { layoutIsExpanded } = useContext(UIContext);
  const currentResource = useMemo(
    () => data?.find((p) => p.id === resourceId) as TTimelineResource,
    [data, resourceId],
  );
  const [blockIdForActiveState, setBlockIdForActiveState] =
    useState<string>('');
  const currentProject = useMemo(
    () =>
      currentResource?.projects?.find(
        (p) => p.id === projectId,
      ) as TTimelineProject,
    [currentResource, projectId],
  );

  const lengthWeeks = differenceInCalendarWeeks(
    localProps.end,
    localProps.start,
  );

  const startPixels = weeksToPixels(
    localProps.start,
    timeInterval.start,
    false,
    weekSizeInPx,
  );

  const endPixels = weeksToPixels(
    localProps.end,
    timeInterval.start,
    true,
    weekSizeInPx,
  );

  const lengthOfBlockInPx = endPixels - startPixels;

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerClickRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    document.addEventListener('keydown', onKeydownFn);
    document.addEventListener('keyup', onKeyupFn);
    return () => {
      document.removeEventListener('keydown', onKeydownFn);
      document.removeEventListener('keyup', onKeyupFn);
    };
  }, []);

  const finalizeData = useCallback(
    (newBlock: TTimeBlockRange) => {
      const blocksToUpdate = updateBlocksWithProperEvents(
        currentProject?.timeblocks || [],
        newBlock,
      );

      setBlocksToUpdate({
        events: blocksToUpdate,
        projectId,
        resourceId,
      });
    },
    [currentProject?.timeblocks, setBlocksToUpdate, projectId, resourceId],
  );

  const currentTimeIntervalStart = useRef<Date | null>(null);

  const resizeBlock = useCallback(() => {
    if (rowDisabled || !mouseEventRef.current.mouseMoveX) return;
    if (
      mouseEventRef.current.mouseDownX &&
      mouseEventRef.current.targetRect &&
      mouseEventRef.current.currentProps
    ) {
      const deltaX =
        mouseEventRef.current.mouseDownX - mouseEventRef.current.mouseMoveX;
      if (
        mouseEventRef.current.mouseDownX !== null &&
        mouseEventRef.current.handle !== null &&
        Math.abs(deltaX) > 2
      ) {
        // allow a little threshold before considering the movement as "resizing"
        document.body.style.cursor = 'col-resize';

        if (mouseEventRef.current.handle === 'left') {
          // Resizing with left handle
          const newPosition =
            mouseEventRef.current.targetRect.left -
            deltaX +
            (virtualizer.scrollOffset ?? 0);
          let newSnapTime = addWeeks(
            timeInterval.start,
            Math.floor(newPosition / weekSizeInPx),
          );
          const newSnapPosition = weeksToPixels(
            newSnapTime,
            timeInterval.start,
            false,
            weekSizeInPx,
          );

          if (newSnapPosition > endPixels - weekSizeInPx) {
            // prevent resizing smaller than minimum width
            newSnapTime = addDays(
              subWeeks(mouseEventRef.current.currentProps.end, 1),
              1,
            );
          }

          if (newSnapTime !== mouseEventRef.current.currentProps.start) {
            // update only when needed
            const updatedProps = {
              ...mouseEventRef.current.currentProps,
              start: newSnapTime,
            };

            mouseEventRef.current.currentProps = updatedProps;
            setLocalProps(updatedProps);
          }
        } else {
          // Resizing with right handle
          const newPosition =
            mouseEventRef.current.targetRect.right -
            deltaX +
            (virtualizer.scrollOffset ?? 0);
          let newSnapTime = addWeeks(
            timeInterval.start,
            Math.floor(newPosition / weekSizeInPx),
          );
          const newSnapPosition = weeksToPixels(
            newSnapTime,
            timeInterval.start,
            false,
            weekSizeInPx,
          );

          if (newSnapPosition < startPixels + weekSizeInPx) {
            // prevent resizing smaller than minimum width
            newSnapTime = subDays(
              addWeeks(mouseEventRef.current.currentProps.start, 1),
              1,
            );
          }

          if (newSnapTime !== mouseEventRef.current.currentProps.end) {
            // update only when needed
            const updatedProps = {
              ...mouseEventRef.current.currentProps,
              end: endOfWeek(newSnapTime),
            };

            mouseEventRef.current.currentProps = updatedProps;
            setLocalProps(updatedProps);
          }
        }
      }
    }
  }, [
    rowDisabled,
    virtualizer.scrollOffset,
    timeInterval.start,
    weekSizeInPx,
    endPixels,
    startPixels,
  ]);

  const moveBlock = useCallback(() => {
    if (rowDisabled || !mouseEventRef.current.mouseMoveX) return;
    if (
      mouseEventRef.current.mouseDownX &&
      mouseEventRef.current.targetRect &&
      mouseEventRef.current.currentProps
    ) {
      const deltaX =
        mouseEventRef.current.mouseDownX - mouseEventRef.current.mouseMoveX;
      if (mouseEventRef.current.mouseDownX !== null && Math.abs(deltaX) > 2) {
        // allow a little threshold before considering the movement as "dragging"

        // Snap to week
        const newPosition =
          mouseEventRef.current.targetRect.left -
          deltaX +
          (virtualizer.scrollOffset ?? 0);
        const newSnapTime = addWeeks(
          timeInterval.start,
          Math.floor(newPosition / weekSizeInPx),
        );

        // update only when needed
        if (newSnapTime !== mouseEventRef.current.currentProps.start) {
          const newEndTime = endOfWeek(addWeeks(newSnapTime, lengthWeeks));
          const updatedProps = {
            ...mouseEventRef.current.currentProps,
            start: newSnapTime,
            end: newEndTime,
          };

          mouseEventRef.current.currentProps = updatedProps;
          setLocalProps(updatedProps);
        }
      }
    }
  }, [
    lengthWeeks,
    rowDisabled,
    timeInterval.start,
    virtualizer.scrollOffset,
    weekSizeInPx,
  ]);

  const onKeydownFn = (event: KeyboardEvent) => {
    // detect if shift is pressed
    if (event.key === 'Shift') {
      setShiftIsPressed(true);
    }
  };

  const onKeyupFn = () => {
    // detect if shift is pressed
    setShiftIsPressed(false);
  };

  const handleMove = useCallback(
    (event: { clientX: number }) => {
      mouseEventRef.current.mouseMoveX = event.clientX;
      if (mouseEventRef.current.handle) resizeBlock();
      else moveBlock();
    },
    [moveBlock, resizeBlock],
  );

  const handleMouseUp = () => {
    document.body.style.cursor = 'default';
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('contextmenu', handleMouseUp);
    document.removeEventListener('mousemove', handleMove);
    setSelectedBlockId(null);
    if (
      mouseEventRef.current.currentProps &&
      JSON.stringify(mouseEventRef.current.currentProps) !==
        JSON.stringify(localProps)
    ) {
      finalizeData(mouseEventRef.current.currentProps);
    }

    setBlockIdForActiveState(block?.id);

    mouseEventRef.current = {};
    onClick({
      blockId: block?.id,
      isMulti: false,
      // isMulti: shiftIsPressed,
      projectId: projectId,
      resourceId: resourceId,
    });
    currentTimeIntervalStart.current = null;
    setDragging(false);
    if (timerClickRef.current) {
      clearTimeout(timerClickRef.current);
    }
  };

  const handleMouseDownBlock = (event: React.MouseEvent) => {
    if (rowDisabled || event.button !== 0) return;
    timerClickRef.current = setTimeout(() => setDragging(true), 800);
    event.stopPropagation();
    setSelectedBlockId(block.id);
    mouseEventRef.current = {
      ...mouseEventRef.current,
      mouseDownX: event.clientX,
      mouseMoveX: event.clientX,
      targetRect: (event.target as HTMLElement).getBoundingClientRect(),
      currentProps: { ...localProps },
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('contextmenu', handleMouseUp);
    document.addEventListener('mousemove', handleMove);
    setActiveBlockIds([]);
    currentTimeIntervalStart.current = timeInterval.start;
    setDragging(true);
  };

  const [hovered, setHovered] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleMouseOver = useCallback(() => {
    if (!activeBlockIds.includes(block?.id)) {
      timerRef.current = setTimeout(() => {
        setHovered(true);
      }, 800);
    }
  }, [activeBlockIds, block?.id]);

  const handleMouseOut = useCallback(() => {
    if (!timerRef?.current) {
      return;
    }
    clearTimeout(timerRef.current);
    setHovered(false);
  }, []);

  useEffect(() => {
    const node = ref?.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver]);

  useEffect(() => {
    setShouldShowTooltip(
      (hovered || isDragging) &&
        !activeBlockIds.includes(blockIdForActiveState),
    );
  }, [hovered, isDragging, activeBlockIds, blockIdForActiveState]);

  // EFFECTS
  useEffect(() => setLocalProps(block), [block]);

  const isPast = isBefore(localProps.end, new Date());
  const allocation = block.allocation;

  const clampedAllocation = useMemo(() => {
    const allocValue = Math.round(
      Math.min(Math.max(allocation, 1), currentResource?.capacity ?? 1),
    );
    return Math.floor(
      linearMap(allocValue, 1, currentResource?.capacity, 1, 5),
    );
  }, [allocation, currentResource?.capacity]);

  const shadeClass = useMemo(
    () => `shade-${projectColor}-${clampedAllocation}`,
    [clampedAllocation, projectColor],
  );

  const blockLength = useMemo(
    () => differenceInWeeks(block.end, block.start) + 1,
    [block.end, block.start],
  );

  const createNewBlock = useCallback(
    ({ clientX }: { clientX: number }) => {
      setHovered(false);
      setDragging(false);
      if (timerClickRef.current) clearTimeout(timerClickRef.current);
      handleMouseOut();
      const newBlockWeek = addWeeks(
        timeInterval.start,
        Math.floor((clientX + (virtualizer.scrollOffset ?? 0)) / weekSizeInPx),
      );
      const newBlock = {
        id: generateUUID(),
        start: newBlockWeek,
        end: subDays(addWeeks(newBlockWeek, 1), 1),
        allocation: block.allocation,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.INSERT,
      } as TTimeBlockRange;

      if (currentProject?.id) {
        onActiveBlockFn({
          blockId: newBlock.id,
          isMulti: false,
          projectId: currentProject.id,
          resourceId: currentResource.id ?? '',
        });

        const blocksToUpdate = [
          ...updateBlocksWithProperEvents(
            currentProject?.timeblocks || [],
            newBlock,
          ),
          newBlock,
        ];

        setBlocksToUpdate({
          events: blocksToUpdate,
          projectId: currentProject.id,
          resourceId: currentResource.id ?? '',
          shouldInvalidated: true,
        });

        setActiveBlockIds([newBlock.id]);
      }
    },
    [
      handleMouseOut,
      timeInterval.start,
      virtualizer.scrollOffset,
      weekSizeInPx,
      block.allocation,
      currentProject?.id,
      currentProject?.timeblocks,
      onActiveBlockFn,
      currentResource,
      setBlocksToUpdate,
      setActiveBlockIds,
    ],
  );

  const canSplit = useMemo(() => {
    return (
      !rowDisabled && blockLength > 1 && !activeBlockIds?.length && !isDragging
    );
  }, [activeBlockIds?.length, blockLength, isDragging, rowDisabled]);

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  // RENDER
  return (
    <BlockContext.Provider
      value={{
        localProps,
        mouseEventRef,
        lengthWeeks,
        projectId,
        projectStatus,
        blockIdForActiveState,
        shouldShowTooltip,
        projectName,
        projectEmoji: emoji,
        block,
        handleMove,
        setDragging,
        isDragging,
        handleMouseUp,
        resizeBlock,
        moveBlock,
      }}
    >
      <div
        ref={ref}
        onClick={(event) => event.stopPropagation()}
        className={classNames([styles.container, styles[shadeClass]], {
          [styles.isPast]: isPast,
          [styles.isExpanded]: layoutIsExpanded,
          [styles.active]: isActive || isDragging,
          [styles.timeoff]: isTimeoff,
          [styles.classicCursor]: rowDisabled,
          [styles[`isUnconfirmed-${clampedAllocation}`]]:
            !isTimeoff && projectStatus === PROJECT_STATUS.UNCONFIRMED,
        })}
        style={{ left: startPixels + 1, width: lengthOfBlockInPx - 1 }}
        onMouseDown={handleMouseDownBlock}
        aria-hidden
      >
        {children}
        {canSplit &&
          Array.from(new Array(blockLength).keys()).map((i) => {
            return (
              <span
                key={i}
                className={classNames(styles.bottom, {
                  [styles.right]: i < blockLength - 1,
                  [styles.left]: i > 0,
                  [styles.isCutExpanded]: layoutIsExpanded,
                })}
                tabIndex={0}
                style={{ width: weekSizeInPx, left: i * weekSizeInPx }}
                role="button"
                onKeyDown={noop}
                onClick={createNewBlock}
                onMouseDown={(e) => e.stopPropagation()}
              ></span>
            );
          })}

        {isDragging && (
          <ScrollDraggingOverlay
            panelId="resource-row-handle"
            stepSize={weekSizeInPx}
            isDragging={isDragging}
            onScrollUpdate={() => {
              if (mouseEventRef.current.handle) resizeBlock();
              else moveBlock();
            }}
          />
        )}
      </div>
    </BlockContext.Provider>
  );
};

BlockWrap.Content = Content;
BlockWrap.Handle = Handle;
BlockWrap.Tooltip = Tooltip;

export default BlockWrap;
