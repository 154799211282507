import { useCallback, useContext } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { format, setDefaultOptions } from 'date-fns';
import { t } from 'i18next';

import { THiddenProject, TTimelineHiddenProject } from '@/types/timeline';

import { UIContext } from '@contexts/UIContext';
import { UserContext } from '@contexts/UserContext';
import { weeksToPixels } from '@services/helpers';

import { TimelineResourcesContext } from '@components/Timelines/TimelineResources/context';

import styles from './styles.module.css';

export type Props = {
  block: TTimelineHiddenProject;
  projectName?: string;
  projectColor?: string;
  hasInitialRadius?: boolean;
  hasFinalRadius?: boolean;
};

export default function HiddenBlock({
  block,
  hasInitialRadius,
  hasFinalRadius,
}: Props) {
  setDefaultOptions({ weekStartsOn: 1 });

  const { layoutIsExpanded } = useContext(UIContext);
  const { workspaceMode } = useContext(UserContext);
  const { timeInterval, weekSizeInPx } = useContext(TimelineResourcesContext);

  const startPixels = weeksToPixels(
    block.start,
    timeInterval.start,
    false,
    weekSizeInPx,
  );
  const endPixels = weeksToPixels(
    block.end,
    timeInterval.start,
    true,
    weekSizeInPx,
  );
  const lengthPixels = endPixels - startPixels;

  const renderProject = useCallback(
    (project: THiddenProject) => {
      return (
        <li
          className={classNames([
            styles.item,
            styles[`shade-${project.color}`],
          ])}
          key={project?.id}
        >
          <span>{project?.name}</span>
          <p>
            {project?.allocation}{' '}
            {project?.allocation > 1
              ? t(`timeline:blockAllocationPluralShort${workspaceMode}`)
              : t(`timeline:blockAllocationSingularShort${workspaceMode}`)}
          </p>
        </li>
      );
    },
    [workspaceMode],
  );

  // RENDER
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div
            className={classNames([styles.container], {
              [styles.isExpanded]: layoutIsExpanded,
              [styles.initialBorderRadius]: hasInitialRadius,
              [styles.finalBorderRadius]: hasFinalRadius,
            })}
            style={{ left: startPixels, width: lengthPixels }}
          >
            <span></span>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal
          container={document.getElementById('radix-tooltip-portal')}
        >
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            <div className={styles.tooltip}>
              <span className={styles.date}>{`${format(
                block?.start,
                'E, dd MMM',
              )} - ${format(block?.end, 'E, dd MMM')}`}</span>
              <ul className={styles.list}>
                {block?.projects?.map(renderProject)}
              </ul>
            </div>
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
