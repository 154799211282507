import ContentLoader from 'react-content-loader';

import classNames from 'classnames';
import { first } from 'lodash';

import useImageFirebaseQuery from '@hooks/firebase/useImageFirebaseQuery';
import { getCssVariable } from '@services/helpers';

import styles from './styles.module.css';

type Props = {
  image?: string;
  name: string;
  overrideIsLoading?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
};
export default function Avatar({
  image,
  name,
  size = 'small',
  overrideIsLoading = false,
}: Props) {
  const isBlob = image?.includes('blob:');
  const { data, isLoading } = useImageFirebaseQuery({
    path: image,
    enabled: !isBlob,
  });

  const initials =
    size === 'xsmall'
      ? name?.trim().charAt(0)
      : name?.includes(' ')
        ? name
            .split(' ')
            ?.map((n) => first(n))
            ?.join('')
        : (name?.slice(0, 2) ?? '');

  return (
    <div className={classNames([styles.container, styles[`${size}`]])}>
      {(isLoading || overrideIsLoading) && (
        <div className={styles.loading}>
          <ContentLoader
            speed={2}
            width={24}
            height={24}
            backgroundColor={getCssVariable('--bg-subdued')}
            foregroundColor={getCssVariable('--bg-subdued')}
          >
            <rect x="0" y="0" rx="6" ry="6" width="24" height="24" />
          </ContentLoader>
        </div>
      )}
      {data ? (
        <img src={data ?? image} alt={name} />
      ) : (
        <span>{initials?.slice(0, 2)?.toUpperCase()}</span>
      )}
    </div>
  );
}
