import * as Tooltip from '@radix-ui/react-tooltip';
import { IconCopy } from '@tabler/icons-react';
import { t } from 'i18next';
import { noop } from 'lodash';

import Button from '@components/Button';
import Input from '@components/Input';

import styles from './styles.module.css';

type Props = {
  token: string;
  onClipboardCopy?: (token: string) => void;
};
export default function TokenSection({ token, onClipboardCopy }: Props) {
  return (
    <section className={styles.atSection}>
      <div className={styles.atDescription}>
        <span>{t('common:modals.api.tokenSection.title')}</span>
        <span className={styles.warningText}>
          {t('common:modals.api.tokenSection.warning')}
        </span>
      </div>
      <div className={styles.atContent}>
        <Input
          name="token"
          value={token}
          onChange={noop}
          readOnly
          TrailingIcon={
            <Tooltip.Provider delayDuration={200}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Button
                    icon={IconCopy}
                    size="small"
                    variant="ghost"
                    onClick={() => onClipboardCopy?.(token)}
                  />
                </Tooltip.Trigger>
                <Tooltip.Content className="TooltipContent">
                  <div className={styles.detailTooltip}>
                    <span>{t('common:modals.api.tokenSection.tooltip')}</span>
                  </div>
                  <Tooltip.Arrow className="TooltipArrow" />
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          }
        />
      </div>
    </section>
  );
}
