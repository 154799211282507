import { useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import { IconKey } from '@tabler/icons-react';
import { t } from 'i18next';

import { AccessTokenInfo } from '@/types/workspace';

import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';
import ModalRevokeAccessToken from '@components/Modals/ModalRevokeAccessToken';

import styles from './styles.module.css';
import TokenSection from './TokenSection';

type Props = {
  token: AccessTokenInfo & { workspaceId: string };
  accessToken?: string;
};
export default function TokenInfoContainer({ token, accessToken }: Props) {
  const [_copyState, copyAccessToken] = useCopyToClipboard();
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false);
  return (
    <>
      <div className={styles.revokeContainer}>
        <section className={styles.revokeInfoSection}>
          <div className={styles.revokeTextContainer}>
            <IconKey size={20} color={getCssVariable('--icon')} />
            <span>{token?.workspaceName}</span>
          </div>
          <Button
            label={t('common:modals.api.actionRevoke')}
            variant="outline"
            size="medium"
            onClick={() => setIsRevokeModalOpen(true)}
          />
        </section>
        {accessToken && (
          <TokenSection token={accessToken} onClipboardCopy={copyAccessToken} />
        )}
      </div>
      {isRevokeModalOpen && (
        <ModalRevokeAccessToken
          tokenInfo={token}
          onClose={() => setIsRevokeModalOpen(false)}
          isOpen={isRevokeModalOpen}
        />
      )}
    </>
  );
}
