import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@hooks/workspace/resources/useTimelineResourceQuery';
import { USAGE_INFO_QUERY_KEY } from '@hooks/workspace/useUsageInfo';
import { deleteResource } from '@services/api/workspace/resources';

import { GET_MEMBERS_COUNT_BY_STATUS_QUERY } from './useResourceCountByStatusQuery';
import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';

type Props = {
  resourceId: string;
};

export default function useDeleteResource() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({ resourceId }: Props): Promise<void> => {
      await deleteResource({ workspaceId, resourceId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_MEMBERS_COUNT_BY_STATUS_QUERY],
      });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
