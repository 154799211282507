import type { IContentLoaderProps } from 'react-content-loader';
import ContentLoader from 'react-content-loader';

import { getCssVariable } from '@services/helpers';

export default function PlanCardSkeleton(props: IContentLoaderProps) {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height="304px"
      backgroundColor={getCssVariable('--bg-subdued')}
      foregroundColor={getCssVariable('--bg-subdued-active')}
      {...props}
    >
      <rect y="8" width="80" height="16" rx="6" fill="#F3F3F5" />
      <rect y="40" width="160" height="24" rx="6" fill="#F3F3F5" />
      <rect y="90" width="120" height="16" rx="6" fill="#F3F3F5" />
      <rect y="136" width="160" height="16" rx="6" fill="#F3F3F5" />
      <rect y="164" width="200" height="16" rx="6" fill="#F3F3F5" />
      <rect y="192" width="160" height="16" rx="6" fill="#F3F3F5" />
      <rect y="220" width="200" height="16" rx="6" fill="#F3F3F5" />
    </ContentLoader>
  );
}
