import { useContext } from 'react';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';
import { ProjectInfo } from '@/shared-app/types/project-info';
import { useQuery } from '@tanstack/react-query';

import { BaseResponse } from '@/types/base-responses';

export const GET_PROJECT_INFO_QUERY_KEY = 'get-project-info';
export default function useProjectInfo() {
  const { publicHttpClient, clientInitialized } = useContext(SharedAppContext);
  return useQuery({
    queryKey: [GET_PROJECT_INFO_QUERY_KEY],
    queryFn: async () => {
      const {
        data: { data: project },
      } =
        await publicHttpClient.get<BaseResponse<ProjectInfo>>(`/projects/info`);
      return project;
    },
    enabled: clientInitialized,
  });
}
