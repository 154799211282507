import { useContext } from 'react';

import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { TPagedQuery } from '@/types/generic';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@hooks/workspace/resources/useTimelineResourceQuery';
import { USAGE_INFO_QUERY_KEY } from '@hooks/workspace/useUsageInfo';
import { deleteProject } from '@services/api/workspace/projects';

type Props = {
  projectId: string;
};

export default function useDeleteProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({ projectId }: Props) => {
      await deleteProject({ workspaceId, projectId });
    },
    onMutate: ({ projectId }) => {
      const queryKey = [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId];
      queryClient.setQueriesData<
        InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
      >({ queryKey }, (prev) => {
        if (!prev) return prev;
        for (const page of prev.pages) {
          const projectIndex = page.results.findIndex(
            (project) => project.id === projectId,
          );
          if (projectIndex !== -1) {
            page.results.splice(projectIndex, 1);
            return cloneDeep(prev);
          }
        }
        return prev;
      });
      const data = queryClient.getQueriesData<
        InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
      >({ queryKey });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
      });
    },
    onError: (error, _vars, context) => {
      (
        context as [
          readonly unknown[],
          InfiniteData<
            TPagedQuery<TProjectListWithResources<TResourceItemList>>,
            unknown
          >,
        ][]
      )?.forEach(([queryKey, data]) =>
        queryClient.setQueryData<
          InfiniteData<
            TPagedQuery<TProjectListWithResources<TResourceItemList>>,
            unknown
          >
        >(queryKey, data),
      );
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
