import { useContext } from 'react';

import classNames from 'classnames';

import { TimelineResourcesContext } from '@components/Timelines/TimelineResources/context';

import { useBlockContext } from './Context';
import styles from './styles.module.css';

type Props = {
  type: 'left' | 'right';
};
export default function Handle({ type }: Props) {
  const { setActiveBlockIds, setSelectedBlockId } = useContext(
    TimelineResourcesContext,
  );

  const { mouseEventRef, localProps, handleMouseUp, handleMove, setDragging } =
    useBlockContext();

  const handleMouseDownResize = (event: React.MouseEvent) => {
    if (event.button !== 0) return;
    event.stopPropagation();
    setSelectedBlockId(localProps.id);
    mouseEventRef.current = {
      ...mouseEventRef.current,
      mouseDownX: event.clientX,
      mouseMoveX: event.clientX,
      targetRect: (event.target as HTMLElement)
        .closest(`.${styles.container}`)
        ?.getBoundingClientRect(),
      handle: (event.target as HTMLElement).dataset.side,
      currentProps: { ...localProps },
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('contextmenu', handleMouseUp);
    document.addEventListener('mousemove', handleMove);
    setActiveBlockIds([]);
    setDragging(true);
  };

  return (
    <>
      <div
        className={classNames(styles.handle, {
          [styles.handleRight]: type === 'right',
          [styles.handleLeft]: type === 'left',
        })}
        data-side={type}
        onMouseDown={handleMouseDownResize}
        aria-hidden
      ></div>
    </>
  );
}
