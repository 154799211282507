import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { contextMenu, Item, Menu, Separator } from 'react-contexify';
import type { TriggerEvent } from 'react-contexify';
import { ClipLoader } from 'react-spinners';

import { IconPlus, IconSearch } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { t } from 'i18next';
import { noop } from 'lodash';
import { useDebounce } from 'use-hooks';

import { UIContext } from '@contexts/UIContext';
import useAssignResourceToProject from '@hooks/workspace/projects/useAssignResourceToProject';
import useSearchResourcesQuery, {
  SEARCH_RESOURCES_KEY,
} from '@hooks/workspace/resources/useSearchResourcesQuery';
import { getCssVariable } from '@services/helpers';

import Avatar from '@components/Avatar';
import Input from '@components/Input';
import ModalAddNewTeamMemberForProject from '@components/Modals/ModalAddNewTeamMemberForProject';
import Portal from '@components/Portal';

import styles from './styles.module.css';

export default function AssignResourceButtonDropdown({
  projectId,
}: {
  projectId: string;
}) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const [queryIsEnabled, setQueryIsEnabled] = useState(false);

  const {
    data: resources,
    isLoading,
    isFetched,
  } = useSearchResourcesQuery({
    query: debouncedValue,
    enabled: queryIsEnabled,
    projectId,
    onError: noop,
  });

  const MENU_ID = useMemo(
    () => `assign-resource-to-project-context-menu-${projectId}`,
    [projectId],
  );
  function getMenuPosition() {
    const { bottom } = triggerRef?.current?.getBoundingClientRect() ?? {
      bottom: 0,
    };
    menuPositionRef.current = { x: 8, y: bottom + 8 };
    return menuPositionRef.current;
  }

  const eventRef = useRef<TriggerEvent | null>(null);
  const queryClient = useQueryClient();

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setQueryIsEnabled(true);
      eventRef.current = e;
      if (isFetched) {
        contextMenu?.show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(),
        });
      }
    },
    [MENU_ID, isFetched],
  );

  useEffect(() => {
    if (isFetched && eventRef.current) {
      contextMenu?.show({
        id: MENU_ID,
        event: eventRef?.current as TriggerEvent,
        position: getMenuPosition(),
      });
    }
  }, [MENU_ID, isFetched]);

  const { mutate, isPending: isLoadingMutate } = useAssignResourceToProject();

  const [assignResourceId, setAssignResourceId] = useState<string | null>(null);

  const onResourceClick = useCallback(
    (resourceId: string) => {
      setAssignResourceId(resourceId);
      mutate(
        { resourceId, projectId },
        {
          onSuccess: () => {
            contextMenu.hideAll();
            setAssignResourceId(null);
            queryClient.invalidateQueries({ queryKey: [SEARCH_RESOURCES_KEY] });
          },
        },
      );
    },
    [mutate, projectId, queryClient],
  );
  const ref = useRef<HTMLInputElement>(null);

  const onChangeFn = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);
  const { layoutIsExpanded } = useContext(UIContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        ref={triggerRef}
        onClick={onClickFn}
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
      >
        <IconPlus size={16} /> {t('timeline:assignResource')}
      </button>
      <Portal>
        <Menu
          animation={false}
          className={classNames(styles.menu, 'top-triangle')}
          id={MENU_ID}
          onVisibilityChange={(isVisible) => {
            setValue('');
            if (!isVisible) {
              setQueryIsEnabled(false);
            }
          }}
        >
          <Item
            className="unstyled-item"
            style={{ opacity: 1 }}
            disabled
            closeOnClick={false}
          >
            <Input
              ref={ref}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              LeadingIcon={<IconSearch size={20} />}
              isDark
              name="input"
              placeholder={`${t('common:search')}...`}
              value={value}
              onChange={onChangeFn}
            />
          </Item>
          <Separator />

          {resources?.length ? (
            resources?.map((item, index) => (
              <Item
                key={index}
                tabIndex={index}
                closeOnClick={false}
                onClick={() => onResourceClick(item.id)}
                // keyMatcher={(e: KeyboardEvent): boolean => {
                //   return e.key === (index + 1).toString();
                // }}
              >
                {isLoadingMutate && assignResourceId === item.id && (
                  <ClipLoader
                    color={getCssVariable('--color-neutral-500')}
                    cssOverride={{
                      borderWidth: '2px',
                      marginRight: 4,
                    }}
                    size={16}
                    speedMultiplier={0.8}
                  />
                )}
                <div className={styles.resourceItem}>
                  <Avatar
                    name={`${item.firstName} ${item.lastName}`}
                    image={item.image?.ref}
                    size={'small'}
                  />
                  <span className={styles.name}>
                    {item.firstName} {item.lastName}
                  </span>
                </div>
              </Item>
            ))
          ) : isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '10px 0',
              }}
              className={styles.loader}
            >
              <ClipLoader
                color={getCssVariable('--color-neutral-500')}
                cssOverride={{
                  borderWidth: '2px',
                }}
                size={20}
                speedMultiplier={0.8}
              />
            </div>
          ) : (
            <Item disabled>{t('common:noResourcesToAssign')}</Item>
          )}
          <Separator />
          <Item
            onClick={() => setIsModalOpen(true)}
            // keyMatcher={(e: KeyboardEvent): boolean => {
            //   e.preventDefault();
            //   return e.metaKey && e.key === 'n';
            // }}
          >
            <IconPlus style={{ marginRight: 8 }} size={20} />
            {t('common:addResourceContextMenuActions.addResource')}{' '}
            {/* <RightSlot>⌘N</RightSlot> */}
          </Item>
        </Menu>
      </Portal>
      {isModalOpen && (
        <ModalAddNewTeamMemberForProject
          isOpen={isModalOpen}
          projectId={projectId}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}
