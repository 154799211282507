import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContext } from '@contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@hooks/utils/useAnalytics';
import { http } from '@services/api';

export default function useDeleteWorkspace() {
  const queryClient = useQueryClient();
  const { workspace, clearLocalStorage } = useContext(UserContext);
  const { trackEvent } = useAnalytics();

  return useMutation({
    mutationFn: async (workspaceId: string) => {
      await http.delete(`/workspaces/${workspaceId}`);
    },
    onSuccess: async () => {
      if (workspace?.id)
        await trackEvent(ANALYTICS_EVENTS.WORKSPACE_DELETED, workspace?.id);
      queryClient.clear();

      clearLocalStorage(false);
    },
  });
}
