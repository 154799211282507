import { forwardRef } from 'react';
import { Item } from 'react-contexify';

import { IconCheck } from '@tabler/icons-react';

import BaseDropdownMenu from '@components/MenuUtility/DropdownMenu';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';

import styles from './styles.module.css';

const SortTypes = ['Manual', 'Name'] as const;
export type SortLabels = (typeof SortTypes)[number];
type Props = {
  onSortSelected: (sort: SortLabels) => void;
  selectedSort: SortLabels;
};
const SortMenu = forwardRef<DropdownMenuRefType, Props>(
  ({ onSortSelected, selectedSort }, ref) => {
    return (
      <BaseDropdownMenu id="sort-menu" ref={ref} className={styles.sortMenu}>
        {SortTypes.map((sort) => {
          return (
            <Item
              key={sort}
              onClick={() => onSortSelected(sort)}
              className={styles.sortItem}
            >
              <div className={styles.sortIcon}>
                {sort === selectedSort ? <IconCheck size={20} /> : null}
              </div>
              <span>
                {sort === 'Manual' ? 'Manual ordering' : 'Order by name'}
              </span>
            </Item>
          );
        })}
      </BaseDropdownMenu>
    );
  },
);

SortMenu.displayName = 'SortMenu';
export default SortMenu;
