import { useContext } from 'react';

import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { BaseResponse } from '@/types/base-responses';
import {
  TPagedQuery,
  TResponseError,
  TSuccessErrorHook,
} from '@/types/generic';
import { TLabel } from '@/types/labels';

import { UserContext } from '@contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { http } from '@services/api';

import { GET_WORKSPACE_LABELS_KEY } from './useGetWorkspaceLabels';

export default function useDeleteLabel({
  onSuccess,
  onError,
}: TSuccessErrorHook<unknown> = {}) {
  const queryClient = useQueryClient();
  const { workspace } = useContext(UserContext);
  return useMutation({
    mutationFn: async (labelId: string) =>
      http.delete<BaseResponse<void>>(
        `workspaces/${workspace?.id}/labels/${labelId}`,
      ),
    onMutate(variables) {
      const prevLabels = queryClient.getQueriesData<
        InfiniteData<TPagedQuery<TLabel>>
      >({
        queryKey: [GET_WORKSPACE_LABELS_KEY],
      });
      queryClient.setQueriesData<InfiniteData<TPagedQuery<TLabel>>>(
        { queryKey: [GET_WORKSPACE_LABELS_KEY] },
        (data) => {
          if (!data) return data;
          const newData = cloneDeep(data);
          newData.pages.forEach((page) => {
            page.results = page.results.filter((item) => item.id !== variables);
          });
          return newData;
        },
      );
      return prevLabels;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError: (error: TResponseError, _vars, prevData) => {
      prevData?.forEach(([_query, data]) => {
        if (data) queryClient.setQueryData(_query, data);
      });
      onError?.(error);
    },
  });
}
