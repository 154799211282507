import { useCallback, useContext, useEffect, useState } from 'react';

import type { Paddle, PaddleEventData } from '@paddle/paddle-js';
import { CheckoutEventNames, initializePaddle } from '@paddle/paddle-js';

import { UserContext } from '@contexts/UserContext';
import useCreateCustomer from '@hooks/workspace/subscriptions/useCreateCustomer';
import useUpdateCustomer from '@hooks/workspace/subscriptions/useUpdateCustomer';

type Props = {
  enableCallback?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  eventHandler?: (event: PaddleEventData) => void;
  onCheckoutCompleted?: (
    event: PaddleEventData,
    paddleCheckout?: Paddle,
  ) => void;
};

export default function usePaddle({
  enableCallback = true,
  onOpen,
  onClose,
  eventHandler,
  onCheckoutCompleted,
}: Props = {}) {
  const { workspace } = useContext(UserContext);
  const [paddle, setPaddle] = useState<Paddle | undefined>();
  const { mutate: createCustomer } = useCreateCustomer();
  const { mutate: updateCustomer } = useUpdateCustomer();

  const createOrUpdateCustomer = useCallback(
    (event: PaddleEventData) => {
      switch (event.name) {
        case CheckoutEventNames.CHECKOUT_LOADED:
          onOpen?.();
          break;
        case CheckoutEventNames.CHECKOUT_CLOSED:
          onClose?.();
          break;
        case CheckoutEventNames.CHECKOUT_COMPLETED:
          onCheckoutCompleted?.(event, paddle);
          break;
        case CheckoutEventNames.CHECKOUT_CUSTOMER_CREATED:
        case CheckoutEventNames.CHECKOUT_CUSTOMER_UPDATED:
          if (event.data?.customer.id) {
            const {
              customer: { id: c_id, address, business },
            } = event.data;

            const existingCust = workspace?.customer?.customerId === c_id;

            if (!existingCust) {
              createCustomer?.({
                customerId: event.data.customer.id,
                addressId: event.data?.customer?.address?.id,
                businessId: event.data?.customer?.business?.id,
              });
            } else if (
              workspace.customer.addressId !== address?.id ||
              workspace.customer.businessId !== business?.id
            ) {
              updateCustomer?.({
                customerId: event.data.customer.id,
                addressId: event.data.customer?.address?.id,
                businessId: event.data.customer?.business?.id,
              });
            }
          }
      }
    },
    [
      createCustomer,
      onCheckoutCompleted,
      onClose,
      onOpen,
      paddle,
      updateCustomer,
      workspace?.customer?.addressId,
      workspace?.customer?.businessId,
      workspace?.customer?.customerId,
    ],
  );

  useEffect(() => {
    if (!paddle?.Initialized) {
      const paddleEnv =
        import.meta.env.VITE_PADDLE_ENV === 'production'
          ? 'production'
          : 'sandbox';
      initializePaddle({
        token: import.meta.env.VITE_PADDLE_PUBLIC_KEY,
        environment: paddleEnv,
        checkout: {
          settings: {
            displayMode: 'overlay',
          },
        },
      }).then((paddle) => setPaddle(paddle));
    }
  }, [paddle?.Initialized]);

  useEffect(() => {
    if (paddle && paddle.Initialized && enableCallback)
      paddle.Update({
        eventCallback: eventHandler ?? createOrUpdateCustomer,
      });
  }, [
    createOrUpdateCustomer,
    enableCallback,
    eventHandler,
    onCheckoutCompleted,
    paddle,
  ]);

  return { paddle };
}
