import { Fragment, useCallback, useMemo, useState } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import { IconSparkles } from '@tabler/icons-react';
import { t } from 'i18next';

import { useProfileModal } from '@contexts/ModalPreferenceContext';
import { getCssVariable } from '@services/helpers';

import Button from '@components/Button';
import ModalManageAutomations from '@components/Modals/ModalManageAutomations';
import { ContentPages } from '@components/Modals/ModalUserProfile';
import Toolbar from '@components/Toolbar';

type Props = {
  autoSync: boolean;
  autoHide: boolean;
};

export default function ManageAutomationsIcon({ autoHide, autoSync }: Props) {
  const [isAutoSyncModalOpen, setIsAutoSyncModalOpen] = useState(false);
  const openAutoSyncModal = useCallback(() => setIsAutoSyncModalOpen(true), []);
  const { openModal } = useProfileModal();

  const onModalClose = useCallback(
    (answer?: boolean) => {
      if (answer) {
        openModal(ContentPages.SETTINGS);
      }
    },
    [openModal],
  );

  const automationsIcon = useMemo(() => {
    const color = getCssVariable('--icon-caution');

    return {
      icon: IconSparkles,
      iconProps: autoSync || autoHide ? { color, fill: color } : {},
    };
  }, [autoHide, autoSync]);

  return (
    <Fragment>
      <Tooltip.Provider delayDuration={1000}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <Toolbar>
              <Button
                {...automationsIcon}
                variant="ghost"
                size="large"
                onClick={openAutoSyncModal}
              />
            </Toolbar>
          </Tooltip.Trigger>
          <Tooltip.Portal
            container={document.getElementById('radix-tooltip-portal')}
          >
            <Tooltip.Content className="TooltipContent" sideOffset={8}>
              {t('timeline:manageAutomationsTooltip')}
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
      {isAutoSyncModalOpen && (
        <ModalManageAutomations
          key={`auto-sync-modal`}
          isOpen={true}
          onClose={(answer) => {
            setIsAutoSyncModalOpen(false);
            onModalClose(answer);
          }}
        />
      )}
    </Fragment>
  );
}
