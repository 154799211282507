import { CSSProperties, useCallback, useContext, useMemo } from 'react';

import { type UniqueIdentifier } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { VirtualItem } from '@tanstack/react-virtual';
import classNames from 'classnames';

import { PROJECT_STATUS } from '@/types/enums';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';

import { UIContext } from '@contexts/UIContext';
import { titleCase } from '@services/helpers';

import { INDICATOR_MAPPING } from '@components/Indicators';
import { TimelineProjectsContext } from '@components/Timelines/TimelineProjects/context';
import SortableRow from '@components/Timelines/TimelineProjects/SortableRow';

import { ProjectStatusProvider } from './ProjectStatusContext';
import styles from './styles.module.css';

type Props = {
  status: PROJECT_STATUS;
  activeSortingId: UniqueIdentifier | null;
  projects?: TProjectListWithResources<TResourceItemList>[];
  rightLabel?: JSX.Element | string;
};

export default function ProjectStatusWrapper({
  status,
  projects,
  activeSortingId,
  rightLabel,
}: Props) {
  const { virtualizer } = useContext(TimelineProjectsContext);

  const { layoutIsExpanded } = useContext(UIContext);

  const items = useMemo(
    () => projects?.map((project) => project.id) ?? ['PLACEHOLDER'],
    [projects],
  );

  const renderWeek = useCallback(
    (virtualWeek: VirtualItem) => (
      <div
        className={classNames(styles.weekCell, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
        style={
          {
            width: `${virtualWeek.size}px`,
            transform: `translateX(${virtualWeek.start}px)`,
          } as CSSProperties
        }
        key={virtualWeek.key}
      ></div>
    ),
    [layoutIsExpanded],
  );

  const Indicator = INDICATOR_MAPPING[status];
  return (
    <ProjectStatusProvider status={status} initialData={projects ?? []}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div
            className={classNames(styles.header, {
              [styles.isExpanded]: layoutIsExpanded,
            })}
          >
            <div
              className={classNames(styles.statusHeader, {
                [styles.isExpanded]: layoutIsExpanded,
              })}
            >
              <Indicator />
              <span className={styles.statusHeader_label}>
                {titleCase(status.replaceAll('_', ' '))}
              </span>
            </div>
            {rightLabel && (
              <div className={styles.rightLabel}>{rightLabel}</div>
            )}
          </div>
          <div className={styles.allocationWrapper}>
            <div
              className={classNames(styles.allocation, {
                [styles.isExpanded]: layoutIsExpanded,
              })}
            >
              {virtualizer
                .getVirtualItems()
                .map((virtual) => renderWeek(virtual))}
            </div>
          </div>
        </div>
        {status !== PROJECT_STATUS.COMPLETED && (
          <div>
            <SortableContext
              id={status}
              strategy={verticalListSortingStrategy}
              items={items}
            >
              {projects?.length
                ? projects.map((project) => (
                    <SortableRow
                      key={`${project?.id}_${status}`}
                      sortableId={project?.id}
                      activeSortingId={activeSortingId}
                      project={project}
                    />
                  ))
                : [
                    <SortableRow
                      key={`PLACEHOLDER_${status}`}
                      activeSortingId={activeSortingId}
                      sortableId={`PLACEHOLDER_${status}`}
                    />,
                  ]}
            </SortableContext>
          </div>
        )}
      </div>
    </ProjectStatusProvider>
  );
}
