import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { TSubscriptionDto } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { GET_SUBSCRIPTION_KEY } from '@hooks/workspace/subscriptions/useGetSubscription';
import { http } from '@services/api';

export default function useReactivateSubscription() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation({
    mutationFn: async (subscriptionId: string) => {
      await http.post(
        `/workspaces/${workspaceId}/subscriptions/${subscriptionId}/reactivate`,
      );
    },
    onMutate: () => {
      const subscription = queryClient.getQueryData<TSubscriptionDto>([
        GET_SUBSCRIPTION_KEY,
        workspaceId,
      ]);
      if (!subscription) return undefined;
      const newSubscription = cloneDeep(subscription);
      newSubscription.waitingForUpdate = true;
      queryClient.setQueryData<TSubscriptionDto>(
        [GET_SUBSCRIPTION_KEY, workspaceId],
        newSubscription,
      );

      return { subscription };
    },
    onError: (error: Error, _subscriptionId, context) => {
      if (context?.subscription) {
        queryClient.setQueryData<TSubscriptionDto>(
          [GET_SUBSCRIPTION_KEY, workspaceId],
          context.subscription,
        );
      }
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
