import { useContext } from 'react';
import Modal from 'react-modal';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { ROUTES } from '@/types/routes';

import { UserContext } from '@contexts/UserContext';
// TODO: cosi firebase viene inizializzato lo stessa anche se sei in shared-app (non va bene, non dovrebbe inizializzarsi)
import '@utils/firebase-init';

import Loader from '@components/Loader';
import AcceptInvitationScreen from '@screens/AcceptInvitation';
import AuthRedirectScreen from '@screens/AuthRedirect';
import ResetPasswordScreen from '@screens/AuthRedirect/ResetPassword';
import ValidateEmailAccount from '@screens/AuthRedirect/ValidateEmail';
import VerifyAndChangeEmailScreen from '@screens/AuthRedirect/VerifyAndchangeEmail';
import DashboardProjectView from '@screens/DashboardProjectView';
import DashboardResourceView from '@screens/DashboardResourceView';
import EmailVerificationScreen from '@screens/EmailVerification';
import Error404 from '@screens/ErrorScreen/404';
import Home from '@screens/Home';
import OnboardingLayout from '@screens/OnBoarding';
import AddMemberScreen from '@screens/OnBoarding/AddMember';
import WorkspaceCreateScreen from '@screens/OnBoarding/WorkspaceCreate';
import WorkspaceTimeModeScreen from '@screens/OnBoarding/WorkspaceTimeMode';
import RecoverPasswordScreen from '@screens/RecoverPassword';
import SignInScreen from '@screens/SignIn';
import SignUpScreen from '@screens/SignUp';

import './i18n';
import { PrivateRoute } from './PrivateRoute';

if (!import.meta.env.VITEST) {
  Modal.setAppElement('#root');
}

export default function App() {
  const { isLoading } = useContext(UserContext);

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: '*',
      element: <Error404 />,
    },
    {
      path: '/error',
      element: <Error404 />,
    },
    {
      path: ROUTES.SIGN_IN,
      element: <SignInScreen />,
    },
    {
      path: ROUTES.SIGN_UP,
      element: <SignUpScreen />,
    },
    {
      path: ROUTES.EMAIL_VERIFICATION,
      element: <EmailVerificationScreen />,
    },
    {
      path: ROUTES.PASSWORD_RECOVER,
      element: <RecoverPasswordScreen />,
    },
    {
      path: ROUTES.PASSWORD_RESET,
      element: <ResetPasswordScreen />,
    },
    {
      path: ROUTES.AUTH,
      element: <AuthRedirectScreen />,
    },
    {
      path: ROUTES.VALIDATE_EMAIL,
      element: <ValidateEmailAccount />,
    },
    {
      path: ROUTES.VALIDATE_AND_CHANGE_EMAIL,
      element: <VerifyAndChangeEmailScreen />,
    },
    {
      path: ROUTES.RECOVER_EMAIL,
      element: <VerifyAndChangeEmailScreen />,
    },
    {
      path: ROUTES.ON_BOARDING,
      element: (
        <PrivateRoute>
          <OnboardingLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <WorkspaceCreateScreen />,
        },
        {
          path: ROUTES.ADD_MEMBER,
          element: <AddMemberScreen />,
        },
        {
          path: ROUTES.WORKSPACE_SET_TIME_MODE,
          element: <WorkspaceTimeModeScreen />,
        },
      ],
    },
    {
      path: ROUTES.ACCEPT_INVITATION,
      element: (
        <PrivateRoute>
          <AcceptInvitationScreen />
        </PrivateRoute>
      ),
    },
    {
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
      children: [
        {
          path: ROUTES.ROOT,
          element: <DashboardResourceView />,
        },
        {
          path: ROUTES.DASHBOARD,
          exact: true,
          element: <DashboardResourceView />,
        },
        {
          path: ROUTES.PROJECT,
          exact: true,
          element: <DashboardProjectView />,
        },
      ],
    },
  ]);

  return isLoading ? <Loader /> : <RouterProvider router={router} />;
}
