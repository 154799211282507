import type { IContentLoaderProps } from 'react-content-loader';
import ContentLoader from 'react-content-loader';

import { getCssVariable } from '@services/helpers';

export default function InvoiceListSkeleton(props: IContentLoaderProps) {
  return (
    <ContentLoader
      speed={0.5}
      width="100%"
      height="100%"
      viewBox="0 0 100% 100%"
      backgroundColor={getCssVariable('--bg-subdued')}
      foregroundColor={getCssVariable('--bg-subdued-active')}
      {...props}
    >
      <rect width="85" height="18" rx="4" fill="#090808" />
      <rect x="180" width="85" height="18" rx="4" fill="#090808" />
      <rect x="360" width="46" height="18" rx="4" fill="#090808" />
      <rect x="546" width="46" height="18" rx="4" fill="#090808" />
      <rect y="34" width="85" height="18" rx="4" fill="#090808" />
      <rect x="180" y="34" width="85" height="18" rx="4" fill="#090808" />
      <rect x="360" y="34" width="46" height="18" rx="4" fill="#090808" />
      <rect x="546" y="34" width="46" height="18" rx="4" fill="#090808" />
      <rect y="68" width="85" height="18" rx="4" fill="#090808" />
      <rect x="180" y="68" width="85" height="18" rx="4" fill="#090808" />
      <rect x="360" y="68" width="87" height="18" rx="4" fill="#090808" />
      <rect x="546" y="68" width="46" height="18" rx="4" fill="#090808" />
    </ContentLoader>
  );
}
