import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { BaseResponse } from '@/types/base-responses';

import { http } from '@services/api';

export type TValidationeEmail = {
  status: string;
  email: string;
  result: boolean;
};

export const VALIDATION_EMAIL_KEY = 'validate-email';
export default function useValidateEmail({ email }: { email: string }) {
  const { data, isFetched, isStale, refetch, ...others } = useQuery({
    queryKey: [VALIDATION_EMAIL_KEY, email],
    queryFn: async ({ queryKey: [_, email] }) => {
      return http
        .get<BaseResponse<TValidationeEmail>>(`/accounts/validate-email`, {
          params: { email },
        })
        .then(({ data: { data } }) => data)
        .catch(
          (_error) =>
            ({
              status: 'error',
              email,
              result: false,
            }) as TValidationeEmail,
        );
    },
    enabled: false,
  });

  const validate = useCallback(async () => {
    if (isFetched && !isStale) return data;
    return refetch().then(({ data }) => data);
  }, [data, isStale, refetch]);
  return { data, validate, isStale, ...others };
}
