import Portal from '@components/Portal';

import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  message: string | JSX.Element;
};
export default function SortBanner({ isOpen, message }: Props) {
  return (
    <Portal>
      <div
        className={styles.wrapper}
        {...(isOpen ? { 'data-animation': isOpen ? 'open' : 'close' } : {})}
      >
        <div className={styles.content}>{message}</div>
      </div>
    </Portal>
  );
}
