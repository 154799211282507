import { useContext } from 'react';

import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { BaseListResponse } from '@/types/base-responses';
import { TPagedQuery, TSuccessErrorHookWithParams } from '@/types/generic';
import { TLabel } from '@/types/labels';

import { UserContext } from '@contexts/UserContext';
import { handleApiCall, http } from '@services/api';
import { normalizeInfiniteQuery, normalizeLabels } from '@services/normalizer';

type Props = Omit<
  TSuccessErrorHookWithParams<
    TPagedQuery<TLabel>,
    {
      q?: string;
      pageSize?: number;
      onSelect?: (
        data: InfiniteData<TPagedQuery<TLabel>>,
      ) => InfiniteData<TPagedQuery<TLabel>>;
    }
  >,
  'enabled'
>;
const PAGE_SIZE = 15;

export const GET_WORKSPACE_LABELS_KEY = 'get-workspace-labels';
export default function useGetWorkspaceLabels({
  q,
  pageSize,
  onSuccess,
  onSelect,
  onError,
}: Props) {
  const { workspaceId } = useContext(UserContext);
  const KEY: [
    string,
    string | null | undefined,
    string | undefined,
    number | undefined,
  ] = [GET_WORKSPACE_LABELS_KEY, workspaceId, q, pageSize ?? undefined];

  return useSuspenseInfiniteQuery({
    queryKey: KEY,
    queryFn: async ({ pageParam, queryKey: [_, ws, search, size] }) => {
      return handleApiCall(
        http
          .get<BaseListResponse<TLabel>>(`workspaces/${ws}/labels`, {
            params: {
              page: pageParam ?? 1,
              pageSize: size ?? PAGE_SIZE,
              q: search,
            },
          })
          .then((response) => {
            return {
              ...normalizeInfiniteQuery(
                response.data satisfies Omit<TPagedQuery<TLabel>, 'results'>,
              ),
              results: (response.data.data ?? []).map(normalizeLabels),
            };
          }),
        onSuccess,
        onError,
      );
    },
    initialPageParam: 1,
    select: onSelect,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    getPreviousPageParam: (lastPage) => lastPage?.previousPage,
  });
}
