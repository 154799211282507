import { forwardRef, useMemo, useState } from 'react';
import { Item, Separator } from 'react-contexify';

import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { useDebounce } from 'use-hooks';

import { PROJECT_COLOR } from '@/types/enums';
import { TLabel } from '@/types/labels';

import { getCssVariable } from '@services/helpers';

import ColorDot from '@components/ColorDot';
import BaseDropdownMenu from '@components/MenuUtility/DropdownMenu';
import { DropdownMenuRefType } from '@components/MenuUtility/DropdownMenu/types';
import ItemSearch from '@components/MenuUtility/UtilityItems/component/ItemSearch/ItemSearch';

import styles from './styles.module.css';

type Props = {
  label: TLabel;
  onColorSelect: (color: PROJECT_COLOR) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
};

const ColorMenu = forwardRef<DropdownMenuRefType, Props>(
  ({ label, onColorSelect, onMenuClose, onMenuOpen }: Props, menuRef) => {
    const [searchColorValue, setSearchColorValue] = useState<string>('');
    const debounced = useDebounce(searchColorValue, 300);

    const colorValues = useMemo(() => {
      const colorVals = Object.values(PROJECT_COLOR);
      if (!debounced) return colorVals;
      const colorRegex = new RegExp(`.?(${debounced}).?`, 'i');
      return colorVals.filter((c) => colorRegex.test(c));
    }, [debounced]);

    const MENU_ID = `color-menu-${label.id}`;

    return (
      <BaseDropdownMenu
        id={MENU_ID}
        ref={menuRef}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        className={styles.menuContainer}
      >
        <ItemSearch
          onValueChanged={setSearchColorValue}
          value={searchColorValue}
        />
        <Separator />
        {colorValues?.map((color) => {
          return (
            <Item key={color} onClick={() => onColorSelect(color)}>
              <div className={styles.colorMenuItem}>
                <IconCheck
                  size={20}
                  color={getCssVariable('--icon-inverse')}
                  className={classNames({
                    [styles.hidden]: color !== label.color,
                  })}
                />
                <ColorDot color={color} size={16} />
                <span className={styles.text}>{color}</span>
              </div>
            </Item>
          );
        })}
      </BaseDropdownMenu>
    );
  },
);

ColorMenu.displayName = 'ColorMenu';

export default ColorMenu;
