import { useContext } from 'react';

import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import classNames from 'classnames';
import { setDefaultOptions } from 'date-fns';

import { TTimeBlockRange } from '@/types/timeline';

import { UIContext } from '@contexts/UIContext';
import { weeksToPixels } from '@services/helpers';

import BlockTooltip from '@components/Blocks/BlockTooltip';
import { TimelineProjectsContext } from '@components/Timelines/TimelineProjects/context';

import styles from './styles.module.css';

export type Props = {
  block: TTimeBlockRange;
  projectName: string;
  projectColor: string;
};

export default function NewBlock({
  block,
  projectName,
  projectColor = 'grey',
}: Props) {
  setDefaultOptions({ weekStartsOn: 1 });

  const isMdDevice = useMediaQuery('(min-width: 992px)');

  const { timeInterval, weekSizeInPx } = useContext(TimelineProjectsContext);

  const { layoutIsExpanded } = useContext(UIContext);
  const leftPositionPixels = isMdDevice ? 0 : projectName?.length * 8 + 16;
  const startPixels = weeksToPixels(
    block.start,
    timeInterval.start,
    false,
    weekSizeInPx,
  );
  const endPixels = weeksToPixels(
    block.end,
    timeInterval.start,
    true,
    weekSizeInPx,
  );
  const lengthPixels = endPixels - startPixels;

  // RENDER
  return (
    <div
      className={classNames(
        [styles.container, styles[`shade-${projectColor}`]],
        {
          [styles.isExpanded]: layoutIsExpanded,
        },
      )}
      style={{ left: startPixels, width: lengthPixels }}
    >
      <p style={{ left: leftPositionPixels }}>
        <strong>{projectName}</strong>
      </p>
      <BlockTooltip startDate={block?.start} endDate={block?.end} />
    </div>
  );
}
