import { Dto, TResponse } from '@/types/generic';
import { TTimeBlockRange } from '@/types/timeline';

import { normalizeTimeblock } from '@services/normalizer';

import { http } from '..';

export async function updateAllocationDetail({
  workspaceId,
  allocationId,
  note,
}: {
  workspaceId?: string | null;
  allocationId: string;
  note?: string;
}) {
  const {
    data: { data },
  }: { data: TResponse<Dto<TTimeBlockRange>> } = await http.put(
    `/workspaces/${workspaceId}/allocations/${allocationId}/detail`,
    { note: note ?? undefined },
  );
  return data ? normalizeTimeblock(data) : undefined;
}
