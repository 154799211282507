import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
import { t } from 'i18next';

import { NotificationsContext } from '@contexts/NotificationContext';
import { UserContext } from '@contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@hooks/insights/useInsightsPartQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@hooks/utils/useAnalytics';
import { HIDDEN_PROJECTS_QUERY } from '@hooks/workspace/projects/useHiddenProjectQuery';
import { SEARCH_PROJECTS_KEY } from '@hooks/workspace/projects/useProjectsQuery';
import { GET_HIDDEN_QUERY } from '@hooks/workspace/resources/useHiddenQuery';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { unhideProjectToResource } from '@services/api/workspace/resources';

type Props = {
  resourceId: string;
  projectId: string;
};

export default function useAssignProjectToResource() {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);
  const { trackEvent } = useAnalytics();

  return useMutation<AxiosResponse<void>, Error, Props>({
    mutationFn: async ({ resourceId, projectId }: Props) =>
      unhideProjectToResource({ workspaceId, resourceId, projectId }),
    onSuccess: () => {
      trackEvent(ANALYTICS_EVENTS.PROJECT_UNARCHIVED, workspaceId as string);

      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [HIDDEN_PROJECTS_QUERY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [GET_HIDDEN_QUERY] });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
