import NB from '@components/Timelines/TimelineResources/NewBlock';

import { useProjectRowContext } from './Context';

export default function NewBlock() {
  const { project, mouseEventRef, newBlock } = useProjectRowContext();
  return (
    newBlock &&
    project?.id === mouseEventRef.current.projectId && (
      <NB
        projectColor={project?.color ?? ''}
        block={newBlock}
        projectName={project?.name ?? ''}
      />
    )
  );
}
