import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { BaseResponse } from '@/types/base-responses';
import { Dto } from '@/types/generic';
import { TPaymentInfo } from '@/types/invoices';

import { UserContext } from '@contexts/UserContext';
import { http } from '@services/api';
import { normalizePaymentInfo } from '@services/normalizer';

type Props = {
  subscriptionId: string;
  enabled?: boolean;
};

export const GET_PAYMENT_METHOD_KEY = 'get-payment-method';
export default function useGetPaymentMethod({
  enabled = true,
  subscriptionId,
}: Props) {
  const { workspace } = useContext(UserContext);
  return useQuery({
    queryKey: [GET_PAYMENT_METHOD_KEY, workspace?.id, subscriptionId],
    queryFn: async ({ queryKey: [_, ws, subID] }) => {
      const {
        data: { data },
      } = await http.get<BaseResponse<TPaymentInfo>>(
        `/workspaces/${ws}/subscriptions/${subID}/payment-method`,
      );
      return normalizePaymentInfo(data as Dto<TPaymentInfo>);
    },
    enabled: enabled && !!workspace?.id,
  });
}
