import { useContext, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { find } from 'lodash';

import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import { UserContext } from '@contexts/UserContext';

import { TimelineProjectsContext } from '@components/Timelines/TimelineProjects/context';
import ResourceRow from '@components/Timelines/TimelineProjects/ResourceRow';

type Props = {
  project: TProjectListWithResources<TResourceItemList>;
  resource: TResourceItemList;
  activeSortingId: UniqueIdentifier | null;
};
export default function SortableResource({
  project,
  resource,
  activeSortingId,
}: Props) {
  const { activeBlockIds } = useContext(TimelineProjectsContext);
  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id: resource?.id,
    disabled: true,
  });

  const isDragging = activeSortingId === resource?.id;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
  };

  const disabled = useMemo(
    () =>
      isMobile ||
      workspace?.permission !== WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ||
      !resource?.capacity,
    [resource?.capacity, workspace?.permission],
  );

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        position: isDragging ? 'relative' : undefined,
      }}
    >
      <ResourceRow
        key={resource.id}
        resource={resource}
        project={project}
        disabled={disabled}
      >
        <ResourceRow.Header
          sortHandlerProps={{
            listeners,
            activator: setActivatorNodeRef,
            attributes,
          }}
        />
        <ResourceRow.Content>
          {activeBlockIds.length === 0 && !disabled && <ResourceRow.Cursor />}
          <ResourceRow.Blocks />
          <ResourceRow.NewBlock />
          {!isMobile && <ResourceRow.ActivePanel />}
        </ResourceRow.Content>
      </ResourceRow>
    </div>
  );
}
