import ContentLoader from 'react-content-loader';
import type { IContentLoaderProps } from 'react-content-loader';

import { getCssVariable } from '@services/helpers';

export default function ListSkeleton(props: IContentLoaderProps) {
  return (
    <ContentLoader
      speed={2}
      width={368}
      height={104}
      viewBox="0 0 368 104"
      backgroundColor={getCssVariable('--bg-subdued')}
      foregroundColor={getCssVariable('--bg-subdued-active')}
      {...props}
    >
      <path
        style={{ transform: 'scale(1.3)' }}
        d="M6 0a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6H6Zm30 6a4 4 0 0 0-4 4v4a4 4 0 0 0 4 4h112a4 4 0 0 0 4-4v-4a4 4 0 0 0-4-4H36ZM0 34a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V34Zm36 0a4 4 0 0 0-4 4v4a4 4 0 0 0 4 4h152a4 4 0 0 0 4-4v-4a4 4 0 0 0-4-4H36ZM0 62a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V62Zm36 0a4 4 0 0 0-4 4v4a4 4 0 0 0 4 4h128a4 4 0 0 0 4-4v-4a4 4 0 0 0-4-4H36Z"
      />
    </ContentLoader>
  );
}
