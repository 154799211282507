import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { isNil, noop } from 'lodash';
import queryString from 'query-string';

import { TSuccessErrorHook } from '@/types/generic';
import { TInvitationInfo } from '@/types/workspace';

import { NotificationsContext } from '@contexts/NotificationContext';
import { handleApiCall } from '@services/api';
import { getInvitationInfo } from '@services/api/account';

export const INVITATION_INFO_QUERY_KEY = 'get-invitation-info';
export default function useInvitationQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TInvitationInfo>) {
  const { tk: token = '' } = queryString.parse(location.search);
  const { addNotification } = useContext(NotificationsContext);
  return useQuery({
    queryKey: [INVITATION_INFO_QUERY_KEY],
    queryFn: () =>
      handleApiCall(getInvitationInfo(token as string), onSuccess, onError),
    enabled: enabled && !isNil(token),
    retry: false,
  });
}
